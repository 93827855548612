import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js';

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: { 
        generatePdaTahunan({state,commit}, payload) {               
            commit('setItem', {resource: 'generateDinasPdaExcel', item: {}}, {root: true})
            return axiosInstance.post('/api/kedinasan/generate/xls/pda', payload)
            .then(res => {
                const fileName = res.data.fileName    
                commit('setItem', {resource: 'generateDinasPdaExcel', item: fileName}, {root: true})
                return state.item
             })
            },
        downloadExcelFile({state, commit}, payload) {            
            commit('setItem', { resource: 'generateDinasPdaExcel', item: {} }, { root: true })
            return axiosInstance.get(`/api/hidrologi/download/${payload}`, {
                method: 'get',
                responseType: 'arraybuffer',
              })
                .then(res => {
                     const files = res.data;
                     const url = window.URL.createObjectURL(new Blob([res.data]))
                     const link = document.createElement('a')
                     link.href = url
                     link.setAttribute('download', payload)
                     document.body.appendChild(link)
                     link.click()
                    commit('setItem', { resource: 'generateDinasPdaExcel', item: files }, { root: true })
                    return state.item
                })
        } 
    },
    mutations: {

    }
}