<template>
   <div class="modal-level">
  
  <div class="tombol">
      <div class="tombol-button">
        <button class="button h-button is-success is-elevated" @click="isOpen = !isOpen" type="is-success" outlined>
          Pratinjau
        </button>
      </div>     
    </div>
  
    <div :class="['modal', { 'is-active': isOpen }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Pengkinian Data Rumus Debit</p>
          <!-- TODO: Close Modal set isOpen to false -->
          <button
            @click="isOpen = false"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <!-- <FormInputDevice :sn="deviceId" /> -->
          <form> 
        <div class="field">
          <div class="control">
            <label class="label is-normal">Koef A</label>
            <input
              @blur="$v.form.koefA.$touch()"
              v-model="form.koefA"
              type="number"
              class="input"
              placeholder="Masukan Angka"
            />
            <div v-if="$v.form.koefA.$error">
              <span v-if="!$v.form.koefA.required" class="help is-danger"
                >Angka Harus Diisi</span
              >
            </div>
          </div>
        </div>

        <div class="field">
          <div class="control">
            <label class="label is-normal">Koef B</label>
            <input
              @blur="$v.form.koefB.$touch()"
              v-model="form.koefB"
              type="number"
              class="input"
              placeholder="Masukan Angka"
            />
            <div v-if="$v.form.koefB.$error">
              <span v-if="!$v.form.koefB.required" class="help is-danger"
                >Angka Harus Diisi</span
              >
            </div>
          </div>
        </div>

        <div class="field">
          <div class="control">
            <label class="label is-normal">Koef C</label>
            <input
              @blur="$v.form.koefC.$touch()"
              v-model="form.koefC"
              type="number"
              class="input"
              placeholder="Masukan Angka"
            />
            <div v-if="$v.form.koefC.$error">
              <span v-if="!$v.form.koefC.required" class="help is-danger"
                >Angka Harus Diisi</span
              >
            </div>
          </div>
        </div>
      </form>

        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <div class="control btn1">
              <button
                @click="isOpen = !isOpen"
                @click.prevent="editDebit" 
                class="button h-button is-link is-elevated"
              >
                <span> Simpan </span>
              </button>
            </div>

            <div class="control btn">
              <button
                @click="isOpen = false"
                class="button  h-button is-danger is-elevatedr"
              >
                <span> Batal </span>
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>


<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";

export default {
  name: "ModalUpdateKoef",
    props: {
    rumusId: {
      type: Number,
      required: false
    },
      
  }, 
  data() {
    return {
        isOpen: false,
      form: {
        id: null,
        koefA: null,
        koefB: null,
        koefC: null,
      },
    };
  },
  validations: {
    form: {
      koefA: { required },
      koefB: { required },
      koefC: { required },
    },
  },
  created(){
     this.fetchDebitById(this.rumusId).then((res) => {
      this.form = res;
    }); 
  },
  computed: {
     ...mapState({
      debit: (state) => state.debit.item,
    }),
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
  },
  methods: {
     ...mapActions("debit", ["fetchDebitById", "updateDebit"]),
   editDebit() {
      this.updateDebit(this.form)
      .then(() => {
            this.$toasted.success('Rubah Data Debit Berhasil', {duration: 3000})
            this.$emit("submitted");
            })
      .catch(error => { 
        this.$toasted.error(error.response.data.message, {duration: 3000})
      })
      

    },
  //  deleteDebit() {
  //          this.$swal({
  //                 title: "Rumus Debit Akan Dihapus?",
  //                 text: "Setelah dihapus anda tidak dapat mengembalikannya!",
  //                 type: "warning",
  //                 showCancelButton: true,
  //                 confirmButtonColor: "#c0392b",
  //                 confirmButtonText: "YA, HAPUS!"
  //               }).then((result) => { // <--
  //                 if (result.value) { // <-- if confirmed
  //                   this.$store.dispatch("debit/deleteDebit", this.debitId)
  //                   this.$router.push('/kadaster')
  //                   // this.$emit("deleted");
  //                   this.$toasted.success("Delet Debit Success", { duration: 5000 }) 
                    
  //                 }
  //             });
  //   },
  },
};
</script>

<style scoped>
.modal {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}


.modal-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
}
 

@media (max-width: 768px) {
  .modal-card {
    height: 600px;
    width: 350px;
  }
   
}

</style>