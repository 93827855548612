import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import router from './router'
import store from './store'
import vuelidate from 'vuelidate'; 
import moment from 'moment'
import Toasted from 'vue-toasted';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"; 
import { LMap, LControlScale, LPopup, LTooltip, LTileLayer, LMarker, LPolygon, LPolyline, LControlLayers, LLayerGroup, LWMSTileLayer } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import './assets/app.css';
import './assets/main.css';
import VueSSE from 'vue-sse';
import ElTableInfiniteScroll from "el-table-infinite-scroll";

import "echarts";
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  BarChart
} from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent
} from 'echarts/components'

import Trend from "vuetrend" 

import VueQuillEditor from 'quill-vuejs'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TooltipComponent
]);

Vue.use(VueQuillEditor, /* { default global options } */)
Vue.component('v-chart', ECharts)

Vue.use(VueSSE);
Vue.use(Trend)
Vue.use(Buefy)
Vue.use(Toasted)
Vue.use(VueSweetalert2); 
Vue.use(vuelidate) 
Vue.config.productionTip = false
Vue.filter('formatDate', function(value, formatType = 'YYYY-MM-DD HH:mm:ss'){
  if(!value ) return ''

  return moment(value).format(formatType)
})
 
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('Lpolygon', LPolygon);
Vue.component('Lpolyline', LPolyline);
Vue.component('LControlLayers', LControlLayers);
Vue.component('LPopup', LPopup);
Vue.component('LLayerGroup', LLayerGroup)
Vue.component('LTooltip', LTooltip);
Vue.component('LControlScale', LControlScale);
Vue.component('LWMSTileLayer', LWMSTileLayer)

Vue.directive("el-table-infinite-scroll", ElTableInfiniteScroll);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
