import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js'

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fetchSungai({state, commit}){
           commit('setItems', {resource: 'sungai', items: []}, {root: true})
           return axiosInstance.get('/api/sungai/manage/search-sungai')
            .then(res => {
                const sungai = res.data.sungai                  
                commit('setItems', {resource: 'sungai', items: sungai}, {root: true})
                return state.items
             })
        },
        searchDas({state, commit}, params){
          commit('setItems', {resource: 'sungai', items: []}, {root: true})
         return axiosInstance.get(`/api/sungai/manage/search-das?${params}`)
          .then(res => {
              const results = res.data
              commit('setItems', {resource: 'sungai', items: results}, {root: true})
              return state.items
           })
        },
        searchSungai({state, commit}, params){
          commit('setItems', {resource: 'sungai', items: []}, {root: true})
         return axiosInstance.get(`/api/sungai/manage/search-sungai?${params}`)
          .then(res => {
              const results = res.data
              commit('setItems', {resource: 'sungai', items: results}, {root: true})
              return state.items
           })
        },
        fetchSungaiById({state, commit}, sungaiId){
          commit('setItem', {resource: 'sungai', item: {}}, {root: true})
          return axiosInstance.get(`/api/sungai/manage/view/${sungaiId}`)
          .then(res => {
              const sungai = res.data                 
              commit('setItem', {resource: 'sungai', item: sungai}, {root: true})
              return state.item
           })
        },
        createSungai(context, payload){
            const headers = {
                'Content-Type': 'application/json',
              }
            return axiosInstance.post('/api/sungai/manage/create', 
            payload,{
                headers: headers
              })
              .then(res => res.data)
        },
        addSungai({commit,state}, sungai) {
          const sungais = state.items
          commit('setItems', {resource: 'sungai', items: [...sungais, sungai]}, {root: true})
        },
        updateSungai(context, payload){
          const headers = {
            'Content-Type': 'application/json',
          }          
            return axiosInstance.put(`/api/sungai/manage/update/${payload.id}`, payload,
            {
              headers: headers
            })             
             
        },
        deletSungai({state, commit}, id){                            
          commit('setItem', {resource: 'sungai', item: {}}, {root: true})
          return axiosInstance.delete(`/api/sungai/manage/remove/${id}`)
          .then(res => {
              const sungai = res.data                 
              commit('setItem', {resource: 'sungai', item: sungai}, {root: true})
              return state.item
           })
        },
        getSungaiFromKadaster({state, commit}, posId){
          commit('setItem', {resource: 'sungai', item: {}}, {root: true})
          return axiosInstance.get(`/api/kadaster/manage/view/${posId}`)
          .then(res => {
              const sungai = res.data.sungai                         
              commit('setItem', {resource: 'sungai', item: sungai}, {root: true})
              return state.sungai
           })
      },
    },
    mutations: {
    mergeSungai (state, updatedSungai) {
          state.item = {...state.item, ...updatedSungai}
      },
    }
}