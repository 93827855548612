import axios from '../../conf/axios';
// import ApiMethods from '@/conf/config.js'

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {        
        fetchLevelByRegisterId({state, commit}, registerId){    
            commit('setItem', {resource: 'landingLevel', item:{}}, {root: true})
            return axios.get(`/api/landing/level/view/${registerId}`)
            .then(res => {
                const level = res.data                      
                commit('setItem', {resource: 'landingLevel', item: level}, {root: true})
                return state.item
            })
        } 
        
    },
    
}