<template>
  <div>
    
    <ErrorView :title="'Ooooops, halaman yang anda cari tidak ditemukan'"
               :status="404"
               :navigateToPage="'PageHome'"
               :navigateToText="'Beranda'" />
  </div>
</template>

<script>
import ErrorView from './ErrorView'
  export default {
    components: {
      ErrorView
    }
  }
</script>

<style scoped>
</style>