import axios from '../../conf/axios';
// import ApiMethods from '@/conf/config.js'

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fetchLandingPchChannel({state,commit}, kodeRegister) {
            commit('setItems', {resource: 'landingPchChannel', items: []}, {root: true})
            return axios.get(`/api/landing/channel/view/pch/${kodeRegister}`)
            .then(res => {
                const pchchannels = res.data.channels  
                commit('setItems', {resource: 'landingPchChannel', items: pchchannels}, {root: true})
                return state.items
                })
        },
        fetchLandingPchHourlyChannel({state,commit}, payload) {
            commit('setItems', {resource: 'landingPchChannel', items: []}, {root: true})
            return axios.post(`/api/landing/channel/search/hourly/pch/${payload.registerId}`,payload.search)
            .then(res => {
                const pchchannels = res.data.channels  
                commit('setItems', {resource: 'landingPchChannel', items: pchchannels}, {root: true})
                return state.items
             })
            }, 
        fetchLandingPchDailyChannel({state,commit}, payload) {
            commit('setItems', {resource: 'landingPchChannel', items: []}, {root: true})
            return axios.post(`/api/landing/channel/search/daily/pch/${payload.registerId}`,payload.search)
            .then(res => {
                const pchchannels = res.data.channels                 
                commit('setItems', {resource: 'landingPchChannel', items: pchchannels}, {root: true})
                return state.items
             })
            }, 
         
        addPchChannel({commit,state}, chan) {
            const channels = state.items
            commit('setItems', {resource: 'landingPchChannel', items: [...channels, chan]}, {root: true})
        }
    },
    mutations: {

    }
}