<template>
  <div class="mobile-main-sidebar" :class="{ 'is-active': isActive }">
    <div class="inner">
      <ul class="icon-side-menu">
        <LinkNavigation />
      </ul>

      <ul class="bottom-icon-side-menu">
        <li>
          <router-link
            class="button h-button is-primary is-raised hint--info hint--top"
            to="/login"
            data-hint="Login"
          >
            <i class="lnil lnil-user-alt-1"></i>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
import LinkNavigation from "./LinkNavigation.vue";
export default {
  name: "MobileSidebarMain",
  components: { LinkNavigation },
  data() {
    return {
      isActive: "",
    };
  },
  mounted() {
    this.$root.$on("mobile-navbar", () => {
      this.isActive = this.isActive ? "" : "is-active";
    });
  },
  beforeDestroy() {
    this.$root.$off("global-notification", this.registerId);
  },
};
</script>