import axiosInstance from '../../conf/axiosAdmin'; 


export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fetchPengumuman({state, commit}){
            commit('setItems', {resource: 'pengumuman', items: {}}, {root: true})
            return axiosInstance.get('/api/annoucement/search')
            .then(res => {
                const pengumuman = res.data.annoucements                 
                commit('setItems', {resource: 'pengumuman', items: pengumuman}, {root: true})
                return state.items
                })
            }, 
        searchPengumuman({state, commit}, params){
              commit('setItems', {resource: 'pengumuman', items: {}}, {root: true})
              return axiosInstance.get(`/api/annoucement/search?${params}`)
              .then(res => {
                  const results = res.data                
                  commit('setItems', {resource: 'pengumuman', items: results}, {root: true})
                  return state.items
                  })
              },   
        fetchPengumumanById({state, commit}, Id){            
              commit('setItem', {resource: 'pengumuman', item: {}}, {root: true})
              return axiosInstance.get(`/api/annoucement/view/${Id}`)
              .then(res => {
                  const pengumuman = res.data           
                  commit('setItem', {resource: 'pengumuman', item: pengumuman}, {root: true})
                  return state.item
               })               
          },    
        addPengumuman(context, payload){ 
            const headers = {
                'Content-Type': 'application/json',
              }
 
            return axiosInstance.post('/api/annoucement/add', 
            payload,{
                headers: headers
              })
              .then(res => res.data)
        },
        updatePenguman(context, payload){
            const headers = {
              'Content-Type': 'application/json',
            }          
              return axiosInstance.put(`/api/annoucement/update/${payload.id}`, payload,
              {
                headers: headers
              })             
               
          },
          deletPengumumanById({ state, commit }, id) {
            commit('setItem', { resource: 'pengumuman', item: [] }, { root: true })
            return axiosInstance.delete(`/api/annoucement/remove/${id}`)
                .then(res => {
                    const pengumuman = res.data
                    commit('setItem', { resource: 'pengumuman', item: pengumuman }, { root: true })
                    return state.item
                })
            },
          deactivePengumumanById(context, payload){
              const headers = {
                'Content-Type': 'application/json',
              }          
                return axiosInstance.put(`/api/annoucement/deactive/${payload.id}`, payload,
                {
                  headers: headers
                })   
               },

    },
    mutations: {

    }
}