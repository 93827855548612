<template>
     <span>
        <!-- Activity -->
        <!-- {{> sidebar-item-home}} -->
        <li>             
          <router-link  to="/dashboard/profile" class="hint--top-right" data-hint="Profile">
            <i class="lnil lnil-user-alt-1"></i>
          </router-link>
        </li>
        <!-- Layouts -->
        <!-- {{> sidebar-item-layouts}} -->
        <li>
          <router-link to="/dashboard/device" class="hint--top-right" data-hint="Device">
            <i class="lnil lnil-cogs"></i>
          </router-link>
        </li>
        <!-- Bounties -->
        <!-- {{> sidebar-item-elements}} -->
        <li>
          <router-link to="/dashboard/pos" class="hint--top-right" data-hint="Pos Hidrologi">
            <i class="lnil lnil-house"></i>
          </router-link>
        </li>
         <li>
          <router-link to="/dashboard/posdinas" class="hint--top-right" data-hint="Pos Dinas">
            <i class="lnil lnil-tent"></i>
          </router-link>
        </li>
         <li>
          <router-link to="/dashboard/kualitasair" class="hint--top-right" data-hint="Kualitas Air">
            <i class="lnil lnil-water-drops"></i>
          </router-link>
        </li>
        <li>
          <router-link to="/dashboard/das" class="hint--top-right" data-hint="Data Das">
            <i class="lnil lnil-travel"></i>
          </router-link>
        </li>
         <li>
          <router-link to="/dashboard/sungai" class="hint--top-right" data-hint="Data Sungai">
            <i class="lnil lnil-air-flow"></i>
          </router-link>
        </li> 
         <li>
          <router-link to="/dashboard/forecasting" class="hint--top-right" data-hint="Forecasting">
            <i class="lnil lnil-night-thunder"></i>
          </router-link>
        </li> 
        <li>
          <router-link to="/dashboard/cluster" class="hint--top-right" data-hint="Cluster Pos">
            <i class="lnil lnil-network-alt"></i>
          </router-link>
        </li>       
        <li>
          <router-link to="/dashboard/konteninformasi" class="hint--top-right" data-hint="Konten Informasi">
             <i class="lnil lnil-file-download"></i>
          </router-link>
        </li>
         <li>
          <router-link to="/dashboard/pengumuman" class="hint--top-right" data-hint="Pengumuman">
             <i class="lnil lnil-bullhorn"></i>
          </router-link>
        </li>
     </span>
</template>

<script>
export default {
    name: "LinkDashboard"
}
</script>