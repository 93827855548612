<template>
<div class="page-content-wrapper">
  <div class="page-content-inner">
    <div class="datatable-toolbar">
        <div class="buttons" v-if="count===0">
         <LevelInput :registeredId="regId" @submitted="submitted" />
        </div>
      </div>
    <div class="flex-list-wrapper flex-list-v1">
      <div class="flex-table">
        <!--Table header-->
        <div class="flex-table-header">
          <span>Waspada</span>
          <span>Siaga</span>
          <span>Awas</span> 
          <span>Tinggi Tanggul</span> 
          <span>Actions</span>
        </div>

        <div v-if="isLoaded" class="flex-list-inner">
          <!--Table item-->
          <div class="flex-table-item">
              <div class="flex-table-cell" data-th="Waspada">
              <span class="light-text"> {{level.waspada ? level.waspada + ' cm' : '-'}} </span>
            </div>

              <div class="flex-table-cell" data-th="Siaga">
                <span class="light-text">
                  {{ level.siaga ? level.siaga + ' cm' : '-' }}
                   
                </span>
              </div>
             
            <div class="flex-table-cell" data-th="Awas">
              <span
                class="light-text"
                >{{ level.awas ? level.awas + ' cm': '-'}}</span
              >              
            </div>
             <div class="flex-table-cell" data-th="Tinggi Tanggul">
              <span
                class="light-text"
                >{{ level.tinggiTanggul ? level.tinggiTanggul + ' cm': '-'}}</span
              >              
            </div>
           
            <div class="flex-table-cell" v-if="count===1">
              <LevelUpdate :levelId="level.id"
                   @updatedSubmit="updateLevel"/>
            </div>
            <div class="flex-table-cell" v-else>
               -
            </div>
          </div>
        </div>
        <div v-else class="flex-list-inner">
          <AppSpinner />
        </div>
      </div>

      
    </div>
  </div>
</div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import AppSpinner from '../shared/AppSpinner.vue'
import LevelInput from './LevelInput.vue';
import LevelUpdate from './LevelUpdate.vue'
export default {
  name: "LevelList",
  components: {
      AppSpinner,
      LevelInput,
      LevelUpdate
  },
  props: {
    regId: {
      type: String,
      required: true,
    }
  },
   data() {
        return {
        isLoaded: false, 
        data: [],         
        count: 0,
        };
    },
  created() {  
        this.fetchLevelByRegisterId(this.regId).then((resp) => {           
        this.isLoaded = true
          
         if(Object.entries(resp).length > 1){
             this.count = 1;
         } 
      });
  },
  computed: {
    ...mapState({
      level: (state) => state.level.item,
    }),
  },
  methods: {
    ...mapActions("level", ["fetchLevelByRegisterId"]),
    updateLevel(){
    this.loadAsyncData();
    },
    /*
     * Load async data
     */
    loadAsyncData() {
      
      this.fetchLevelByRegisterId(this.regId).then(() => {  
             
        this.isLoaded = true
      });
    }, 
    submitted(){      
      this.loadAsyncData();
    },
  },

  mounted() {
    this.loadAsyncData();
  },  
};
</script>