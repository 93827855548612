<template>
  <div>
    
    <button
      class="button h-button is-primary is-elevated"
      @click="isOpen = !isOpen"
      type="is-success"
      outlined 
      
    >
     <i class="fal fa-plus"></i><span>Input Level</span>
    </button>
   
     
    <div :class="['modal', { 'is-active': isOpen }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Masukan Data Level Ketinggian</p>
          <!-- TODO: Close Modal set isOpen to false -->
          <button
            @click="isOpen = false"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <!-- <FormInputDevice :sn="deviceId" /> -->
          <form>
       
      <div class="field">
        <div class="control">
          <label class="label is-normal">Waspada</label>
          <input
            @blur="$v.form.waspada.$touch()"
            v-model="form.waspada"
            type="number"
            class="input"
            placeholder="Masukan Level Ketinggian"
          />
          <div v-if="$v.form.waspada.$error">
            <span v-if="!$v.form.waspada.required" class="help is-danger"
              >Level Ketinggian Harus Diisi</span
            >
            <span v-if="!$v.form.waspada.between" class="help is-danger"
                >Level Ketinggian Tidak Boleh Minus</span
              >
          </div>
        </div>
      </div>
            
      <div class="field">
        <div class="control">
          <label class="label is-normal">Siaga</label>
          <input
            @blur="$v.form.siaga.$touch()"
            v-model="form.siaga"
            type="number"
            class="input"
            placeholder="Masukan Level Ketinggian"
          />
          <div v-if="$v.form.siaga.$error">
            <span v-if="!$v.form.siaga.required" class="help is-danger"
              >Level Ketinggian Harus Diisi</span
            >
            <span v-if="!$v.form.siaga.between" class="help is-danger"
                >Level Ketinggian Tidak Boleh Minus</span
              >
          </div>
        </div>
      </div>

      

      <div class="field">
        <div class="control">
          <label class="label is-normal">Awas</label>
          <input
            @blur="$v.form.awas.$touch()"
            v-model="form.awas"
            type="number"
            class="input"
            placeholder="Masukan Level Ketinggian"
          />
          <div v-if="$v.form.awas.$error">
            <span v-if="!$v.form.awas.required" class="help is-danger"
              >Level Ketinggian Harus Diisi</span
            >
            <span v-if="!$v.form.awas.between" class="help is-danger"
                >Level Ketinggian Tidak Boleh Minus</span
              >
          </div>
        </div>
      </div>
      <div class="field">
        <div class="control">
          <label class="label is-normal">Tinggi Tanggul</label>
          <input
            @blur="$v.form.tinggiTanggul.$touch()"
            v-model="form.tinggiTanggul"
            type="number"
            class="input"
            placeholder="Masukan Level Ketinggian"
          />
          <div v-if="$v.form.tinggiTanggul.$error">
            <span v-if="!$v.form.tinggiTanggul.required" class="help is-danger"
              >Level Ketinggian Harus Diisi</span
            >
            <span v-if="!$v.form.tinggiTanggul.between" class="help is-danger"
                >Level Ketinggian Tidak Boleh Minus</span
              >
          </div>
        </div>
      </div>
      </form>

        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <div class="control btn1">
              <button
                @click.prevent="saveLevel"
                @click="isOpen = false"
                :disabled="isFormInvalid"
                class="button is-normal is-link is-focused"
              >
                <span> Simpan </span>
              </button>
            </div>

            <div class="control btn">
              <button
                @click="isOpen = false"
                class="button is-normal is-danger is-focused"
              >
                <span> Batal </span>
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>

import { required, between } from "vuelidate/lib/validators";


export default {
  components: {
    
  },
   props: {
   registeredId: {
      type: String,
      required: false,
    },
    
  },
  data() {
    return {
     isOpen: false,
      form: {
        registerId: this.registeredId,  
        awas: null,
        siaga: null,
        waspada: null,
        tinggiTanggul: null
      },
    };
  },
  validations: {
    form: {
      waspada: { 
        required, 
         bettween: between(0, 1000000)
        },
      siaga: { 
        required,
        bettween: between(0, 1000000)
        },
      awas: { 
        required,
        bettween: between(0, 1000000) 
        },
      tinggiTanggul: { 
        required,
        bettween: between(0, 1000000) 
        },
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    }
  },
  methods: {
    saveLevel() {
      this.$store.dispatch("level/createLevelSungai", this.form)
       .then(() => 
          {
               this.$emit('submitted', this.form);
               this.$toasted.success("Tambah Data Level Berhasil", { duration: 5000 });
          }
         
       )
       .catch(error => this.$toasted.error(error.response.data.details, { duration: 5000 }));
       this.isOpen = false;
    },
     
    },
  }

</script>

<style scoped>
.modal {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}


.modal-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
}
</style>