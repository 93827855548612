import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js';


export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fetchKualitasAir({state, commit}){
            commit('setItems', {resource: 'kualitas', items: []}, {root: true})
           return axiosInstance.get('/api/kualitas/air/manage/search')
            .then(res => {
                const kualitas = res.data                
                commit('setItems', {resource: 'kualitas', items: kualitas}, {root: true})
                return state.items
             })
        },
        fetchKualitasAirById({state, commit}, kualitasId){
          commit('setItem', {resource: 'kualitas', item: {}}, {root: true})
          return axiosInstance.get(`/api/kualitas/air/manage/view/${kualitasId}`)
          .then(res => {
              const kualitas = res.data                 
              commit('setItem', {resource: 'kualitas', item: kualitas}, {root: true})
              return state.item
           })
        },
        searchKualitasAir({state, commit}, params){
          commit('setItems', {resource: 'kualitas', items: []}, {root: true})
         return axiosInstance.get(`/api/kualitas/air/manage/search?${params}`)
          .then(res => {
              const results = res.data
                           
              commit('setItems', {resource: 'kualitas', items: results}, {root: true})
              return state.items
           })
      },
      createKualitasAir(context, payload){
        const headers = {
            'Content-Type': 'application/json',
          }
        return axiosInstance.post('/api/kualitas/air/manage/create', 
        payload,{
            headers: headers
          })
          .then(res => res.data)
    },
    updateKualitasAir(context, payload){
      const headers = {
        'Content-Type': 'application/json',
      }          
        return axiosInstance.put(`/api/kualitas/air/manage/update/${payload.id}`, payload,
        {
          headers: headers
        })             
         
    },
    deletKualitasAir({state, commit}, id){                            
      commit('setItem', {resource: 'kualitas', item: {}}, {root: true})
      return axiosInstance.delete(`/api/kualitas/air/manage/remove/${id}`)
      .then(res => {
          const kualitas = res.data                 
          commit('setItem', {resource: 'kualitas', item: kualitas}, {root: true})
          return state.item
       })
    },
        
    },
    mutations: {
      mergeKualitasAir (state, UpdateKualitasAir) {
        state.item = {...state.item, ...UpdateKualitasAir}
  },
    }
}