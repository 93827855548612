import axios from '../../conf/axios';

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
       async fetchLandingKanalPos({ state, commit }, registerId) {
            commit('setItems', { resource: 'kanalPosDinas', items: [] }, { root: true })
            return await axios.get(`/api/landing/kanaldinas/${registerId}`)
                .then(res => {
                    const dataList = res.data                    
                    commit('setItems', { resource: 'kanalPosDinas', items: dataList }, { root: true })
                    return state.items
                })
        }, 
           
    }
}