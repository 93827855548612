<template>
  <div class="business-dashboard company-dashboard ecommerce-dashboard-v1">
    <div class="datatable-toolbar">
      <div class="buttons">
        <!-- SEARCH PDA -->
        <SearchPda/>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="dashboard-card is-base-chart">
          <div class="content-box">
            <div class="revenue-stats is-dark-bordered-12">
              Tinggi Muka air (cm)
            </div>
          </div>
          <v-chart autoresize class="chart" :option="awlr" />
        </div>
      </div>

      <div class="column is-12">
        <!-- Datatable -->

        <div class="table-wrapper">
          <b-table
            class="table is-datatable is-hoverable table-is-bordered"
            :data="data"
            :columns="columns"
            focusable
            paginated
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @sort="onSort"
            icon-pack="fas"
            icon-left="chevron-right"
          >
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import VChart from "vue-echarts";
import SearchPda from './SearchPda.vue'
export default {
  name: "TablePdaTelemetry",
  components: {
    VChart,
    SearchPda
  }, 
  data() {
    return {
      data: [],
      page: 0,
      perPage: 10,
      total: 0,
      columns: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "tma",
          label: "TMA (Cm)",
        },
      ],
      sortField: "tanggal",
      sortOrder: "ASC",
      defaultSortOrder: "ASC",
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      awlr: {},
    };
  },
  created() {
    this.fetchPosDinasPda().then((resp) => {
      if (resp != null) {
        let currentTotal = resp.length;
        if (currentTotal / this.perPage > 1000) {
          currentTotal = this.perPage * 1000;
        }
        this.total = currentTotal;

        resp.forEach((item) => {
          this.loading = false;
          this.data.push(item);
        });

        this.awlr = {
          title: {
            left: "1%",
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: "5%",
            right: "15%",
            bottom: "10%",
            top: "10%",
          },
          xAxis: {
            data: resp.map((r) => r.tanggal).reverse(),
          },
          yAxis: {
            axisLabel: {
              formatter: "{value} cm",
            },
          },

          dataZoom: [
            {
              startValue: "1970-01-01",
            },
            {
              type: "inside",
            },
          ],
          series: {
            name: "TMA",
            type: "line",
            data: resp.map((r) => r.tma).reverse(),
          },
        };
      }
    });
  },
  computed: {
    ...mapState({
      dinasHidrologi: (state) => state.dinasHidrologi.items,
    }),
  },
  methods: {
    ...mapActions("dinasHidrologi", ["fetchPosDinasPda"]),

    onPageChange(page) {
      this.pagePda = page;
    },

    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
    increment() {
      this.pagePda += 1;
    },
    decrement() {
      this.pagePda -= 1;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 450px;
  width: 100%;
}

@media (max-width: 868px) {
  .chart {
    height: 200px;
    width: 360px;
  }
}
</style>