import axiosInstance from '../../conf/axiosAdmin';


export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
       async fetchCluster({state, commit}){
            commit('setItems', {resource: 'clusterSungai', items: []}, {root: true})
           return await axiosInstance.get('/api/cluster/sungai/search')
            .then(res => {
                const clusters = res.data
                commit('setItems', {resource: 'clusterSungai', items: clusters}, {root: true})
                return state.items
             })
        },
       async fetchClusterByid({state, commit}, id){
            commit('setItem', {resource: 'clusterSungai', item: []}, {root: true})
           return await axiosInstance.get(`/api/cluster/sungai/view/${id}`)
            .then(res => {
                const cluster = res.data
                commit('setItem', {resource: 'clusterSungai', item: cluster}, {root: true})
                return state.item
             })
        },
       async addCluster({state, commit}, payload){
            commit('setItem', {resource: 'clusterSungai', item: {}}, {root: true})
            return await axiosInstance.post('/api/cluster/sungai/add', payload)
            .then(res => {
                const cluster = res.data
                commit('setItem', {resource: 'clusterSungai', item: cluster}, {root: true})
                return state.item
             })
        },
       async updateCluster({state, commit}, payload){
            commit('setItem', {resource: 'clusterSungai', item: {}}, {root: true})
            return await axiosInstance.put(`/api/cluster/sungai/Update/${payload.id}`, payload)
            .then(res => {
                const cluster = res.data
                commit('setItem', {resource: 'clusterSungai', item: cluster}, {root: true})
                return state.item
             })
        },       
        deleteCluster({state, commit}, id){
            commit('setItem', {resource: 'clusterSungai', item: {}}, {root: true})
            return axiosInstance.delete(`/api/cluster/sungai/delete/${id}`)
            .then(res => {
                const cluster = res.data
                commit('setItem', {resource: 'clusterSungai', item: cluster}, {root: true})
                return state.item
             })
        },


    },
}
