import axiosInstance from '../../conf/axiosAdmin';

export default {
    namespaced: true,
    state: {
        items: [],
        item: {},
    },
    actions: {       
        fetchChannelHourly({state,commit}, payload) {
            commit('setItems', {resource: 'searchChannel', items: []}, {root: true})
            return axiosInstance.post(`/api/channel/search/hourly/pda/${payload.registerId}`,payload.search)
            .then(res => {
                const channels = res.data.channels                
                commit('setItems', {resource: 'searchChannel', items: channels}, {root: true})
                return state.items
                })
            }, 
        fetchChannelDaily({state,commit}, payload) {
            commit('setItems', {resource: 'searchChannel', items: []}, {root: true})
            return axiosInstance.post(`/api/channel/search/daily/pda/${payload.registerId}`, payload.search)
            .then(res => {
                const channels = res.data.channels                 
                commit('setItems', {resource: 'searchChannel', items: channels}, {root: true})
                return state.items
                })
        },
        
         
    },
    mutations: {
        
    }
    
}