import axios from '../../conf/axios';

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: { 
        fetchCluster({state, commit}){
            commit('setItems', {resource: 'landingCluster', items: []}, {root: true})
           return axios.get('/api/landing/cluster/sungai/search')
            .then(res => {
                const clusters = res.data.clusters
                commit('setItems', {resource: 'landingCluster', items: clusters}, {root: true})
                return state.items
             })
        },
        
    },
    mutations: {

    }
}