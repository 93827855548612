import axiosInstance from '../../conf/axiosAdmin';
import moment from "moment";

export default {
    namespaced: true,
    state: {
        items: [],
        item: {},
    },
    actions: {
       async fetchChannel({state,commit}, registerId) {
            commit('setItems', {resource: 'channel', items: []}, {root: true})
            return await axiosInstance.get(`/api/channel/view/pda/${registerId}`)
            .then(res => {
                let payloads = res.data.channels
                const channels = [];
                payloads.slice().reverse()
                .forEach((item) => {  
                    item.tanggal =  moment(String(item.tanggal)).format('YYYY-MM-DD HH:mm:ss')
                    channels.push(item);
                });
                                             
                commit('setItems', {resource: 'channel', items: channels}, {root: true})
                // commit('mergeChannels', channels)
                return state.items
                })
        },
        
       async fetchChannelHourly({state,commit}, payload) {
            commit('setItems', {resource: 'channel', items: []}, {root: true})
            return await axiosInstance.post(`/api/channel/search/hourly/pda/${payload.registerId}`,payload.search)
            .then(res => {
                const channels = res.data.channels                
                commit('setItems', {resource: 'channel', items: channels}, {root: true})
                return state.items
                })
            }, 
       async fetchChannelDaily({state,commit}, payload) {
            commit('setItems', {resource: 'channel', items: []}, {root: true})
            return await axiosInstance.post(`/api/channel/search/daily/pda/${payload.registerId}`, payload.search)
            .then(res => {
                const channels = res.data.channels                 
                commit('setItems', {resource: 'channel', items: channels}, {root: true})
                return state.items
                })
        },
        addPda({state, commit}, payload){            
            let index = state.items.length         
            let channel = payload
            channel.tanggal =  moment(String(channel.tanggal)).format('YYYY-MM-DD HH:mm:ss')
             
            commit('addItemToArray', {item: channel, index, resource: 'channel'}, {root: true})             
            return state.items
        },
         
    },
    mutations: {
        
    }
    
}