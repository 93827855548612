var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"pengumuman"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control"},[_c('ModalPengumuman')],1)])]),_c('div',{staticClass:"page-content-wrapper"},[_c('div',{staticClass:"dashboard-map-wrapper"},[_c('div',{staticClass:"dashboard-map-wrapper-inner"},[_c('div',{staticClass:"map",attrs:{"id":"mapbox-1"}},[_c('HomeMapBaru',{attrs:{"position":_vm.position}})],1),_c('div',{staticClass:"content-section"},[_vm._m(0),_c('div',{staticClass:"content-section-body"},[_c('h2',{staticClass:"titles"},[_vm._v("Informasi Pos Duga Air Otomatis")]),_c('h4',{staticClass:"text"},[_vm._v("Data Per 5 menit")]),_vm._l((_vm.posList),function(pos,index){return _c('div',{key:index,staticClass:"content",attrs:{"lat-lng":[pos.lat, pos.lng]}},[_c('div',{staticClass:"box map-box"},[_c('div',{staticClass:"map-box-place",on:{"click":function($event){return _vm.handleClick([
                      pos.lat,
                      pos.lng,
                      pos.namaPos,
                      pos.registerId,
                    ])}}},[_c('div',{staticClass:"map-box-header"},[_c('div',{staticClass:"media-flex-center"},[_c('div',{staticClass:"flex-meta"},[_c('span',[_vm._v(_vm._s(pos.namaPos))]),_c('span',[_vm._v(_vm._s(pos.lokasiPos))])])])]),_c('div',{staticClass:"map-box-body"},[_c('table',{staticClass:"table"},[_c('tbody',[(pos.das)?_c('tr',[_c('td',[_vm._v("Das")]),_c('td',[_vm._v(":")]),_c('td',[_vm._v(_vm._s(pos.das ? pos.das : ""))])]):_vm._e(),(pos.sungai)?_c('tr',[_c('td',[_vm._v("Sungai")]),_c('td',[_vm._v(":")]),_c('td',[_vm._v(_vm._s(pos.sungai ? pos.sungai : ""))])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Waktu")]),_c('td',[_vm._v(":")]),_c('td',[_vm._v(_vm._s(pos.timestamp ? pos.timestamp : ""))])]),_c('tr',[_c('td',[_vm._v("TMA")]),_c('td',[_vm._v(":")]),_c('td',[_vm._v(_vm._s(pos.tma ? pos.tma : "-")+" cm")])]),_c('tr',[_c('td',[_vm._v("Status")]),_c('td',[_vm._v(":")]),(pos.status === 'AWAS')?_c('td',[_c('span',{staticClass:"tag is-success is-square"},[_vm._v(" "+_vm._s(pos.status ? pos.status : "AWAS")+" ")])]):(pos.status === 'SIAGA')?_c('td',[_c('span',{staticClass:"tag is-warning is-square"},[_vm._v(" "+_vm._s(pos.status ? pos.status : "SIAGA")+" ")])]):(pos.status === 'WASPADA')?_c('td',[_c('span',{staticClass:"tag is-danger is-square"},[_vm._v(" "+_vm._s(pos.status ? pos.status : "WASPADA")+" ")])]):_vm._e()])])]),_c('span',{staticClass:"content-section-title"},[_vm._v(" (* Klik 2x untuk melihat posisi pada peta) ")])])])])])})],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-section-header"},[_c('div',{staticClass:"toolbar"})])
}]

export { render, staticRenderFns }