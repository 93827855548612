<template>
  <div class="main-sidebar">
    <div class="sidebar-brand">
      <a href="/">
        <img class="light-image" src="../../assets/logoPu.png" alt="" />
        <img class="dark-image" src="../../assets/logoPu.png" alt="" />
      </a>
    </div>
    <div class="sidebar-inner">
      <!-- Plus 64 per menu -->
      

      <!-- User account -->
      <ul class="bottom-menu">
        <!-- Notifications -->
        <!-- {{> sidebar-item-search}} -->
        <!-- Wallet -->
        <!-- {{> sidebar-item-settings}} -->
        <!-- Profile -->
        <!-- {{> sidebar-item-profile}} -->
        <li>
          <div
            id="profile-menu"
            class="dropdown profile-dropdown dropdown-trigger is-spaced is-up"
          >
             <a @click.prevent="logout" class="button h-button is-success is-raised hint--info hint--top" data-hint="Logout">
               <i class="lnil lnil-exit"></i>
             </a>
              
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>


<script> 
export default {
  name: "SidebarNav",
  components: {
     
  },
  data() {
    return {
      location: "",
    };
  },
  created() {
    const loc = this.$route.name;
    if (loc === "Profile") {
      this.location = "150px";
    } else if (loc === "Device" || loc === "DeviceDetail") {
      this.location = "214px";
    } else if (loc === "Pos" || loc === "PosDetail" || loc === "inputKadaster" || loc === "updateKadaster") {
      this.location = "278px";
    } else if (loc === "posDinasList" || loc === "PosDinasDetail" || loc === "FormInputPos" || loc === "FormUpdatePos") {
      this.location = "342px";
    }else if (loc === "KualitasAirList" || loc === "FormInputKualitasAir" || loc === "FormUpdateKualitasAir") {
      this.location = "406px";  
    } else if (loc === "DasList" || loc === "FormInputDas" || loc === "FormUpdateDas") {
      this.location = "466px";
    } else if (loc === "SungaiList" || loc === "FormInputSungai" || loc === "FormUpdateSungai") {
      this.location = "526px";
    } else if (loc === "ForecastingList" || loc === "inputForecasting" || loc === "updateForecasting") {
      this.location = "590px";
    } else if (loc === "ClusterList" || loc === "FormInputCluster" || loc === "UpdateKontenInformasi") {
      this.location = "654px";
    } else if (loc ===  "KontenInfoList" || loc === "InputKontenInformasi" || loc === "UpdateKontenInformasi") {
      this.location = "718px";
    }
    // console.log(this.loc)
    // console.log(this.location)
  },
  methods: {
     logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push(this.$route.query.redirect || "/login"));
    },
  }
};
</script>