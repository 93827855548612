<template>
<div>   
    <!-- <MobileNavbar />
    <MobileSidebarMain />
    <Sidebar />
    <KualitasAirMap /> -->
    <KualitasAirMapBaru/>
</div> 
</template>

<script>
// import Sidebar from "../components/navigation/Sidebar.vue";
// import MobileNavbar from "../components/navigation/MobileNavbar.vue";
// import MobileSidebarMain from "../components/navigation/MobileSidebarMain.vue";
// import KualitasAirMap from "../components/landing/KualitasAirMap.vue";
import KualitasAirMapBaru from "../components/landing/KualitasAirMapBaru.vue";
export default {
   name: 'KualitasAir',
   components: {       
    // Sidebar,
    // MobileNavbar,
    // MobileSidebarMain,
    // KualitasAirMap,
      KualitasAirMapBaru
  },
}
</script>