import axiosInstance from '../../conf/axiosAdmin';


export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
       async fetchDas({state, commit}){
            commit('setItems', {resource: 'das', items: []}, {root: true})
           return await axiosInstance.get('/api/das/manage/search')
            .then(res => {
                const das = res.data.dasList                               
                commit('setItems', {resource: 'das', items: das}, {root: true})
                return state.items
             })
        },
       async searchDas({state, commit}, params){
          commit('setItems', {resource: 'das', items: []}, {root: true})
         return await axiosInstance.get(`/api/das/manage/search?${params}`)
          .then(res => {
              const results = res.data
              commit('setItems', {resource: 'das', items: results}, {root: true})
              return state.items
           })
      },
      async  fetchDasById({state, commit}, dasId){
          commit('setItem', {resource: 'das', item: {}}, {root: true})
          return await axiosInstance.get(`/api/das/manage/view/${dasId}`)
          .then(res => {
              const das = res.data                 
              commit('setItem', {resource: 'das', item: das}, {root: true})
              return state.item
           })
        },
        createDas(context, payload){
            const headers = {
                'Content-Type': 'application/json',
              }
            return axiosInstance.post('/api/das/manage/create', 
            payload,{
                headers: headers
              })
              .then(res => res.data)
        },
        addDas({commit,state}, das) {
          const dases = state.items
          commit('setItems', {resource: 'das', items: [...dases, das]}, {root: true})
        },
        updateDas(context, payload){
          console.log(payload)
          const headers = {
            'Content-Type': 'application/json',
          }          
            return axiosInstance.put(`/api/das/manage/update/${payload.id}`, payload,
            {
              headers: headers
            })             
             
        },
        deletDas({state, commit}, id){                            
          commit('setItem', {resource: 'das', item: {}}, {root: true})
          return axiosInstance.delete(`/api/das/manage/remove/${id}`)
          .then(res => {
              const das = res.data                 
              commit('setItem', {resource: 'das', item: das}, {root: true})
              return state.item
           })
        },
         
    },
    mutations: {
    
    }
}