import axiosInstance from '../../conf/axiosAdmin';
export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
       async fetchHidrologiKodeRegister({ state, commit }, regId) { 
            commit('setItems', {resource: 'uploadHidrologi', items: []}, {root: true}) 
            return await axiosInstance.get(`/api/hidrologi/file/search/${regId}`)
                .then(res => {
                    const uploadHidrologi = res.data.data
                    
                    commit('setItems', { resource: 'uploadHidrologi', items: uploadHidrologi }, { root: true })
                    return state.items
                })
        },
       async searchHidrologi({state,commit}, params) {
            commit('setItems', {resource: 'uploadHidrologi', items: [] }, {root: true })
            return await axiosInstance.get(`/api/hidrologi/search/pda?${params}`)
                .then(res => {
                    const results = res.data.kanalList
                    commit('setItems', {
                        resource: 'uploadHidrologi',
                        items: results
                    }, {
                        root: true
                    })
                    return state.items
                })
        },

        hidrologiPdaUpload(context, { regId, formData }) { 
            const headers = {
                'Content-Type': 'multipart/form-data',
            }
            return axiosInstance.post(`/api/hidrologi/pda/upload/${regId}`,
                formData, {
                headers: headers
            })
                .then(res => res.data)
        },
        hidrologiPchUpload(context, { regId, formData}) {
            
            const headers = {
                'Content-Type': 'multipart/form-data',
            }
            return axiosInstance.post(`/api/hidrologi/pch/upload/${regId}`,
                formData, {
                headers: headers
            })
                .then(res => res.data)
        },


    },
    mutations: {

    }
}