<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
            <div
              class="huro-hamburger nav-trigger push-resize"
              data-sidebar="layouts-sidebar"
            >
              <span class="menu-toggle has-chevron">
                <span class="icon-box-toggle">
                  <span class="rotate">
                    <i class="icon-line-top"></i>
                    <i class="icon-line-center"></i>
                    <i class="icon-line-bottom"></i>
                  </span>
                </span>
              </span>
            </div>

            <div class="title-wrap">
              <h1 class="title is-4">Data Forecasting</h1>
            </div>
          </div>

          <!--Header-->

          <div class="page-content-inner">
            <div class="map" v-if="!isLoading">
              <div class="cards">
                <ForecastingMap :forecastFile="forecastFile" />
              </div>
            </div>
            <br />

            <div class="list-view-toolbar">
              

              <div class="tabs-inner">
                <router-link
                  to="/dashboard/inputforecasting"
                  class="button h-button is-success is-outlined is-raised"
                >
                  <i class="fal fa-plus"></i>
                  <span class="tambah">Tambah Forecasting</span>
                </router-link>
              </div>
            </div>

            <div class="page-content-inner">
                  <div class="flex-list-wrapper flex-list-v2">

                    <!--List Empty Search Placeholder -->
                    <div class="page-placeholder custom-text-filter-placeholder is-hidden">
                        <div class="placeholder-content">
                            <img class="light-image" src="../../assets/img/illustrations/placeholders/search-4.svg" alt="" />
                            <img class="dark-image" src="../../assets/img/illustrations/placeholders/search-4-dark.svg"
                                alt="" />
                            <h3>We couldn't find any matching results.</h3>
                            <p class="is-larger">Too bad. Looks like we couldn't find any matching results for the
                                search terms you've entered. Please try different search terms or criteria.</p>
                        </div>
                    </div>

                    <!--Active Tab-->
                    <div id="active-items-tab" class="tab-content is-active" v-if="!isLoading">
                         <!-- Datatable -->
                            <div class="table-wrapper">
                                 
                                 <b-table
                                    class="table is-datatable is-hoverable table-is-bordered"
                                    :data="isEmpty ? [] : data"
                                    :bordered="isBordered"
                                    :striped="isStriped"
                                    :narrowed="isNarrowed"
                                    :hoverable="isHoverable"
                                    :loading="isLoading"
                                    :focusable="isFocusable"
                                    :mobile-cards="hasMobileCards"
                                    
                                    >
                                  
                                <b-table-column
                                    field="startShowPeriod"
                                    label="Start Periode"
                                    :td-attrs="columnTdAttrs"
                                    v-slot="props"
                                >
                                    {{ props.row.startShowPeriod }}
                                </b-table-column>
                                <b-table-column
                                    field="endShowPeriod"
                                    label="End Periode"
                                    :td-attrs="columnTdAttrs"
                                    v-slot="props"
                                >
                                    {{ props.row.endShowPeriod }}
                                </b-table-column>
                                <b-table-column
                                    field="forecastFile"
                                    label="Nama File"
                                    :td-attrs="columnTdAttrs"
                                    v-slot="props"
                                >
                                    {{ props.row.forecastFile.oriFileName }}
                                </b-table-column>
                                <b-table-column
                                    field="Status"
                                    label="Status"
                                    :td-attrs="columnTdAttrs"
                                    v-slot="props"
                                >
                                <span class="tag is-success" v-if="props.row.isActive">
                                    {{ props.row.isActive ? 'AKTIF' : 'TIDAK AKTIF' }}
                                </span>
                                <span class="tag is-danger" v-else>
                                    {{ props.row.isActive ? 'AKTIF' : 'TIDAK AKTIF' }}
                                </span>
                                    
                                </b-table-column>

                                <b-table-column 
                                field="id" 
                                label=""
                                v-slot="props"
                                 >

                                  <b-button
                                    @click="showMap(props.row.forecastFile.fileName)"
                                    type="is-primary"
                                    outlined
                                >Tampilkan Peta</b-button>
                                 
                                
                                                    
                                </b-table-column>
                                 <b-table-column 
                                field="id" 
                                label=""
                                v-slot="props"
                                 >
                                  <router-link class="button h-button is-info is-elevated" :to="`/dashboard/updateforecasting/${props.row.id}`">
                                            <span class="icon">
                                                <i aria-hidden="true" class="lnir lnir-scan"></i>
                                            </span>
                                            <span>pratinjau</span>
                                 </router-link>  
                                 </b-table-column>

                                <template #empty>
                                    <div class="has-text-centered">No records</div>
                                </template>
                                
                                </b-table>
                            </div>
    
                        <!--Table Pagination-->
                        <div class="buttons is-right">
                            <b-button
                                @click="decrement"
                                type="is-info"
                                icon-pack="fas"
                                icon-left="chevron-left"
                                :disabled="hasDisablePreviousPage"
                            ></b-button>
                            <b-button
                                @click="increment"
                                type="is-info"
                                icon-pack="fas"
                                icon-left="chevron-right"
                                :disabled="hasDisableNextPage"
                            ></b-button>
                            </div>

                    </div>

                    <!--inactive Tab-->
                    <div id="active-items-tab" class="tab-content is-active" v-if="isLoading">

                        <!--Empty placeholder-->
                        <div class="page-placeholder">
                            <div class="placeholder-content">
                                <img class="light-image is-larger"
                                    src="../../assets/img/illustrations/placeholders/projects.svg" alt="" />
                                <img class="dark-image is-larger"
                                    src="../../assets/img/illustrations/placeholders/projects-dark.svg" alt="" />
                                <h3>Tidak Ada Data.</h3>
                                <p>Tidak ada Data Forecasting yang ditampilkan.</p>
                            </div>
                        </div>

                    </div>
                    
                </div>
                <!-- END content iner -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script> 
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
//import AppSpinner from "../shared/AppSpinner.vue";
import ForecastingMap from "../forecasting/ForecastingMap";
export default {
  name: "forecastingList",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    //AppSpinner,
    ForecastingMap,
  },
  data() {
    return { 
      data: [],
      total: 0,
      loading: false,
      sortField: "created",
      sortOrder: "DESC",
      defaultSortOrder: "DESC",
      page: 0,
      perPage: 10,     
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      forecastFile: null
    };
  },
  created(){
    this.isLoading = true;
    this.fetchForecasts().then(() => {
       
      this.isLoading = false;
    }); 
  },
  beforeMount(){
    const authority = localStorage.getItem('authority')
    if(authority === 'DINAS'){
      this.$router.push('/dashboard/dinas')
    }
  },
  computed: {
    ...mapState({
      results: (state) => state.forecasting.items,
    }),
  },
  methods: {
    ...mapActions("forecasting", ["fetchForecasts","deactiveForecastingById"]),
    
    loadAsyncData() {      
      this.fetchForecasts().then(() => {
       
        let response = this.results; 
        this.data = [];
        let enablePreviousPage = response.hasPreviousPage;
        let enableNextPage = response.hasNextPage;
        if (enablePreviousPage) {
           
          this.hasDisablePreviousPage = false;
        } else {
          this.hasDisablePreviousPage = true;
        }

        if (enableNextPage) {
          
          this.hasDisableNextPage = false;
        } else {
          this.hasDisableNextPage = true;
        }

        response.forEach((item) => {
          this.isLoading = false; 
          this.data.push(item);
        });
        // .catch((error) => {
        //   this.data = [];
        //   this.total = 0;
        //   this.loading = false;
        //   throw error;
        // });
      });
    },

     onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
    cari() {
      this.loadAsyncData();
    },
    dateThAttrs(column) {
      return column.label === "Date"
        ? {
            title: 'This title is sponsored by "th-attrs" prop',
            class: "has-text-success",
          }
        : null;
    },
    columnTdAttrs(row, column) {
      if (row.id === "Total") {
        if (column.label === "ID") {
          return {
            colspan: 4,
            class: "has-text-weight-bold",
            style: {
              "text-align": "left !important",
            },
          };
        } else if (column.label === "Gender") {
          return {
            class: "has-text-weight-semibold",
          };
        } else {
          return {
            style: { display: "none" },
          };
        }
      }
      return null;
    },
    remove(index) {
      console.log("index" + index);
    },
    showMap(fileName){
      // console.log(fileName)
      this.forecastFile = fileName;
    } 
    
  },
  mounted() {
    this.loadAsyncData();
  }
   
};
</script>

<style scoped>
.map {
  min-width: 100%;
  height: 60vh;
}
.lnir-air-flow {
  margin-right: 10px;
  color: #777;
}
.lnir-scan {
  margin-right: 10px;
}
.fa-sharp {
  color: #777;
}
.icon-separator {
  margin-right: 10px;
}

.titles {
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
  color: #777;
}
.fa-water {
  color: #777;
  margin-right: 7px;
}
.fal {
  margin-right: 10px;
  font-weight: 400;
}
.tambah {
  font-weight: 400;
}
.lat {
  font-size: 14px;
  font-weight: 400;
  margin-left: 20px;
  color: #777;
}
.long {
  font-size: 14px;
  font-weight: 400;
  margin-left: 15px;
  color: #777;
}

.periode {
  margin-left: 17px;
  font-weight: 400;
  color: #777;
}

@media (max-width: 768px) {
  .fa-water {
    margin-right: 5px;
    color: #777;
  }
  .lat {
    font-size: 12px;
    font-weight: 400;
    margin-left: 20px;
    color: #777;
  }
  .long {
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
    color: #777;
  }
  .titles {
    font-size: 12px;
    font-weight: 400;
    color: #777;
    margin-left: 5px;
  }

  .periode {
    font-size: 12px;
    margin-left: 20px;
    font-weight: 400;
    color: #777;
  }
}
</style>