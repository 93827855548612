<template>
  <div>
   
    <ErrorView :title="'Ooooops, Anda Tidak dapat membuka halaman ini, silahkan login terlebih dahulu'"
               :status="'401'"
               :navigateToPage="'PageLogin'"
               :navigateToText="'Beranda'" />
  </div>
</template>

<script>
  import ErrorView from './ErrorView'
  export default {
    components: {
      ErrorView,
    }
  }
</script>

<style scoped>


</style> 