<template>
  <div>
    <MobileNavbar />
    <MobileSidebarMain />
    <Sidebar />
    <!-- Content Wrapper -->
    <div
      id="app-list"
      class="view-wrapper"
      data-naver-offset="214"
      data-menu-item="#layouts-sidebar-menu"
      data-mobile-item="#home-sidebar-menu-mobile"
    >
      <div class="page-content-wrapper">
        <div
          class="
            page-content
            is-relative
            tabs-wrapper
            is-slider is-squared is-inverted
          "
        >
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
            <div class="title-wrap">
              <h1 class="title is-4">Konten Info</h1>
            </div>
          </div>
          <div class="page-content-inner">
            <!--List-->
            <div class="list-view list-view-v3">
              <!--List Empty Search Placeholder -->
              <div
                class="
                  page-placeholder
                  custom-text-filter-placeholder
                  is-hidden
                "
              >
                <div class="placeholder-content">
                  <img
                    class="light-image"
                    src="assets/img/illustrations/placeholders/search-3.svg"
                    alt=""
                  />
                  <img
                    class="dark-image"
                    src="assets/img/illustrations/placeholders/search-3-dark.svg"
                    alt=""
                  />
                  <h3>We couldn't find any matching results.</h3>
                  <p class="is-larger">
                    Too bad. Looks like we couldn't find any matching results
                    for the search terms you've entered. Please try different
                    search terms or criteria.
                  </p>
                </div>
              </div>

              <!--Active Tab-->
              <div id="active-items-tab" class="tab-content is-active">
                <div class="list-view-inner">
                  <!--Item-->
                  <div class="list-view-item">
                    <div class="list-view-item-inner">
                      <img
                        class="avatar"
                        src="../assets/img/illustrations/apps/huro-banking-light.png"
                        alt=""
                      />
                      <div class="meta-left">
                        <h3 data-filter-match>Data Olahan</h3>
                        <span>
                          <i data-feather="map-pin"></i>
                          <span data-filter-match
                            >Menampilkan informasi data-data hasil olahan Unit
                            Hidrologi dan Kualitas Air, atau data terkait
                            pengelolaan Sumber Daya Air di Wilayah Kerja BBWS
                            Cimanuk Cisanggarung, maupun Dinas terkait. Untuk
                            Selengkapnya dapat mengunjungi Unit Hidrologi BBWS
                            Cimanuk Cisanggarung.</span
                          >
                        </span>
                      </div>
                      <div class="meta-right">
                        <div class="buttons">
                           <router-link to="/konteninfo/dataolahan">
                           <a
                            class="
                              button
                              h-button
                              is-primary is-outlined is-raised
                            "
                            >Pratinjau</a>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Item-->
                  <div class="list-view-item">
                    <div class="list-view-item-inner">
                      <img
                        class="avatar"
                        src="../assets/img/placeholders/huro-1.svg"
                        alt=""
                      />
                      <div class="meta-left">
                        <h3 data-filter-match>Informasi Analisa</h3>
                        <span>
                          <i data-feather="map-pin"></i>
                          <span data-filter-match
                            >Menampilkan informasi hasil analisa Hidrologi dan
                            Kualitas Air, atau data terkait pengelolaan Sumber
                            Daya Air di Wilayah Kerja BBWS Cimanuk Cisanggarung,
                            maupun Dinas terkait. Untuk Selengkapnya dapat
                            mengunjungi Unit Hidrologi BBWS Cimanuk
                            Cisanggarung.</span
                          >
                        </span>
                      </div>
                      <div class="meta-right">
                        <div class="buttons">
                          <router-link to="/konteninfo/informasianalisa">
                           <a
                            class="
                              button
                              h-button
                              is-primary is-outlined is-raised
                            "
                            >Pratinjau</a>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Item-->
                  <div class="list-view-item">
                    <div class="list-view-item-inner">
                      <img
                        class="avatar"
                        src="../assets/img/illustrations/dashboards/lifestyle/reading.svg"
                        alt=""
                      />
                      <div class="meta-left">
                        <h3 data-filter-match>Informasi Umum</h3>
                        <span>
                          <i data-feather="map-pin"></i>
                          <span data-filter-match
                            >Menampilkan informasi umum terkait kegiatan Unit
                            Hidrologi dan Kualitas Air, Koordinasi dan
                            Sosialisasi, maupun informasi umum terkait kegiatan
                            BBWS Cimanuk Cisanggarung.</span
                          >
                        </span>
                      </div>
                      <div class="meta-right">
                        <div class="buttons">
                          <router-link to="/konteninfo/informasiumum">
                           <a
                            class="
                              button
                              h-button
                              is-primary is-outlined is-raised
                            "
                            >Pratinjau</a>
                          </router-link>
                          <!-- <a
                            class="
                              button
                              h-button
                              is-primary is-outlined is-raised
                            "
                            >Pratinjau</a> -->
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--Inactive Tab-->
              <div id="inactive-items-tab" class="tab-content">
                <div class="list-view-inner">
                  <!--Empty placeholder-->
                  <div
                    class="page-placeholder custom-text-filter-placeholder"
                    data-filter-hide
                  >
                    <div class="placeholder-content">
                      <img
                        class="light-image"
                        src="assets/img/illustrations/placeholders/thinking-canvas.svg"
                        alt=""
                      />
                      <img
                        class="dark-image"
                        src="assets/img/illustrations/placeholders/thinking-canvas-dark.svg"
                        alt=""
                      />
                      <h3>No saved jobs.</h3>
                      <p class="">
                        Looks like you don't have any saved jobs for the moment.
                        It's also possible that some of your saved items expired
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Sidebar from "../components/navigation/Sidebar.vue";
import MobileNavbar from "../components/navigation/MobileNavbar.vue";
import MobileSidebarMain from "../components/navigation/MobileSidebarMain.vue";
export default {
  name: "KontenInfo",
  components: {
    Sidebar,
    MobileNavbar,
    MobileSidebarMain,
  },
};
</script>