<template>
  <div class="business-dashboard company-dashboard ecommerce-dashboard-v1">
    <div class="datatable-toolbar">
      <div class="buttons">
        <!-- SEARCH PDA -->
        <SearchPch/>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="dashboard-card is-base-chart">
          <div class="content-box">
            <div class="revenue-stats is-dark-bordered-12">
              Volume (mm)
            </div>
          </div>
          <v-chart autoresize class="chart" :option="arr" />
        </div>
      </div>

      <div class="column is-12">
        <!-- Datatable -->

        <div class="table-wrapper">
          <b-table
            class="table is-datatable is-hoverable table-is-bordered"
            :data="data"
            :columns="columns"
            focusable
            paginated
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @sort="onSort"
            icon-pack="fas"
            icon-left="chevron-right"
          >
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import VChart from "vue-echarts";
import SearchPch from './SearchPch.vue'
export default {
  name: "TablePdaTelemetry",
  components: {
    VChart,
    SearchPch
  }, 
  data() {
    return {
      data: [],
      page: 0,
      perPage: 10,
      total: 0,
      columns: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "value",
          label: "Volume (mm)",
        },
      ],
      sortField: "tanggal",
      sortOrder: "ASC",
      defaultSortOrder: "ASC",
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      arr:{},
    };
  },
  created() {
    this.fetchPosDinasPch().then((resp) => {
      if (resp != null) {
        let currentTotal = resp.length;
        if (currentTotal / this.perPage > 1000) {
          currentTotal = this.perPage * 1000;
        }
        this.total = currentTotal;

        resp.forEach((item) => {
          this.loading = false;
          this.data.push(item);
        });

        const colors = ["#5470C6", "#91CC75", "#EE6666"];
        this.arr = {
            color: colors,
            title: {
              
              left: "1%",
            },
            legend: {},
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
              },
            },
            dataZoom: [
              {
                type: "inside",
              },
              {
                type: "slider",
              },
            ],
            xAxis: {
              type: "category",
              axisTick: {
                alignWithLabel: true,
              },
              data: resp.map((r) => r.tanggal).reverse(),
            },
            yAxis: {
              type: "value",
              name: "Volume",
              position: "right",
              alignTicks: true,
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[0],
                },
              },
              axisLabel: {
                formatter: "{value} mm",
              },
            },
            series: [
              {
                data: resp.map((r) => r.value).reverse(),
                type: "bar",
              },
            ],
          };
      }
    });
  },
  computed: {
    ...mapState({
      dinasHidrologi: (state) => state.dinasHidrologi.items,
    }),
  },
  methods: {
   ...mapActions("dinasHidrologi", ["fetchPosDinasPch"]),

    onPageChange(page) {
      this.page = page;
    },

    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
    increment() {
      this.page += 1;
    },
    decrement() {
      this.page -= 1;
    },
  },
};
</script>

<style scoped>
.chart {
  height: 450px;
  width: 100%;
}

@media (max-width: 868px) {
  .chart {
    height: 200px;
    width: 360px;
  }
}
</style>