import axiosInstance from '../../conf/axiosAdmin';
export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
       async fetchFilePosDinas({ state, commit }) { 
            commit('setItems', {resource: 'uploadDinas', items: []}, {root: true}) 
            return await axiosInstance.get('/api/kedinasan/file/search')
                .then(res => {
                    const uploadHidrologi = res.data.data
                   
                    commit('setItems', { resource: 'uploadDinas', items: uploadHidrologi }, { root: true })
                    return state.items
                })
        },
       async searchHidrologi({state,commit}, params) {
            commit('setItems', {resource: 'uploadHidrologi', items: [] }, {root: true })
            return await axiosInstance.get(`/api/hidrologi/search/pda?${params}`)
                .then(res => {
                    const results = res.data.kanalList
                    commit('setItems', {
                        resource: 'uploadHidrologi',
                        items: results
                    }, {
                        root: true
                    })
                    return state.items
                })
        },

        pdaUpload(context, {formData }) { 
            const headers = {
                'Content-Type': 'multipart/form-data',
            }
            return axiosInstance.post('/api/kedinasan/pda/upload',
                formData, {
                headers: headers
            })
                .then(res => res.data)
        },
        pchUpload(context, {formData}) {
            
            const headers = {
                'Content-Type': 'multipart/form-data',
            }
            return axiosInstance.post('/api/kedinasan/pch/upload',
                formData, {
                headers: headers
            })
                .then(res => res.data)
        },


    },
    mutations: {

    }
}