<template>
  <div class="informasipos">
    <div class="layer2" :class="{ show: showSidebar }">
      <div class="tombol" :class="{ show: showSidebar }" @click="showNav">
        <div class="button is-focused">
          <div class="texts">Telemetri</div>
        </div>
      </div>

      <div class="container">
        <div class="content-section-header">
          <h2 class="titless">Informasi Pos Duga Air Otomatis</h2>
          <div class="toolbar ml-auto"></div>
        </div>
        <div class="content-section-body">
          <h4 class="text1">Data Per 5 menit</h4>

          <div
            class="content"
            v-for="(pos, index) in posList"
            :lat-lng="[pos.lat, pos.lng]"
            :key="index"
          >
            <div class="box map-box">
              <div
                class="map-box-place"
                @click="
                  handleClick([pos.lat, pos.lng, pos.namaPos, pos.registerId])
                "
              >
                <div class="map-box-header">
                  <div class="media-flex-center">
                    <div class="flex-meta">
                      <span>{{ pos.namaPos }}</span>
                      <span>{{ pos.lokasiPos }}</span>
                    </div>
                  </div>
                </div>

                <div class="map-box-body">
                  <table class="table">
                    <tbody>
                      <tr v-if="pos.das">
                        <td>Das</td>
                        <td>:</td>
                        <td>{{ pos.das ? pos.das : "" }}</td>
                      </tr>
                      <tr v-if="pos.sungai">
                        <td>Sungai</td>
                        <td>:</td>
                        <td>{{ pos.sungai ? pos.sungai : "" }}</td>
                      </tr>
                      <tr>
                        <td>Waktu</td>
                        <td>:</td>
                        <td>{{ pos.timestamp ? pos.timestamp : "" }}</td>
                      </tr>
                      <tr>
                        <td>TMA</td>
                        <td>:</td>
                        <td>{{ pos.tma ? pos.tma : "-" }} cm</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>:</td>
                        <td v-if="pos.status === 'AMAN'">
                          <span class="tag is-success is-square">
                            {{ pos.status ? pos.status : "AMAN" }}
                          </span>
                        </td>
                        <td v-else-if="pos.status === 'SIAGA'">
                          <span class="tag is-warning is-square">
                            {{ pos.status ? pos.status : "SIAGA" }}
                          </span>
                        </td>
                        <td v-else-if="pos.status === 'AWAS'">
                          <span class="tag is-danger is-square">
                            {{ pos.status ? pos.status : "AWAS" }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
let sseClient;
export default {
  name: "InformasiPos",
  components: {},

  data() {
    return {
      // position: [],
      // status: "NORMAL",
      showSidebar: false,
    };
  },
  created() {
    this.fetchLandingMonitor().then(() => {
      this.loading = false;
    });

    this.loadSubcribe();
  },
  computed: {
    ...mapState({
      kadasters: (state) => state.landingKadaster.items,
      monitorPda: (state) => state.monitorPda.items,
    }),
    posList() {
      return this.monitorPda.slice().reverse();
    },
  },
  methods: {
    ...mapActions("landingKadaster", ["fetchLandingKadaster"]),
    ...mapActions("monitorPda", ["fetchLandingMonitor"]),
    handleClick: function (event) {
      this.position = event;
      // console.log(this.position)
      this.$emit("posisi", this.position);
    },
    loadSubcribe() {
      sseClient = this.$sse.create({
        url: "/socket/sse/subscribe",
        format: "json",
        withCredentials: false,
        polyfill: true,
      });

      // Catch any errors (ie. lost connections, etc.)
      sseClient.on("error", (e) => {
        console.error("lost connection or failed to parse!", e);

        // If this error is due to an unexpected disconnection, EventSource will
        // automatically attempt to reconnect indefinitely. You will _not_ need to
        // re-add your handlers.
      });

      // Handle messages without a specific event
      sseClient.on("message", this.message);

      // Handle 'chat' messages
      sseClient.on("pdaMonitoring", (msg) => {
        this.$store.dispatch("monitorPda/dispatchPdaMonitor", msg);
        this.$store.dispatch("monitorPda/postPdaMonitor", msg);
      });

      sseClient
        .connect()
        .then((sse) => {
          console.log("Data Realtime connected!");

          // Unsubscribes from event-less messages after 7 seconds
          setTimeout(() => {
            sseClient.off("message", this.message);
            // console.log("Stopped listening to event-less messages!");
          }, 7000);

          // Unsubscribes from chat messages after 14 seconds
          setTimeout(() => {
            sse.off("pdaMonitoring", this.data);
            // console.log("Stopped listening to Pda Monitoring messages!");
          }, 14000);
        })
        .catch((err) => {
          // When this error is caught, it means the initial connection to the
          // events server failed.  No automatic attempts to reconnect will be made.
          console.error("Failed to connect to server", err);
        });
    },
    showNav() {
      this.showSidebar = !this.showSidebar;
    },
  },
  beforeDestroy() {
    // Make sure to close the connection with the events server
    // when the component is destroyed, or we'll have ghost connections!
    sseClient.disconnect();

    // Alternatively, we could have added the `sse: { cleanup: true }` option to our component,
    // and the SSEManager would have automatically disconnected during beforeDestroy.
  },
};
</script>

<style scoped>
.informasipos {
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: row-reverse;
}

.layer2 {
  position: relative;
  margin-top: 0%;
  right: -300px;
  backdrop-filter: blur(3px);
  background: rgba(255, 255, 255, 0.881);
  border: 1px solid rgba(233, 231, 231, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.171);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  border-radius: 10px;
  transition: 1s ease;
  transform: translateX(38px);
}

.layer2.show {
  position: relative;
  margin-top: 0%;
  right: 40px;
  backdrop-filter: blur(3px);
  background: rgba(255, 255, 255, 0.881);
  border: 1px solid rgba(233, 231, 231, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.171);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  border-radius: 10px;
  transition: 1.5s ease;
}

.layer2 .tombol {
  position: absolute;
  top: 10px;
  left: -34px;
  box-shadow: 0 5px 15px rgba(192, 191, 191, 0.109);
  transition: 0.95s ease;
}

.layer2 .tombol .button {
  position: absolute;
  width: 30px;
  height: 95px;
  background: #212c5f;
  background-size: 200%;
  cursor: pointer;
  border-radius: 10px 0 0 10px;
  transition: 0.9s ease-in-out;
}

.layer2 .tombol .button:hover {
  backdrop-filter: blur(15px);
  background: #2d6efd;
  transform: translateX(-5px);
  transition: 0.5s ease;
}

.layer2 .tombol .button.show {
  position: absolute;
  top: 10px;
  width: 30px;
  height: 95px;
  background: #212c5f;
  cursor: pointer;
  border-radius: 10px 0 0 10px;
  transition: 0.9s ease-in-out;
}

.texts {
  padding: 5px;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  color: #fff;
}

.layer2 .container {
  margin: 10px;
  width: 310px;
  height: 572px;
  overflow-y: auto;
}

.layer2 .container::-webkit-scrollbar {
  display: none;
}

.layer2 .container .map-box {
  margin-left: 50%;
  width: 100%;
  transform: translateX(-100%);
  opacity: 0;
  animation: slide-in-anim 5s ease-out forwards;
  border: 1px solid rgba(233, 231, 231, 0.617);
  border-bottom: 1px solid rgba(255, 255, 255, 0.171);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  background: #fff;
}

@keyframes show {
  0% {
    transform: translateX(700px);
  }
  100% {
    transform: translateX(48px);
  }
}

@keyframes hide {
  0% {
    display: block;
    transform: translateX(48px);
  }

  100% {
    display: block;
    transform: translateX(700px);
  }
}

@keyframes slide-in-anim {
  20% {
    opacity: 0;
  }
  60% {
    transform: translateX(-45%);
  }
  75% {
    transform: translateX(-52%);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%);
  }
}

@keyframes slide-in-anims {
  20% {
    opacity: 0;
  }
  60% {
    transform: translateX(80%);
  }
  75% {
    opacity: 0;
    transform: translateX(90%);
  }
  100% {
    opacity: 1;
    transform: translateX(100%);
  }
}

@keyframes slide-out-anim {
  20% {
    opacity: 1;
    transform: translateX(-40%);
  }
  50% {
    opacity: 1;
    transform: translateX(20%);
  }
  100% {
    opacity: 1;
    transform: translateX(-0%);
  }
}

.layer2 .container .content {
  font-size: 13px;
}

.layer2 .container .titless {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: black;
  margin-left: 5px;
  margin-bottom: 10px;
}
.layer2 .container .footers {
  margin-left: 10px;
}
.layer2 .container .text {
  font-size: 12px;
  font-weight: 500;
  color: gray;
}

.layer2 .container .text1 {
  font-size: 12px;
  font-weight: 400;
  color: gray;
  margin-bottom: 5px;
  margin-left: 5px;
}

.layer2 .container .text2 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: black;
}

@media screen and (max-width: 1368px) {
.layer2 {
  position: relative;
  margin-top: 0%;
  right: -320px;
  backdrop-filter: blur(3px);
  background: rgba(255, 255, 255, 0.881);
  border: 1px solid rgba(233, 231, 231, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.171);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  border-radius: 10px;
  transition: 1s ease;
  transform: translateX(38px);
}

.layer2.show {
  position: relative;
  margin-top: 0%;
  right: 20px;
  backdrop-filter: blur(3px);
  background: rgba(255, 255, 255, 0.881);
  border: 1px solid rgba(233, 231, 231, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.171);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  border-radius: 10px;
  transition: 1.5s ease;
}
}
</style>