<template>
  <div class="page-content-wrapper">
    <div class="page-content-inner">
      <div class="datatable-toolbar">
        <div class="buttons">
          <ModalInputKoef :registeredId="registerId" @submitted="submitted" />
        </div>
      </div>
       <div class="flex-list-wrapper flex-list-v1">
      <div class="flex-table">
        <!--Table header-->
        <div class="flex-table-header">
          <span>KoeF A</span>
          <span>KoeF B</span>
          <span>KoeF C</span> 
          <span>Actions</span>
        </div>

        <div v-if="isLoaded" class="flex-list-inner">
          <!--Table item-->
          <div class="flex-table-item" v-for="rumus in data" :key="rumus.id">
            <div class="flex-table-cell" data-th="KoeF A">
              <span class="light-text">{{ rumus.koefA}} </span>
            </div>
            <div class="flex-table-cell" data-th="KoeF B">
              <span class="light-text">{{ rumus.koefB}}  </span>
            </div>

            <div class="flex-table-cell" data-th="KoeF C">
              <span
                class="light-text"
                >{{ rumus.koefC }}  </span
              >              
            </div>
           
            <div class="flex-table-cell">
               <ModalUpdateKoef :rumusId="rumus.id" @submitted="submitted"/>
            </div>
            
          </div>
        </div>
        <div v-else class="flex-list-inner">
          <AppSpinner />
        </div>
      </div>

      
    </div>
       
  </div>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import ModalInputKoef from "./ModalInputKoef";
import ModalUpdateKoef from './ModalUpdateKoef.vue';
import AppSpinner from "../shared/AppSpinner.vue";
export default {
  name: "RumusDebit",
  components: { ModalInputKoef, ModalUpdateKoef,  AppSpinner},
  props: {
    registerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
      debId: this.registerId,
      selected: null,
      data: [],
      total: 0,
      loading: false,
      sortField: "created",
      sortOrder: "ASC",
      defaultSortOrder: "ASC",
      page: 0,
      perPage: 10,
      columns: [
        {
          field: "koefA",
          label: "Koef A",
        },
        {
          field: "koefB",
          label: "Koef B",
        },
        {
          field: "koefC",
          label: "Koef C",
        },
        {
          field: "Action",
          label: "Action",
        },
      ],
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
    };
  },
  created() {
    this.fetchRumusDebitRegisterId(this.registerId).then(() => {
      this.isLoaded = true;
    });
  },
  computed: {
    ...mapState({
      debit: (state) => state.debit.item,
    }),
  },
  methods: {
    ...mapActions("debit", ["fetchRumusDebitRegisterId"]),
    /*
     * Load async data
     */
    loadAsyncData() {
      this.loading = true;

      this.fetchRumusDebitRegisterId(this.registerId).then(() => {
        let response = this.debit;
        this.data = [];
        let enablePreviousPage = response.hasPreviousPage;
        let enableNextPage = response.hasNextPage;
        if (enablePreviousPage) {
          this.hasDisablePreviousPage = false;
        } else {
          this.hasDisablePreviousPage = true;
        }

        if (enableNextPage) {
          this.hasDisableNextPage = false;
        } else {
          this.hasDisableNextPage = true;
        }

        this.debit.rumusdebits.forEach((item) => {
          this.loading = false;
          this.data.push(item);
        });
        // .catch((error) => {
        //   this.data = [];
        //   this.total = 0;
        //   this.loading = false;
        //   throw error;
        // });
      });
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
    dateThAttrs(column) {
      return column.label === "Date"
        ? {
            title: 'This title is sponsored by "th-attrs" prop',
            class: "has-text-success",
          }
        : null;
    },
    columnTdAttrs(row, column) {
      if (row.id === "Total") {
        if (column.label === "ID") {
          return {
            colspan: 4,
            class: "has-text-weight-bold",
            style: {
              "text-align": "left !important",
            },
          };
        } else if (column.label === "Gender") {
          return {
            class: "has-text-weight-semibold",
          };
        } else {
          return {
            style: { display: "none" },
          };
        }
      }
      return null;
    },
    remove(index) {
      console.log("index" + index);
    },
    submitted() {
      this.loadAsyncData();
    },
  },

  mounted() {
    this.loadAsyncData();
  },
};
</script>