import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js' 
import moment from "moment";

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {            
        async fetchPosDinasPda({state,commit})  {
            commit('setItems', {resource: 'hidrologi', items: []}, {root: true}) 
            return await axiosInstance.get('/api/kedinasan/search/pda')
            .then(res => {
                let hidrologi = res.data.kanalList
                const kanalList = [];
                hidrologi.forEach((item) => {  
                    item.tanggal =  moment(String(item.tanggal)).format('YYYY-MM-DD HH:mm:ss')
                    kanalList.push(item);
                });
                commit('setItems', {resource: 'dinasHidrologi', items: kanalList}, {root: true})
                return state.items
                })
        },
        async fetchPosDinasPch({state,commit}) {
            commit('setItems', {resource: 'hidrologi', items: []}, {root: true})
            return await axiosInstance.get('/api/kedinasan/search/pch')
            .then(res => {
                const hidrologi = res.data.kanalList
                commit('setItems', {resource: 'dinasHidrologi', items: hidrologi}, {root: true})
                return state.items
                })
        },
        

    },
    mutations: {

    }
}