<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
             <div
              class="huro-hamburger nav-trigger push-resize"
              data-sidebar="layouts-sidebar"
            >
              <span class="menu-toggle has-chevron">
                <span class="icon-box-toggle">
                  <span class="rotate">
                    <i class="icon-line-top"></i>
                    <i class="icon-line-center"></i>
                    <i class="icon-line-bottom"></i>
                  </span>
                </span>
              </span>
            </div>

            <div class="title-wrap">
              <h1 class="title is-4">Data Sungai</h1>
            </div>
          </div>

          <div class="list-view-toolbar">
                <div class="control has-icon">
                    <input class="input custom-text-filter" v-model="cariSungai" placeholder="Search..." @keydown="cari">
                    <div class="form-icon">
                        <i class="fa fa-search"></i>
                    </div>
                     
                </div>

                <div class="tabs-inner">
                   <router-link  to="/dashboard/inputsungai" class="button h-button is-success is-outlined is-raised">
                   <i class="fal fa-plus"></i>
                   <span>Tambah Sungai</span> </router-link>
                </div>
            </div>

          <div class="page-content-inner">
            <div class="lifestyle-dashboard lifestyle-dashboard-v4">
              <!--Header-->
              <div class="column is-12">
                <div class="illustration-header-2">
                  <div class="header-image">
                    <img
                      src="../../assets/img/hand.png"
                      alt=""
                      width="250"
                      height="250"
                    />
                  </div>
                  <div class="header-meta">
                    <h3>Data Sungai.</h3>
                    <p>
                      Data Sungai yang dikelola oleh
                      BBWS Cimanuk - Cisanggarung.
                    </p>
                  </div>
                </div>
              </div>
            </div>
                <div class="columns is-multiline">
                           
                        <div class="column is-12" v-if="isLoaded">
                             
                            <!-- Datatable -->
                            <div class="table-wrapper">
                                 
                                 <b-table
                                    class="table is-datatable is-hoverable table-is-bordered"
                                    :data="isEmpty ? [] : data"
                                    :bordered="isBordered"
                                    :striped="isStriped"
                                    :narrowed="isNarrowed"
                                    :hoverable="isHoverable"
                                    :loading="isLoading"
                                    :focusable="isFocusable"
                                    :mobile-cards="hasMobileCards"
                                    
                                    >
                                <b-table-column
                                    field="namaDas"
                                    label="DAS"
                                    :td-attrs="columnTdAttrs"
                                    v-slot="props"
                                >
                                    {{ props.row.das ? props.row.das.namaDas : '-' }}
                                </b-table-column>
  
                                <b-table-column
                                    field="namaSungai"
                                    label="Nama Sungai"
                                    :td-attrs="columnTdAttrs"
                                    v-slot="props"
                                >
                                    {{ props.row.namaSungai }}
                                </b-table-column>

                                <b-table-column
                                    field="panjangSungai"
                                    label="Panjang Sungai"
                                    :td-attrs="columnTdAttrs"
                                    v-slot="props"
                                >
                                    {{ props.row.panjangSungai }}
                                </b-table-column>
                                <b-table-column
                                    field="luasSungai"
                                    label="Luas Sungai"
                                    :td-attrs="columnTdAttrs"
                                    v-slot="props"
                                >
                                    {{ props.row.luasSungai }}
                                </b-table-column>

                                <b-table-column
                                    class="td"
                                    field="kelasSungai"
                                    label="Kelas Sungai"
                                    :td-attrs="columnTdAttrs"
                                    v-slot="props"
                                >
                                    {{ props.row.kelasSungai }}
                                </b-table-column>

                                <b-table-column 
                                field="id" 
                                label="Action"
                                v-slot="props">

                                    <router-link 
                                    class="button h-button is-success is-outlined is-raised"
                                    :to="`/dashboard/sungai/${props.row.id}`">
                                        Pratinjau
                                    </router-link>
                                                    
                                </b-table-column>

                                <template #empty>
                                    <div class="has-text-centered">No records</div>
                                </template>
                                
                                </b-table>
                            </div>
                            <div class="buttons is-right">
                            <b-button
                                @click="decrement"
                                type="is-info"
                                icon-pack="fas"
                                icon-left="chevron-left"
                                :disabled="hasDisablePreviousPage"
                            ></b-button>
                            <b-button
                                @click="increment"
                                type="is-info"
                                icon-pack="fas"
                                icon-left="chevron-right"
                                :disabled="hasDisableNextPage"
                            ></b-button>
                            </div>
                        </div>
                        <div class="column is-12" v-else>
                            <AppSpinner />
                        </div>

                     </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
import AppSpinner from "../shared/AppSpinner.vue"
export default {
  name: "SungaiList",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    AppSpinner
  },
  data() {
    return {
      isLoaded: false,
      inputCari: "",
      cariSungai: "",
      selected: null,
      data: [],
      total: 0,
      sortField: "created",
      sortOrder: "ASC",
      defaultSortOrder: "ASC",
      page: 0,
      perPage: 10,
      columns: [
        // {
        //   field: "namaDas",
        //   label: "Nama DAS",
        // },
        {
          field: "namaSungai",
          label: "Nama Sungai",
        },
        {
          field: "kelasSungai",
          label: "Kelas (Ordo)",
        },
        {
          field: "panjangSungai",
          label: "Panjang Sungai",
        },
        {
          field: "luasSungai",
          label: "Luas Sungai",
        },
      ],
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
    };
  },
  created() {
    this.fetchSungai().then(() => {
      this.isLoaded = true;
    });
  },
   beforeMount(){
    const authority = localStorage.getItem('authority')
    if(authority === 'DINAS'){
      this.$router.push('/dashboard/dinas')
    }
  },
  computed: {
    isAuthResolved() {
      return this.$store.state.auth.isAuthResolved;
    },
    ...mapState({
      results: (state) => state.sungai.items,
    }),
  },
  methods: {
    ...mapActions("sungai", ["searchSungai", "fetchSungai"]),
    /*
     * Load async data
     */
    loadAsyncData() {
      const params = [
        `namaSungai=${this.cariSungai}`,
        `page=${this.page}`,
        `sortType=${this.sortOrder}`,
        `limit=${this.perPage}`,
      ].join("&");

      this.isLoaded = true;

      this.searchSungai(params).then((response) => {
         
        this.data = [];
        let enablePreviousPage = response.hasPreviousPage;
        let enableNextPage = response.hasNextPage;
        if (enablePreviousPage) {
          this.hasDisablePreviousPage = false;
        } else {
          this.hasDisablePreviousPage = true;
        }

        if (enableNextPage) {
          this.hasDisableNextPage = false;
        } else {
          this.hasDisableNextPage = true;
        }

        response.sungai.forEach((item) => {
          this.loading = false;
          this.data.push(item);
        });
        // .catch((error) => {
        //   this.data = [];
        //   this.total = 0;
        //   this.loading = false;
        //   throw error;
        // });
      });
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
    cari() {
      this.loadAsyncData();
    },
    dateThAttrs(column) {
      return column.label === "Date"
        ? {
            title: 'This title is sponsored by "th-attrs" prop',
            class: "has-text-success",
          }
        : null;
    },
    columnTdAttrs(row, column) {
      if (row.id === "Total") {
        if (column.label === "ID") {
          return {
            colspan: 4,
            class: "has-text-weight-bold",
            style: {
              "text-align": "left !important",
            },
          };
        } else if (column.label === "Gender") {
          return {
            class: "has-text-weight-semibold",
          };
        } else {
          return {
            style: { display: "none" },
          };
        }
      }
      return null;
    },
    remove(index) {
      console.log("index" + index);
    },
  },

  mounted() {
    this.loadAsyncData();
  },
};
</script>

<style scoped>
.fal {
  margin-right: 10px;
  font-weight: 400;
}
</style>