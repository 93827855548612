<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->

            <div class="title-wrap">
              <h1 class="title is-4">Input Cluster Sungai</h1>
            </div>
          </div>

          <div class="page-content-inner">
            <!--Form Layout 1-->
            <div class="form-layout">
              <div class="form-outer">
                <div class="form-header stuck-header">
                  <div class="form-header-inner">
                    <div class="left">
                      <h3>Form Input</h3>
                    </div>
                    <div class="right">
                      <div class="buttons">
                        <button
                          @click="$router.go(-1)"
                          class="button h-button is-light is-dark-outlined"
                        >
                          <span class="icon">
                            <i class="lnir lnir-arrow-left rem-100"></i>
                          </span>
                          <span>Batal</span>
                        </button>
                        <button
                          class="button h-button is-primary is-raised"
                          @click.prevent="send"
                          :disabled="isFormInvalid"
                        >
                          Simpan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-body">
                  <!--Fieldset-->
                  <div class="form-fieldset">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="field">
                           <div class="control">
                          <div class="file has-name">
                            <label class="file-label">
                              <input
                                class="file-input"
                                id="file"
                                type="file"
                                name="file"
                                @change="uploadFile"
                              />
                              <span class="file-cta">
                                <span class="file-icon">
                                  <i class="fas fa-upload"></i>
                                </span>
                                <span class="file-label"> Pilih file Cluster</span>
                              </span>
                              <span v-if="file" class="file-name">
                                {{ file.name }}
                              </span>
                            </label>
                          </div>
                        </div>
                        </div>
                      </div>

                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Pos Hulu</label>
                            <div class="select is-normal">
                            <select
                              v-model="form.posHuluId"
                              @blur="$v.form.posHuluId.$touch()"
                              aria-placeholder="Pilih Jenis Pos"
                            >
                              <option
                                v-for="(kadaster, idx) in kadasters"
                                :key="idx"
                                :value="kadaster.registerId"
                              >
                                {{ kadaster.namaPos }}
                              </option>
                            </select>
                            </div>
                            <div v-if="$v.form.posHuluId.$error">
                              <span
                                v-if="!$v.form.posHuluId.required"
                                class="help is-danger"
                                >Pos Hulu Harus DiPilih</span
                              >
                            </div>
                          </div>
                        </div>
                      </div> 
                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Pos Hilir</label>
                                <div class="select is-normal">
                            <select
                              v-model="form.posHilirId"
                              @blur="$v.form.posHilirId.$touch()"
                              aria-placeholder="Pilih Jenis Pos"
                            >
                              <option
                                v-for="(kadaster, idx) in kadasters"
                                :key="idx"
                                :value="kadaster.registerId"
                              >
                                {{ kadaster.namaPos }}
                              </option>
                            </select>
                            </div>
                            
                          </div>
                        </div>
                      </div> 
                    </div>
                  </div>
                  <!-- end form -->
                </div>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
export default {
  name: "FormInputForecasting",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
  },
  data() {
    return {
      form: {
        posHuluId: null, 
        posHilirId: null, 
        uratSungai: null, 
      },
      file: null
    };
  },
  validations: {
    form: {
      posHuluId: { required }, 
      
    },
  },
  created(){
    this.getKadasters();
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
     ...mapState({
      kadasters: (state) => state.kadasters.items,
    }),
  },
  methods: {
    ...mapActions("kadasters", ["getKadasters"]),
   uploadFile(event) {
      // console.log(event);
      this.file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
      this.$store.dispatch("clusterFile/fileUpload", formData)
      .then(response => {
          this.form.uratSungai = response.fileName
      })
      .catch(error=> {
        
         this.$toasted.error(error.response.data.message, {duration: 5000,})
      })
    },
     send() {
      this.$store
        .dispatch("clusterSungai/addCluster", this.form)
        .then(() => {
                this.$router.push('/dashboard/cluster')
                this.$toasted.success("Tambah Data Cluster Berhasil", {
                duration: 5000,
              });
        })
         .catch(error=> {
        
         this.$toasted.error(error.response.data.message, {duration: 5000,})
      })
      
    },
  },
};
</script>

 <style scoped>
</style>