import Vue from 'vue'
import Vuex from 'vuex'

//Modules
import auth from './modules/auth'
import landingContent from './modules/landingContent'
import landingKadaster from './modules/landingKadaster'
import landingChannel from './modules/landingChannel'
import landingImage from './modules/landingImage'
import landingLevel from './modules/landingLevel'
import pdaGeneratePdf from './modules/pdaGeneratePdf'
import pchGeneratePdf from './modules/pchGeneratePdf'
import landingKualitas from './modules/landingKualitas'
import landingPchChannel from './modules/landingPchChannel' 
import hidrologiPdaLanding from './modules/hidrologiPdaLanding'
import hidrologiPchLanding from './modules/hidrologiPchLanding'
import landingKepuasan from './modules/landingKepuasan'


import device from './modules/device'
import kadasters from './modules/kadasters'
import kualitas from './modules/kualitas'
import content from './modules/content'
import contentFile from './modules/contentFile';
import image from './modules/image'
import sungai from './modules/sungai'
import das from './modules/das'
import level from './modules/level'
import channel from './modules/channel'
import kanalPch from './modules/kanalPch'
import hidrologi from './modules/hidrologi'
import hidrologiCh from './modules/hidrologiCh'
import debit from './modules/debit'
import users from './modules/users'
import profile from './modules/profile'
import ordo from './modules/ordo'
import generateTelePdaExcel from './modules/generateTelePdaExcel'
import generateTelePchExcel from './modules/generateTelePchExcel'
import generateHidroPdaExcel from './modules/generateHidroPdaExcel'
import generateHidroPchExcel from './modules/generateHidroPchExcel'
import hidrologiTahunan from './modules/hidrologiTahunan'
import uploadHidrologi from './modules/uploadHidrologi'
import monitorPda from './modules/monitorPda'
import landingForecasting from './modules/landingForecasting';
import forecasting from './modules/forecasting'
import forecastingFile from './modules/forecastingFile'

import pengumuman from './modules/pengumuman'
import landingPengumuman from './modules/landingPengumuman'


// Additional

import searchChannel from './modules/searchChannel'
import searchKanalPch from './modules/searchKanalPch' 
import landingHidrologiTahunan from './modules/landingHidrologiTahunan'
import hidroGeneratePdf from './modules/hidroGeneratePdf'
import clusterSungai from './modules/clusterSungai'
import clusterFile from './modules/clusterFile'
import landingClusterFile from './modules/landingClusterFile'
import landingCluster from './modules/landingCluster'
import posDinas from './modules/posDinas'
import landingPosDinas from './modules/landingPosDinas'
import kanalPosDinas from './modules/kanalPosDinas'
import posDinasGeneratePda from './modules/posDinasGeneratePda'
import posDinasGeneratePch from './modules/posDinasGeneratePch'

// POS DINAS

import posKedinasan from './modules/posKedinasan'
import uploadDinas from './modules/uploadDinas'
import dinasHidrologi from './modules/dinasHidrologi'
import dinasTahunan from './modules/dinasTahunan'
import generateDinasPdaExcel from './modules/generateDinasPdaExcel'
import generateDinasPchExcel from './modules/generateDinasPchExcel'
import uploadTelemetry from './modules/uploadTelemetry'

Vue.use(Vuex)


export default new Vuex.Store({
  modules:{
    auth,   
    landingChannel,
    landingContent,
    landingKadaster,
    landingKualitas,
    pdaGeneratePdf,
    pchGeneratePdf,
    landingImage,
    landingLevel,
    landingPchChannel,
    landingKepuasan,
    device,
    kadasters,
    kualitas,
    content,
    contentFile,
    image,
    sungai,
    das,
    level,
    channel,
    kanalPch,
    hidrologi,
    hidrologiCh,
    debit,
    users,
    profile,
    ordo,
    generateTelePdaExcel,
    generateTelePchExcel,
    generateHidroPdaExcel,
    generateHidroPchExcel,
    hidrologiTahunan,
    hidrologiPdaLanding,
    hidrologiPchLanding,
    uploadHidrologi,
    monitorPda,
    landingForecasting,
    forecasting,
    forecastingFile,
    pengumuman,
    landingPengumuman,

    // additioanl
    searchChannel,
    searchKanalPch,
    landingHidrologiTahunan,
    hidroGeneratePdf,
    clusterSungai,
    clusterFile,
    landingClusterFile,
    landingCluster,
    posDinas,
    landingPosDinas,
    kanalPosDinas,
    posDinasGeneratePda,
    posDinasGeneratePch,
    posKedinasan,
    uploadDinas,
    dinasHidrologi,
    dinasTahunan,
    generateDinasPdaExcel,
    generateDinasPchExcel,
    uploadTelemetry
  },
  mutations: {
     setItems (state, {resource, items}) {
        state[resource].items = items
     },
     setItem (state, {resource, item}) {
        state[resource].item = item
     },
     addItemToArray (state, {item, index, resource}) {
         Vue.set(state[resource].items, index, item)
     },
     removeItemFromArray(state, {resource, registerId}){
      const items =  state[resource].items.filter(item => {
         return item.registerId !== registerId;
       });
       state[resource].items = items
       
      }
  }
  
})