import axiosInstance from '../../conf/axiosAdmin';


export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fetchForecasts({state, commit}){
            commit('setItems', {resource: 'forecasting', items: []}, {root: true})
           return axiosInstance.get('/api/forecasting/search')
            .then(res => {
                const forecasts = res.data.forecastingList 
                commit('setItems', {resource: 'forecasting', items: forecasts}, {root: true})
                return state.items
             })
        },
        fetchForecastById({state, commit}, id){
            commit('setItem', {resource: 'forecasting', item: {}}, {root: true})
            return axiosInstance.get(`/api/forecasting/view/${id}`)
            .then(res => {
                const forecast = res.data                 
                commit('setItem', {resource: 'forecasting', item: forecast}, {root: true})
                return state.item
                })
            },    
         addForecasting(context, payload){ 
            const headers = {
                'Content-Type': 'application/json',
              }
 
            return axiosInstance.post('/api/forecasting/add', 
            payload,{
                headers: headers
              })
              .then(res => res.data)
        },       
        deactiveForecastingById({state, commit}, id){                            
                commit('setItem', {resource: 'forecasting', item: {}}, {root: true})
                return axiosInstance.delete(`/api/forecasting/deactive/${id}`)
                .then(res => {
                    const forecast = res.data                 
                    commit('setItem', {resource: 'forecasting', item: forecast}, {root: true})
                    return state.item
                 })
                 
        },
        deleteForecastingById({state, commit}, id){                            
            commit('setItem', {resource: 'forecasting', item: {}}, {root: true})
            return axiosInstance.delete(`/api/forecasting/delete//${id}`)
            .then(res => {
                const forecast = res.data                 
                commit('setItem', {resource: 'forecasting', item: forecast}, {root: true})
                return state.item
             })
             
        },
        
        
    },
    mutations: {

    }
}