import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js' 
import moment from "moment";

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {            
        async fetchPdaHidrologiKodeRegister({state,commit}, registerId)  {
            commit('setItems', {resource: 'hidrologi', items: []}, {root: true}) 
            return await axiosInstance.get(`/api/hidrologi/search/pda/${registerId}`)
            .then(res => {
                let hidrologi = res.data.kanalList
                const kanalList = [];
                hidrologi.forEach((item) => {  
                    item.tanggal =  moment(String(item.tanggal)).format('YYYY-MM-DD HH:mm:ss')
                    kanalList.push(item);
                });
                commit('setItems', {resource: 'hidrologi', items: kanalList}, {root: true})
                return state.items
                })
        },
        async fetchPchHidrologiKodeRegister({state,commit}, registerId) {
            commit('setItems', {resource: 'hidrologi', items: []}, {root: true})
            return await axiosInstance.get(`/api/hidrologi/search/pch/${registerId}`)
            .then(res => {
                const hidrologi = res.data.kanalList
                commit('setItems', {resource: 'hidrologi', items: hidrologi}, {root: true})
                return state.items
                })
        },
        

    },
    mutations: {

    }
}