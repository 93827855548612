<template>
  <div class="modal-level">
  
  <div class="tombol">
      <div class="tombol-button">
        <button class="button h-button is-success is-elevated" @click="isOpen = !isOpen" type="is-success" outlined>
          Pratinjau
        </button>
      </div>
 
    </div>
  
    <div :class="['modal', { 'is-active': isOpen }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Pengkinian Data Level Ketinggian</p>
          <!-- TODO: Close Modal set isOpen to false -->
          <button
            @click="isOpen = false"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <!-- <FormInputDevice :sn="deviceId" /> -->
      <form>
      <div class="field">
        <div class="control">
          <label class="label is-normal">Waspada</label>
          <input
            @blur="$v.form.waspada.$touch()"
            v-model="form.waspada"
            type="number"
            class="input"
            placeholder="Masukan Level Ketinggian"
          />
          <div v-if="$v.form.waspada.$error">
            <span v-if="!$v.form.waspada.required" class="help is-danger"
              >Level Ketinggian Harus Diisi</span
            >
            <span v-if="!$v.form.waspada.between" class="help is-danger"
                >Level Ketinggian Tidak Boleh Minus</span
              >
          </div>
        </div>
      </div>
         
      <div class="field">
        <div class="control">
          <label class="label is-normal">Siaga</label>
          <input
            @blur="$v.form.siaga.$touch()"
            v-model="form.siaga"
            type="number"
            class="input"
            placeholder="Masukan Level Ketinggian"
          />
          <div v-if="$v.form.siaga.$error">
            <span v-if="!$v.form.siaga.required" class="help is-danger"
              >Level Ketinggian Harus Diisi</span
            >
            <span v-if="!$v.form.siaga.between" class="help is-danger"
                >Level Ketinggian Tidak Boleh Minus</span
              >
          </div>
        </div>
      </div>

      

      <div class="field">
        <div class="control">
          <label class="label is-normal">Awas</label>
          <input
            @blur="$v.form.awas.$touch()"
            v-model="form.awas"
            type="number"
            class="input"
            placeholder="Masukan Level Ketinggian"
          />
          <div v-if="$v.form.awas.$error">
            <span v-if="!$v.form.awas.required" class="help is-danger"
              >Level Ketinggian Harus Diisi</span
            >
            <span v-if="!$v.form.awas.between" class="help is-danger"
                >Level Ketinggian Tidak Boleh Minus</span
              >
          </div>
        </div>
      </div>
       <div class="field">
        <div class="control">
          <label class="label is-normal">Tinggi Tanggul</label>
          <input
            @blur="$v.form.tinggiTanggul.$touch()"
            v-model="form.tinggiTanggul"
            type="number"
            class="input"
            placeholder="Masukan Level Ketinggian"
          />
          <div v-if="$v.form.tinggiTanggul.$error">
            <span v-if="!$v.form.tinggiTanggul.required" class="help is-danger"
              >Level Ketinggian Harus Diisi</span
            >
            <span v-if="!$v.form.tinggiTanggul.between" class="help is-danger"
                >Level Ketinggian Tidak Boleh Minus</span
              >
          </div>
        </div>
      </div>
      </form>

        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <div class="control btn1">
              <button
                @click="isOpen = !isOpen"
                @click.prevent="editLevel" 
                class="button is-normal is-link"
              >
                <span> Simpan </span>
              </button>
            </div>

            <div class="control btn">
              <button
                @click="isOpen = false"
                class="button is-normal is-danger"
              >
                <span> Batal </span>
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>

import { required, between } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    
  },
 props: {
   levelId: {
      type: Number,
      required: false,
    },
     
  },
  data() {
    return {
     isOpen: false,
     isEnable: false,
     form: {
        id: null,  
        awas: null,
        siaga: null,
        waspada: null,
        tinggiTanggul: null,
        registerId: null
      },
    };
  },
  validations: {
    form: {
      waspada: { 
        required, 
         bettween: between(0, 1000000)
        },
      siaga: { 
        required,
        bettween: between(0, 1000000)
        },
      awas: { 
        required,
        bettween: between(0, 1000000) 
        },
      tinggiTanggul: { 
        required,
        bettween: between(0, 1000000) 
        },  
    },
  },
  created(){
     this.fetchLevelById(this.levelId).then((res) => {
      this.form = res;
    }); 
  },
  computed: {
     ...mapState({
      level: (state) => state.level.item,
    }),
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
  },
  methods: {
     ...mapActions("level", ["fetchLevelById", "updateLevel", "deleteLevel"]),
     editLevel() {
      this.updateLevel(this.form)
      .then(() => {
            this.$toasted.success('Rubah Data Level Berhasil', {duration: 3000})
            this.$emit("updatedSubmit");
          })
      .catch(error => {
        console.log(error.response)
        this.$toasted.error(error.response.data.message, {duration: 3000})
      })
    },
    // deletLevel() {
    //    this.$swal({
    //         title: "Level Status Sungai Akan Dihapus?",
    //         text: "Setelah dihapus anda tidak dapat mengembalikannya!",
    //         type: "warning",
    //         showCancelButton: true,
    //         confirmButtonColor: "#c0392b",
    //         confirmButtonText: "YA, HAPUS!"
    //        }).then((result) => { // <--
    //               if (result.value) { // <-- if confirmed
    //                 this.$store.dispatch("level/deleteLevel", this.form)
    //                 this.$router.push('/kadaster')
    //                 // this.$emit("deleted");
    //                 this.$toasted.success("Delet level Success", { duration: 5000 }) 
                    
    //               }
    //           });
    // },
     
    },
   }

</script>

<style scoped>
.modal {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}
.modal-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
}
 

 
@media (max-width: 768px) {
  .modal-card {
    height: 600px;
    width: 350px;
  }
   
}

</style>