<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
            <div
              class="huro-hamburger nav-trigger push-resize"
              data-sidebar="layouts-sidebar"
            >
              <span class="menu-toggle has-chevron">
                <span class="icon-box-toggle">
                  <span class="rotate">
                    <i class="icon-line-top"></i>
                    <i class="icon-line-center"></i>
                    <i class="icon-line-bottom"></i>
                  </span>
                </span>
              </span>
            </div>

            <div class="title-wrap">
              <h1 class="title is-4">Data CSV File</h1>
            </div>
          </div>

          <div class="list-flex-toolbar">
            <div class="control has-icon">
              <input class="input custom-text-filter" placeholder="Search..." />
              <div class="form-icon">
                <i class="fa fa-search"></i>
              </div>
            </div>

            <div class="tabs-inner">
             <InputFileBatchCsv  @submitted="submitted" />
            </div>
          </div>

          <div class="page-content-inner">
            <div class="table-wrapper">
              <b-table
                class="table is-datatable is-hoverable table-is-bordered"
                :data="data"
                :columns="columns"
                focusable
                paginated
                backend-pagination
                :total="total"
                :per-page="perPage"
                @page-change="onPageChange"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                backend-sorting
                :default-sort-direction="defaultSortOrder"
                :default-sort="[sortField, sortOrder]"
                @sort="onSort"
                icon-pack="fas"
                icon-left="chevron-right"
              >
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
import InputFileBatchCsv from "../csv/InputFileBatchCsv.vue";
//import AppSpinner from "../shared/AppSpinner.vue";
export default {
  name: "CsvFileList",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    InputFileBatchCsv
    //AppSpinner,
  },
  data() {
    return {
      isLoaded: false,
      inputCari: "",
      data: [],
      total: 0,
      sortField: "created",
      sortOrder: "ASC",
      defaultSortOrder: "ASC",
      page: 0,
      perPage: 10,
      columns: [
        {
          field: "created",
          label: "Tanggal",
        },
        {
          field: "originalFilename",
          label: "Nama File",
        },        
        {
          field: "status",
          label: "status",
        },
      ],
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      jenisPos: null,
    };
  },
  created() {
    this.fetchCsvFile().then(() => {
      this.isLoaded = true;
    });
  },
  beforeMount() {
    const authority = localStorage.getItem("authority");
    if (authority === "DINAS") {
      this.$router.push("/dashboard/dinas");
    }
  },
  computed: {
    ...mapState({
      uploadTelemetry: (state) => state.uploadTelemetry.items,
    }),
  },
  methods: {
     ...mapActions("uploadTelemetry", ["fetchCsvFile", "searchCsvFile"]),
   
    loadAsyncData() {
      const params = [
        `uploadName=${this.inputCari}`,
        `page=${this.page}`,
        `sortType=${this.sortOrder}`,
        `limit=${this.perPage}`,
      ].join("&");

      this.loading = true;
      this.searchCsvFile(params).then((resp) => {

        this.data = [];
         let currentTotal = resp.length;
        if (currentTotal / this.perPage > 1000) {
          currentTotal = this.perPage * 1000;
        }
        this.total = currentTotal
        resp.forEach((item) => {
          this.isLoaded = true; 
          item.created =  moment(String(item.created)).format('YYYY-MM-DD HH:mm:ss')
          this.data.push(item);
        });
         
      });  
      
    },
     /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
      
    
    remove(index) {
      console.log("index" + index);
    },
    submitted(){
     this.loadAsyncData();
    },
    
  },

  mounted() {
    this.loadAsyncData();
  },
};
</script>

<style scoped>
.headers {
  font-size: 20px;
}

.item-name {
  text-align: justify;
}

.fal {
  margin-right: 10px;
  font-weight: 400;
}

@media (max-width: 768px) {
}
</style>