<template>
  <div id="app"> 
    <div id="huro-app" class="app-wrapper">
      <div class="app-overlay"></div>

      <!--Pageloader-->
      <div class="pageloader"></div>         
    <router-view :key="$route.path"/>
    </div>
  </div>
</template>
 
<script>
export default {
  name: 'App',
  components: {
   
  },
  //  computed: {
  //   isAuthResolved () {
  //     return this.$store.state.auth.isAuthResolved
  //   },     
  // },
   
   
}
</script>