<template>
  <div class="modal-level">
    <div class="tombol">
      <div class="tombol-button">
        <button
          class="button h-button is-info is-raised"
          @click="isOpen = !isOpen"
          type="is-success"
        >
          Ganti Password
        </button>
      </div>
    </div>

    <div :class="['modal', { 'is-active': isOpen }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Ganti Kata Sandi User</p>
          <!-- TODO: Close Modal set isOpen to false -->
          <button
            @click="isOpen = false"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <!-- <FormInputDevice :sn="deviceId" /> -->
          <form>
            <div class="field">
              <div class="control">
                <label class="label is-normal">Password</label>

                <div class="field has-addons">
                  <div class="control has-icon is-expanded">
                    <input
                      v-model="form.newPassword"
                      @blur="$v.form.newPassword.$touch()"
                      class="input is-rounded"
                      :type="show === true ? 'text' : 'password'"
                      placeholder="Password"
                    />
                    <span class="form-icon">
                      <i class="fas fa-lock-open"></i>
                    </span>
                  </div>
                  <div class="control">
                    <a class="button is-rounded">
                      <img
                        class="img"
                        v-if="show"
                        @click="show = !show"
                        src="../../assets/image/eye.png"
                      />
                      <img
                        class="img1"
                        v-else
                        @click="show = !show"
                        src="../../assets/image/eye-slash.png"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <div class="control btn1">
              <button
                @click="isOpen = !isOpen"
                @click.prevent="changePsswd"
                class="button is-normal is-link"
              >
                <span> Simpan </span>
              </button>
            </div>

            <div class="control btn">
              <button
                @click="isOpen = false"
                class="button is-normal is-danger"
              >
                <span> Batal </span>
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";

export default {
  components: {},
  props: {
    cred: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      show: false,
      isOpen: false,
      isEnable: false,
      form: {
        credential: this.cred,
        oldPassword: "-",
        newPassword: null
      },
    };
  },
  validations: {
    form: {
      newPassword: {
        required,
      },
    },
  },
  created() {
    
  },
  computed: {
    ...mapState({
     users: (state) => state.users.item,
    }),
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
  },
  methods: {
    ...mapActions("users", ["fetchUserByCredential", "changePassword"]),
    changePsswd() {
      const credential = this.cred;
      this.form.credential = credential;
      this.changePassword(this.form)
        .then(() => {
          this.$toasted.success("Ganti Kata Sandi Berhasil", {
            duration: 3000,
          });
          this.$emit("updatedSubmit");
        })
        .catch((error) => {
          console.log(error.response);
          this.$toasted.error(error.response.data.message, { duration: 3000 });
        });
    },
    
  },
};
</script>

<style scoped>
.modal {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}
.modal-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
}

@media (max-width: 768px) {
  .modal-card {
    height: 600px;
    width: 350px;
  }
}
</style>