import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js' 

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
       async fetchContentInfo({ state, commit }) {
            commit('setItems', { resource: 'content', items: [] }, { root: true })
            return await axiosInstance.get('/api/content/info/manage/search')
                .then(res => {
                    const content = res.data.contentInfoList
                    commit('setItems', { resource: 'content', items: content }, { root: true })
                    return state.items
                })
        },
       async searchContent({ state, commit }, params) {
            commit('setItems', { resource: 'content', items: [] }, { root: true })
            return await axiosInstance.get(`/api/content/info/manage/search?${params}`)
                .then(res => {
                    const results = res.data
                    commit('setItems', { resource: 'content', items: results }, { root: true })
                    return state.items
                })
        },
        getContentFile({ state, commit}, payload) {            
            commit('setItem', { resource: 'content', item: {} }, { root: true })
            return axiosInstance.get(`/api/content/file/download/${payload}`)
                .then(res => {
                    const content = res.data
                    commit('setItem', { resource: 'content', item: content }, { root: true })
                    return state.item
                })
            
        },
        downloadContentFile({ state, commit}, payload) {
            
            commit('setItem', { resource: 'content', item: {} }, { root: true })
            return axiosInstance.get(`/api/content/file/download/${payload}`, {
                method: 'get',
                responseType: 'arraybuffer',
              })
                .then(res => {
                     const files = res.data;
                     const url = window.URL.createObjectURL(new Blob([res.data]))
                     const link = document.createElement('a')
                     link.href = url
                     link.setAttribute('download', payload)
                     document.body.appendChild(link)
                     link.click()
                    commit('setItem', { resource: 'content', item: files }, { root: true })
                    return state.item
                })
        },
        createContent(context, payload) {
            const headers = {
                'Content-Type': 'application/json',
            }
            return axiosInstance.post('/api/content/info/manage/create',
                payload, {
                headers: headers
            })
                .then(res => res.data)
        },       
        addContent({ commit, state }, content) {
            const contentList = state.items
            commit('setItems', { resource: 'content', items: [...contentList, content] }, { root: true })
        },
        updateContent(context, payload){
            const headers = {
              'Content-Type': 'application/json',
            }          
              return axiosInstance.put(`/api/content/info/manage/update/${payload.id}`, payload,
              {
                headers: headers
              })  
        },
        deletContentById({ state, commit }, id) {
            commit('setItems', { resource: 'content', items: [] }, { root: true })
            return axiosInstance.delete(`/api/content/info/manage/remove/${id}`)
                .then(res => {
                    const content = res.data
                    commit('setItems', { resource: 'content', items: content }, { root: true })
                    return state.items
                })
            },
        fetchContentById({state, commit}, contentId){
            commit('setItem', {resource: 'content', item: {}}, {root: true})
            return axiosInstance.get(`/api/content/info/manage/view/${contentId}`)
            .then(res => {
                const content = res.data                 
                commit('setItem', {resource: 'content', item: content}, {root: true})
                return state.item
                })
            },    
    },
    mutations: {

    }
}