import axiosInstance from '../../conf/axiosAdmin';
export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
       async fetchCsvFile({ state, commit }) { 
            commit('setItems', {resource: 'uploadTelemetry', items: []}, {root: true}) 
            return await axiosInstance.get('/api/telemetry/search')
                .then(res => {
                    const uploadTelemetry = res.data.data                    
                    commit('setItems', { resource: 'uploadTelemetry', items: uploadTelemetry }, { root: true })
                    return state.items
                })
        },
       async searchCsvFile({state,commit}, params) {
            commit('setItems', {resource: 'uploadTelemetry', items: [] }, {root: true })
            return await axiosInstance.get(`/api/telemetry/search?${params}`)
                .then(res => {
                    const results = res.data.data
                    commit('setItems', {
                        resource: 'uploadTelemetry',
                        items: results
                    }, {
                        root: true
                    })
                    return state.items
                })
        },

        async csvUpload(context, {formData}) { 
            const headers = {
                'Content-Type': 'multipart/form-data',
            }
            return await axiosInstance.post('/api/telemetry/upload',
                formData, {
                headers: headers
            })
                .then(res => res.data)
        },
        


    },
    mutations: {

    }
}