<template>
  <div class="modal-kepuasan">
    <div :class="['modal', { 'is-active': buka }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <!-- <header class="modal-card-head">
           
                   
         <button
            @click="buka = false"
            class="delete"
            aria-label="close"
          ></button>
        </header> -->
        <section class="modal-card-body">
          <form>
            <div class="logo">
              <img src="@/assets/image/logoPu.png" style="max-height: 55px" />
            </div>

            <div class="field">
              <div class="control">
                <span class="modal-card-title"
                  >Bantu kami meningkatkan layanan</span
                >
                <p class="subtitle">
                  Seberapa puaskah Anda dengan layanan yang kami berikan?
                </p>
              </div>
            </div>

            <div class="field is-grouped is-grouped-centered">
              <div class="control">
                <label class="radio">
                  <img
                    class="gambar"
                    src="@/assets/image/sad.png"
                    style="max-height: 40px"
                  />
                  <input
                    v-model="form.score"
                    @blur="$v.form.score.$touch()"
                    type="radio"
                    name="kategori"
                    value="kecewa"
                  />
                  <p class="kategori">Kecewa</p>
                </label>
                <label class="radio">
                  <img
                    src="@/assets/image/expressionless.png"
                    style="max-height: 40px"
                  />
                  <input
                    v-model="form.score"
                    @blur="$v.form.score.$touch()"
                    name="kategori"
                    type="radio"
                    value="biasa"
                  />
                  <p class="kategori">Biasa</p>
                </label>
                <label class="radio">
                  <img
                    class=""
                    src="@/assets/image/smiling-face.png"
                    style="max-height: 40px"
                  />
                  <input
                    v-model="form.score"
                    @blur="$v.form.score.$touch()"
                    type="radio"
                    name="kategori"
                    value="puas"
                  />
                  <p class="kategori">Puas</p>
                </label>
              </div>
              <div v-if="$v.form.score.$error" class="form-error">
                <span v-if="!$v.form.score.required" class="help is-danger"
                  >Reting Harus Diisi</span
                >
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="label">Nama</label>
                <div class="control has-icons-left has-icons-right">
                  <input
                    v-model="form.nama"
                    @blur="$v.form.nama.$touch()"
                    class="input"
                    type="text"
                    placeholder="Nama"
                  />
                  <span class="icon is-small is-left">
                    <i class="fal fa-user"></i>
                  </span>
                  <div v-if="$v.form.nama.$error" class="form-error">
                    <span v-if="!$v.form.nama.required" class="help is-danger"
                      >Nama Harus Diisi</span
                    >
                    <span v-if="!$v.form.nama.minLength" class="help is-danger"
                      >Nama minimun 3 karakter</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="label">E-mail</label>
                <div class="control has-icons-left has-icons-right">
                  <input
                    v-model="form.email"
                    @blur="$v.form.email.$touch()"
                    class="input"
                    type="email"
                    placeholder="Email"
                  />
                  <span class="icon is-small is-left">
                    <i class="far fa-envelope"></i>
                  </span>
                  <div v-if="$v.form.email.$error" class="form-error">
                    <span v-if="!$v.form.email.required" class="help is-danger"
                      >Surel Harus Diisi</span
                    >
                    <span v-if="!$v.form.email.email" class="help is-danger"
                      >SurelTidak Sesuai</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="label">Pekerjaan</label>
                <div class="control has-icons-left has-icons-right">
                  <input
                    v-model="form.pekerjaan"
                    @blur="$v.form.pekerjaan.$touch()"
                    class="input"
                    type="text"
                    placeholder="Pekerjaan"
                  />
                  <span class="icon is-small is-left">
                    <i class="fal fa-user-hard-hat"></i>
                  </span>
                  <div v-if="$v.form.pekerjaan.$error" class="form-error">
                    <span
                      v-if="!$v.form.pekerjaan.required"
                      class="help is-danger"
                      >Pekerjaan Harus Diisi</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="label">Instansi atau Perusahaan</label>
                <div class="control has-icons-left has-icons-right">
                  <input
                    v-model="form.instansi"
                    @blur="$v.form.instansi.$touch()"
                    class="input"
                    type="text"
                    placeholder="Instansi atau Perusahaan"
                  />
                  <span class="icon is-small is-left">
                    <i class="fal fa-briefcase"></i>
                  </span>
                  <div v-if="$v.form.instansi.$error" class="form-error">
                    <span
                      v-if="!$v.form.instansi.required"
                      class="help is-danger"
                      >Instansi atau Perusahaan Harus Diisi</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="control">
                <label class="label">captcha Code</label>
              </div>
            </div>
            <div class="field is-grouped">
              
               <captcha-code
                    :captcha.sync="code"
                    @on-change="handleChange"
                    ref="captcha"
                  ></captcha-code>
                  <input
                    v-model="capcay"
                    @blur="$v.capcay.$touch()"
                    class="input"
                    type="text"
                    placeholder="captcha Code"
                  />
                  <!-- <vue-hcaptcha sitekey="ec450111-4d5e-4736-801c-e9023cec354a" @verify="onVerify" v-model="form.robot"></vue-hcaptcha> -->
                  
                 
               
               
            </div>

            <div class="field is-group">
              <div class="control btn1">
                <button
                  :disabled="isFormInvalid"
                  @click="buka = false"
                  @click.prevent="sendKepuasan"
                  class="button is-normal is-link is-focused"
                >
                  <span> Kirim </span>
                </button>
              </div>

              <div class="control btn">
                <button
                  @click="buka = false"
                  class="button is-normal is-danger is-focused"
                >
                  <span> Batal </span>
                </button>
              </div>
            </div>
          </form>
        </section>
        <!-- <footer class="modal-card-foot">
          
        </footer> -->
      </div>
    </div>
  </div>
</template>



<script>
import { required, email, minLength } from "vuelidate/lib/validators";
// import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import CaptchaCode from "vue-captcha-code";
export default {
  components: {
    // VueHcaptcha,
    CaptchaCode,
  },
  props: {
    buka: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {      
      isOpen: false,
      code: "",
      capcay: null,
      isSesuai: true,
      form: {
        score: null,
        nama: null,
        email: null,
        pekerjaan: null,
        instansi: null,         
      },
    };
  },
  validations: {
    form: {
      score: {
        required,
      },
      nama: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      pekerjaan: {
        required,
      },
      instansi: {
        required,
      },
     
    },
    capcay: {
      required
    }
    
  },
  computed: {
    isFormInvalid() {
      // return this.$v.form.$invalid;
      return this.$v.$invalid;
    },
    fileName: (state) => state.landingGeneratePdf.item,
    
  },
  methods: {
    sendKepuasan() {
      if(this.capcay == this.code){
        this.$store
        .dispatch("landingKepuasan/createLandingKepuasan", this.form)
        .then(() => this.$emit("onLoad"));
      this.form = {};
      this.handleClick();
      }else {
       this.$toasted.error('Captcha Code Tidak sama', {duration: 3000})
       this.form = {};
       this.capcay = null;
        this.handleClick(); 
      }       
     
    },
   
    handleClick() {
      this.$refs.captcha.refreshCaptcha();
    },
    handleChange(code) {
      console.log("code:", code);
    },
  },
};
</script>


<style scoped>
.modal-card-head,
.modal-card-body,
.modal-card-foot {
  animation: swing 1.2s ease-in-out;
}

@keyframes swing {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0px);
    transition: all 0.5s;
  }
}

.logo {
  display: block;
  text-align: center;
  padding-bottom: 10px;
}

.modal-card-title {
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
  text-align: center;
}

.subtitle {
  font-size: 16px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 30px;
}

.gambar {
  margin-left: 5px;
}

.button {
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  width: 135px;
  letter-spacing: 1px;
  margin-left: 10px;
}

input[type="radio"] {
  visibility: hidden;
}

input[type="radio"]:checked ~ .kategori {
  color: #2d6efd;
}

.radio {
  margin-right: 50px;
}

.is-grouped {
  margin-left: 50px;
}

.is-group {
  display: flex;
  margin-left: 23%;
  padding-top: 30px;
  padding-bottom: 50px;
}

@media (max-width: 768px) {
  .modal-card {
    height: 600px;
    width: 350px;
  }

  .button {
    font-size: 14px;
    font-weight: 400;
    height: 35px;
    width: 135px;
    margin-bottom: 5px;
  }

  .is-grouped {
    margin-left: 70px;
  }

  .is-group {
    display: flex;
    margin-left: 10px;
    padding-top: 30px;
    padding-bottom: 50px;
  }
}
</style>