<template>
  <div>
    <MobileNavbar />
    <MobileSidebarMain />
    <Sidebar />

  <div id="grid-tiles" class="view-wrapper" data-naver-offset="214" data-menu-item="#layouts-sidebar-menu"
    data-mobile-item="#home-sidebar-menu-mobile">
  

    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
            <div
              class="huro-hamburger nav-trigger push-resize"
              data-sidebar="layouts-sidebar"
            >
              <span class="menu-toggle has-chevron">
                <span class="icon-box-toggle">
                  <span class="rotate">
                    <i class="icon-line-top"></i>
                    <i class="icon-line-center"></i>
                    <i class="icon-line-bottom"></i>
                  </span>
                </span>
              </span>
            </div>

            <div class="title-wrap">
              <h1 class="title is-4">Informasi Analisa</h1>
            </div>
          </div>

          <!-- <div class="tile-grid-toolbar">
                <div class="control has-icon">
                    <input class="input custom-text-filter" placeholder="Pencarian..." data-filter-target=".column">
                    <div class="form-icon">
                        <i class="fa-regular fa-magnifying-glass"></i>
                    </div>
                </div>                
            </div> -->

           <div class="page-content-inner" v-if="isAvai">
              <div class="section-placeholder">
                        <div class="placeholder-content">
                            <img class="light-image" src="../../assets/no-data.png" alt="" />
                            <!-- <img class="dark-image" src="assets/img/illustrations/placeholders/search-4-dark.svg" alt="" /> -->
                            <h3 class="dark-inverted">Tidak ada data untuk ditampilkan</h3>
                            <p>Saat ini tidak ada data untuk ditampilkan dalam daftar ini..</p>
                        </div>
            </div>
          </div>
          <div class="page-content-inner" v-else>
            <div class="tile-grid tile-grid-v2">
            
               <div class="columns is-multiline">
                    <!--Grid item-->
                        <div class="column is-4"  v-for="(item, index) in data" :key="index">
                            <div class="tile-grid-item">
                                <div class="tile-grid-item-inner">
                                    <img src="../../assets/pdf.svg" alt="">
                                    <div class="meta">
                                        <span class="dark-inverted">{{item.title}}</span>
                                        <span>
                                            <span>{{item.fileContent.size}}</span>
                                            <i aria-hidden="true" class="fas fa-circle icon-separator"></i>
                                            <span>{{item.fileContent.created}}</span>
                                        </span>
                                    </div>
                                    <div class="dropdown is-spaced is-dots is-right dropdown-trigger">
                                        <b-dropdown aria-role="list">
                                        <template #trigger="">
                                            <a class="navbar-item" role="button">
                                            <i class="fa-regular fa-ellipsis-vertical"></i>                                              
                                            </a>                                             
                                        </template>

                                        <b-dropdown-item aria-role="listitem">
                                             <router-link 
                                                target="_blank"
                                                :to="`/konteninfo/pratinjau/${item.fileContent.fileName}`"                     
                                                
                                                >
                                                 <i class="fa-solid fa-cloud-arrow-up"></i>
                                               <span class="titles">Pratinjau</span>
                                                </router-link>
                                           
                                            
                                        </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                   <!-- END MULTILINE  -->
                    </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  </div>  
</template>


<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import Sidebar from "../navigation/Sidebar.vue";
import MobileNavbar from "../navigation/MobileNavbar.vue";
import MobileSidebarMain from "../navigation/MobileSidebarMain.vue"; 

export default {
  name: "InfoUmum",
  components: {
    Sidebar,
    MobileNavbar,
    MobileSidebarMain, 
  },

  data() {
    return {
        data: [],
        isAvai: true
    };
  },
  computed: {
    ...mapState({
       landingContent: (state) => state.landingContent.items,
    }),
  },
   methods: {
    ...mapActions("landingContent", [
      "fetchContentInfoUmum",
      "getLandingContentFile",
    ]),
    downloadFile(fileName) {
      this.getLandingContentFile(fileName);
    },
    /*
     * Load async data
     */
    loadAsyncData() {

      this.isAvai = true;
      this.fetchContentInfoAnalisa().then((resp) => {        
         resp 
          .forEach((item) => {
            this.isAvai = false;
            item.fileContent.created = moment(String(item.fileContent.created)).format('YYYY-MM-DD HH:mm:ss')
            this.data.push(item);
          });  
      });
    },
    
    
  },
  mounted(){
    this.loadAsyncData();
  }
};
</script>

<style scoped>
.cards {
  padding-top: 50px;
}

.fa-regular
{
  font-weight: 700;
  font-size: 16px;
}

.fa-solid {
  margin: 5px 15px 5px 5px;
  font-weight: 700;
  font-size: 22px;
}

.content-title {
  font-size: 14px;
  font-weight: 500;
}
.titles {
  font-size: 14px;
  font-weight: 200;
}

/* .media
{
     margin: auto;
} */

.is-2 {
  margin: auto;
}
.is-1 {
  margin: auto;
}

 svg:hover {
    stroke: #969ab0;
}
</style>