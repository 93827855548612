<template>
  <div class="modal-Hidrologi">
    <button
      class="button is-normal is-link is-focused"
      @click="isOpen = !isOpen"
      type="is-success"
      outlined
    >
      <i class="fal fa-plus"></i><span>Upload File</span>
    </button>

    <div :class="['modal', { 'is-active': isOpen }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Masukan Data Hidrologi</p>
          <!-- TODO: Close Modal set isOpen to false -->
          <button
            @click="isOpen = false"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <form>

            <div class="field">
              <label class="label">Jenis Pos</label>
              <div class="control">
                <div class="select">
                  <select
                    @blur="$v.form.category.$touch()"
                    v-model="form.category"
                    type="text"
                    class="input"
                    placeholder="Masukan Kategori"
                  >
                    <option disabled>Pilih Pos</option>
                    <option>POS_DUGA_AIR</option>
                    <option>POS_CURAH_HUJAN</option>
                  </select>
                  <div v-if="$v.form.category.$error">
                    <span
                      v-if="!$v.form.category.required"
                      class="help is-danger"
                      >Jenis Pos Harus Diisi</span
                    >
                  </div>
                </div>
              </div>
            </div>

             <div class="field" v-if="form.category !== null">
                <label class="label">Input File </label>
              <div class="control">
                <div class="file has-name">
                  <label class="file-label">
                    <input
                      class="file-input"
                      type="file" 
                      @change="uploadFile"
                    />
                    <span class="file-cta">
                      <span class="file-icon">
                        <i class="fas fa-upload"></i>
                      </span>
                      <span class="file-label"> Pilih file </span>
                    </span>
                    <span v-if="form.file" class="file-name">
                      {{ form.file.name }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
         

            
            
          </form>
        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <div class="control btn1">
              <button
                @click="onSubmit"
                :disabled="isFormInvalid"
                class="button is-normal is-link"
              >
                <span> Simpan </span>
              </button>
            </div>

            <div class="control btn">
              <button
                @click="isOpen = false"
                class="button is-normal is-danger"
              >
                <span> Batal </span>
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    registerId: {
      type: String,
      required: false
    },
      
  },
  data() {
    return {
      isOpen: false,
      form: {
        category: null,
        fileHidrologiId: null,
        file: null,
      },
      selectedFile: null,
    };
  },
  validations: {
    form: {
      category: { required },
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
  },
  methods: {
    
    uploadFile(event) {       
      this.form.file = event.target.files[0];        
    },

    onSubmit(){
      let formData = new FormData();
      formData.append("file", this.form.file);  
      console.log(this.form.file)
      
      let regId = this.registerId;
      if(this.form.category === 'POS_DUGA_AIR'){          
         this.$store.dispatch("uploadHidrologi/hidrologiPdaUpload", {regId, formData})
          .then(() =>  {
            this.$toasted.success("Tambah File PDA Berhasil", { duration: 5000 });
            this.$emit('submitted',regId)
          
          })
          .catch(error => {         
              this.$toasted.error(error, {duration: 3000})
          });
       
        // this.isOpen = false;
      }else if(this.form.category === 'POS_CURAH_HUJAN'){
        
         this.$store.dispatch("uploadHidrologi/hidrologiPchUpload", {regId, formData})
          .then(() => {
            this.$toasted.success("Tambah File PCH Berhasil", { duration: 5000 });
            this.$emit('submitted', regId)
          })
          .catch(error => { 
            console.log(error)        
              this.$toasted.error(error, {duration: 3000})
              
          }); 
        
      }
      this.isOpen = false;
    }

     
     
  },
};
</script>

<style scoped>
.modal {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

.modal-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
}
.button {
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  width: 145px;
  letter-spacing: 1px;
  margin-left: 10px;
}

.fal {
  margin-right: 10px;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .modal-card {
    height: 350px;
    width: 350px;
  }

  .button {
    font-size: 14px;
    font-weight: 400;
    height: 35px;
    width: 145px;
    margin-bottom: 5px;
  }
  .is-grouped
  {
    margin-left: -10px;
  }
}
</style>