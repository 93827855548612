import axios from '../../conf/axios';

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {            
        fetchHidrologiPdaTahunan({state,commit}, payload) {
            commit('setItems', {resource: 'landingHidrologiTahunan', items: []}, {root: true})
            return axios.post(`/api/landing/search/pda/tahunan/${payload.registerId}`,payload)
            .then(res => {
                const hidroTahunan = res.data
                commit('setItems', {resource: 'landingHidrologiTahunan', items: hidroTahunan}, {root: true})
                return state.items
             })
        }, 
        fetchHidrologiPchTahunan({state,commit}, payload) {
            commit('setItems', {resource: 'landingHidrologiTahunan', item:[]}, {root: true})
            return axios.post(`/api/landing/search/pch/tahunan/${payload.registerId}`,payload)
            .then(res => {
                const hidroTahunan = res.data 
                commit('setItems', {resource: 'landingHidrologiTahunan', items: hidroTahunan}, {root: true})
                return state.items
             })
        }, 
        
    }
}