<template>
 <div>
        <SidebarNav/>
        <MobileNavbarDash/>
        <MobileSidebarDash/>
        <SidebarNav/>
<div id="app-list" class="view-wrapper" data-naver-offset="214" data-menu-item="#layouts-sidebar-menu"
    data-mobile-item="#layouts-sidebar-menu-mobile">

    <div class="page-content-wrapper">
        <div class="page-content is-relative tabs-wrapper is-slider is-squared is-inverted">

            <div class="page-title has-text-centered">
                <!-- Sidebar Trigger -->
                <div class="huro-hamburger nav-trigger push-resize" data-sidebar="layouts-sidebar">
                    <span class="menu-toggle has-chevron">
                        <span class="icon-box-toggle">
                            <span class="rotate">
                                <i class="icon-line-top"></i>
                                <i class="icon-line-center"></i>
                                <i class="icon-line-bottom"></i>
                            </span>
                        </span>
                    </span>
                </div>

                <div class="title-wrap">
                    <h1 class="title is-4">Data Pos</h1>
                </div>
 
            </div>

            <div class="list-view-toolbar">
                <div class="control has-icon">
                   <div class="control has-icon">
                    <input class="input custom-text-filter" v-model="cariNamaPos" placeholder="Search..." @keydown="cari">
                    <div class="form-icon">
                        <i class="fa fa-search"></i>
                    </div>
                     
                </div>
                </div>

                <div class="tabs-inner">                   
                   <b-button
                    class="button h-button is-success is-outlined is-raised"
                    type="is-info"
                    outlined
                    tag="router-link"
                    :to="'/dashboard/pos/input'"
                    ><i class="fal fa-plus"></i>
                    <span>Tambah Pos</span>
                  </b-button>
                </div>
            </div>

            <div class="page-content-inner">

                <!--List-->
                <div class="list-view list-view-v2">
 
                    <!--Active Tab-->
                    <div id="active-items-tab" class="tab-content is-active">
                        <div v-if="isLoaded" class="list-view-inner">
                            <!--List Item-->
                            <div class="list-view-item" v-for="pos in data" :key="pos.id">
                                <div class="list-view-item-inner">
                                     <img :src="getImgUrl(pos.gambar)" @error="onImageLoadFailure($event)" />
                                    <div class="meta-left">
                                        <h3>
                                            <span data-filter-match>{{pos.namaPos}}</span>
                                             
                                        </h3>
                                        <p>
                                            <i class="lnir lnir-map-marker"></i>
                                            <span data-filter-match>{{pos.lokasiPos}}</span>
                                        </p>
                                        <span>                                           
                                            <i class="lnir lnir-air-flow"></i>
                                             <i aria-hidden="true" class="fas fa-circle icon-separator"></i>
                                            <span data-filter-match>{{pos.das? pos.das.namaDas +' -' : ''}} {{pos.sungai? pos.sungai.namaSungai : '-'}}</span> 
                                        </span>
                                        <span>                                           
                                            <i class="lnir lnir-code"></i>
                                             <i aria-hidden="true" class="fas fa-circle icon-separator"></i>
                                            <span data-filter-match>{{pos.kodeRegister}}</span> 
                                        </span>

                                        
                                    </div>
                                    <div class="meta-right">
                                        <div class="buttons">
                                        <router-link class="button h-button is-info is-elevated" :to="`/dashboard/pos/${pos.registerId}`">
                                            <span class="icon">
                                                <i aria-hidden="true" class="lnir lnir-scan"></i>
                                            </span>
                                            <span>pratinjau</span>
                                         </router-link>                                          
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div v-else class="list-view-inner" >
                                <Skeleton />
                            </div>

                        <nav class="flex-pagination pagination is-rounded" aria-label="pagination">
                              <b-button
                            @click="decrement"
                            type="pagination-previous has-chevron"
                            icon-pack="fas"
                            icon-left="chevron-left"
                            :disabled="hasDisablePreviousPage"
                        ></b-button>
                        <b-button
                            @click="increment"
                            type="pagination-next has-chevron"
                            icon-pack="fas"
                            icon-left="chevron-right"
                            :disabled="hasDisableNextPage"
                        ></b-button>
                            
                        </nav>
                    </div>

                   
                </div>

            </div>
        </div>
    </div>
</div>
 </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import SidebarNav from '../dashboardnav/SidebarNav.vue'
import MobileNavbarDash from '../dashboardnav/MobileNavbarDash.vue'
import MobileSidebarDash from '../dashboardnav/MobileSidebarDash.vue'
import Skeleton from '../shared/Skeleton.vue'
import ApiMethods from '../../conf/config.js' 
export default {
   name: "PosKadasterList",
   components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    Skeleton
    },
    data() {
    return {
      isLoaded: false,
      inputCari: "",
      cariNamaPos: "",
      selected: null,
      data: [],
      total: 0,
      loading: false,
      sortField: "created",
      sortOrder: "DESC",
      defaultSortOrder: "DESC",
      page: 0,
      perPage: 5,     
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      url: ApiMethods.API_BASE_URL,
      path: "/pos/image/download/"
    };
  },
 created() {
    this.searchKadaster()
    .then(() => {      
      this.isLoaded = true;       
    }); 
  },
  beforeMount(){
    const authority = localStorage.getItem('authority')
    if(authority === 'DINAS'){
      this.$router.push('/dashboard/dinas')
    }
  },
  computed: {
    ...mapState({
      kadasters: (state) => state.kadasters.items,
      image: (state) => state.image.item, 
    }),
     
     
  },
  methods: {
    ...mapActions("kadasters", ["searchKadaster", "fetchKadasters"]), 
    /*
     * Load async data
     */
    loadAsyncData() {
      const params = [
        `namaPos=${this.cariNamaPos}`,
        `page=${this.page}`,
        `sortType=${this.sortOrder}`,
        `limit=${this.perPage}`,
      ].join("&");

      this.loading = true;

      this.searchKadaster(params).then(() => {
        let response = this.kadasters;
        this.data = [];
        let enablePreviousPage = response.hasPreviousPage;
        let enableNextPage = response.hasNextPage;
        if (enablePreviousPage) {
           
          this.hasDisablePreviousPage = false;
        } else {
          this.hasDisablePreviousPage = true;
        }

        if (enableNextPage) {
          
          this.hasDisableNextPage = false;
        } else {
          this.hasDisableNextPage = true;
        }

        this.kadasters.kadaster.forEach((item) => {           
          this.data.push(item);
        }); 
      });
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
    cari() {
      this.loadAsyncData();
    },
    dateThAttrs(column) {
      return column.label === "Date"
        ? {
            title: 'This title is sponsored by "th-attrs" prop',
            class: "has-text-success",
          }
        : null;
    },
    columnTdAttrs(row, column) {
      if (row.id === "Total") {
        if (column.label === "ID") {
          return {
            colspan: 4,
            class: "has-text-weight-bold",
            style: {
              "text-align": "left !important",
            },
          };
        } else if (column.label === "Gender") {
          return {
            class: "has-text-weight-semibold",
          };
        } else {
          return {
            style: { display: "none" },
          };
        }
      }
      return null;
    },
    getImgUrl(params) {
       var images = "";
       if(params!==null){
          images = this.url+this.path+params;
          return images;
       }else{
         images = "https://via.placeholder.com/150x150";
       }
      return images;
    },
     onImageLoadFailure(event) {
      event.target.src = "https://via.placeholder.com/150x150";
    },
 
  },

  mounted() {
    this.loadAsyncData();
  },
}
</script>

<style scoped>
.fal {
  margin-right: 10px;
  font-weight: 400;
}
</style>