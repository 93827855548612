import axiosInstance from '../../conf/axiosAdmin'; 


export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fetchPengumuman({state, commit}){
            commit('setItem', {resource: 'landingPengumuman', item: {}}, {root: true})
            return axiosInstance.get('api/landing/annoucement')
            .then(res => {
                const landingPengumuman = res.data              
                commit('setItem', {resource: 'landingPengumuman', item: landingPengumuman}, {root: true})
                return state.item
                })
            }, 
         },
  
}