<template>
  <span>
    <li>
      <router-link to="/" class="hint--top-right" data-hint="Telemetry">
        <i class="lnil lnil-air-flow"></i>
      </router-link>
    </li>
     
    <!-- Layouts -->
    <!-- {{> sidebar-item-layouts}} -->
    <li>
      <router-link
        to="/kualitasair"
        class="hint--top-right"
        data-hint="Kualitas Air"
      >
        <i class="lnil lnil-water-drops"></i>
      </router-link>
    </li>
    <li>
      <router-link
        to="/posdinas"
        class="hint--top-right"
        data-hint="Pos Pengelolaan Dinas"
      >
        <i class="lnil lnil-night-thunder"></i>
      </router-link>
    </li>
    <!-- Bounties -->
    <!-- {{> sidebar-item-elements}} -->
    <li>
      <router-link
        to="/konteninfo"
        class="hint--top-right"
        data-hint="Konten Info"
      >
        <i class="lnil lnil-file-download"></i>
      </router-link>
    </li>
  </span>
</template>


<script>
export default {
  name: "LinkNavigation",
};
</script>