import axios from '../../conf/axios';
 

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fetchLandingKadaster({ state, commit }) {
            commit('setItems', { resource: 'landingKadaster', items: [] }, { root: true })
            return axios.get('/api/landing/kadaster/search')
                .then(res => {
                    const kadasters = res.data.kadaster
                    commit('setItems', { resource: 'landingKadaster', items: kadasters }, { root: true })
                    return state.items
                })
        },
        searchLandingKadaster({ state, commit }, payload) {
            commit('setItems', { resource: 'landingKadaster', items: [] }, { root: true })
            return axios.get(`/api/landing/kadaster/search?${payload}`)
                .then(res => {
                    const kadasters = res.data.kadaster
                    commit('setItems', { resource: 'landingKadaster', items: kadasters }, { root: true })
                    return state.items
                })
        },
        fetchLandingKadasterById({state, commit}, registerId){            
            commit('setItem', {resource: 'landingKadaster', item: {}}, {root: true})
            return axios.get(`/api/landing/kadaster/view/${registerId}`)
            .then(res => {
                const landingKadaster = res.data                 
                commit('setItem', {resource: 'landingKadaster', item: landingKadaster}, {root: true})
                return state.item
             })
        },
        getLandingKadasterFile({ state, commit}, payload) {
            commit('setItem', { resource: 'landingKadaster', item: {} }, { root: true })
            return axios.get(`/api/landing/kadaster/gambar/download/${payload}`)
            .then(res => {
                const landingKadaster = res.data                
                commit('setItem', {resource: 'landingKadaster', item: landingKadaster}, {root: true})
                return state.item
             }) 
        },         
    },
    mutations: {

    }
}