import axios from '../../conf/axios';
import moment from "moment";

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fetchLandingPdaChannel({state,commit}, registerId) {
            commit('setItems', {resource: 'landingChannel', items: []}, {root: true})
            return axios.get(`/api/landing/channel/view/pda/${registerId}`)
            .then(res => {
                const channels = res.data.channels
                const chan = []
                channels
                .forEach((item) => {  
                    item.tanggal =  moment(String(item.tanggal)).format('YYYY-MM-DD HH:mm:ss')
                    chan.push(item);
                });          
                commit('setItems', {resource: 'landingChannel', items: chan}, {root: true})
                return state.items
                })
        },
        fetchLandingHourlyChannel({state,commit}, payload) {
            commit('setItems', {resource: 'landingChannel', items: []}, {root: true})
            return axios.post(`/api/landing/channel/search/hourly/pda/${payload.registerId}`,payload.search)
            .then(res => {
                const channels = res.data.channels  
                commit('setItems', {resource: 'landingChannel', items: channels}, {root: true})
                return state.items
             })
            }, 
        fetchLandingDailyChannel({state,commit}, payload) {
            commit('setItems', {resource: 'landingChannel', items: []}, {root: true})
            return axios.post(`/api/landing/channel/search/daily/pda/${payload.registerId}`,payload.search)
            .then(res => {
                const channels = res.data.channels  
                commit('setItems', {resource: 'landingChannel', items: channels}, {root: true})
                return state.items
             })
            }, 
         
        addPda({state, commit}, payload){            
                let index = state.items.length         
                let channel = payload
                channel.tanggal =  moment(String(channel.tanggal)).format('YYYY-MM-DD HH:mm:ss')                 
                commit('addItemToArray', {item: channel, index, resource: 'landingChannel'}, {root: true})             
                return state.items
            },
    },
    mutations: {

    }
}