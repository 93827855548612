<template>
  
  <div>
    <div v-if="isActive" class="bodys">
    <div class="modal-background" :class="{ show: showModal }">
      <div class="popup" :class="{ show: showModal }">
        <div class="contentBox">
          <!-- <div class="contentBox"> -->
          <div class="close" @click="showMod">
            <span class="text">&times;</span>
          </div>
          <!-- <div class="imgBx">
            <img src="../../assets/image/gambar.jpg" />
          </div> -->

          <div class="judul">
            <span> {{ title }} </span>
          </div>

          <div class="content">
            <div class="page">
              <p v-html="body"></p>
            </div>
          </div>
          <div class="tombol">
            <div class="button is-focused is-info" @click="showMod">
              <span class="text1">Tutup</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else >

  </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "ModalPengumuman",

  data() {
    return {
      title: "",
      body: "",
      showModal: false,
      isActive: false,
    };
  },
  created() {
    this.fetchPengumuman().then((resp) => {
      this.title = resp.title;
      this.body = resp.body;
      this.showModal = !resp.isActive;
      this.isActive = resp.isActive;
    });
  },
  computed: {
    ...mapState({
      landingPengumuman: (state) => state.landingPengumuman.item,
    }),
  },
  methods: {
    ...mapActions("landingPengumuman", ["fetchPengumuman"]),
    showMod() {
      this.showModal = !this.showModal;
    },
  },
};
</script>



<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.bodys {
  height: 100%;
  height: 100vh;
  box-sizing: border-box;
  /* display: none; */
}

.modal-background {
  background: rgba(9, 9, 9, 0.273);
  transition: all 2s ease-in;
  z-index: 9;
}

.modal-background.show {
  background: rgba(255, 254, 254, 0.233);
  transition: all 1s ease-out;
  z-index: 9;
  visibility: hidden;
  display: block;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fadeIn 2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  z-index: 9;
}

.popup.show {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fadeOut 2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  z-index: 9;
}

.contentBox {
  position: relative;
  width: 800px;
  height: 870px;
  background: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.267);
  transition: all 3s ease-in-out;
  animation: slide-in-anim 3s ease-out forwards;
  border-top: 7px solid #212c5f;
}

.contentBox .judul {
  margin-top: 10px;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: #212c5f;
}

@keyframes slide-in-anim {
  20% {
    opacity: 1;
  }
  0% {
    transform: scale(0) translateY(100px);
    opacity: 1;
  }
  50% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes slide-out-anim {
  20% {
    opacity: 1;
  }
  0% {
    transform: scale(0) translateY(100px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 0;
  }
}
.contentBox .imgBx {
  position: relative;
  width: 500px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentBox .imgBx img {
  position: relative;
  max-width: 500px;
}
.contentBox .content {
  position: relative;
  width: 100%;
  height: 650px;
  margin: 20px;
  overflow: auto;
  overflow-x: hidden;
}
.contentBox .content::-webkit-scrollbar {
  display: none;
} 
.contentBox .content .page {
  font-size: 14px;
  font-weight: 500;
  text-align: justify;
  margin: 20px;
}

.contentBox .tombol {
  margin-top: 10px;
}

.contentBox .tombol .button {
  width: 120px;
  height: 40px;
  background: #212c5f;
  margin-top: 0px;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;
}

.contentBox .tombol .button:hover {
  background: #2d6efd;
  transition: all 0.5s ease-in-out;
}

.contentBox .tombol .button .text1 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 35px;
  height: 35px;
  background: #212c5f;
  border-radius: 50%;
  cursor: pointer;
}
.close .text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  margin-top: 3px;
  margin-left: 2px;
}

@media (max-width: 769px) {
  .contentBox {
    width: 300px;
    height: 620px;
    flex-direction: column;
  }
  .contentBox .imgBx {
    position: relative;
    width: 150px;
    height: 200px;
    transform: translateX(80px);
    margin-top: 50px;
  }

  .contentBox .imgBx img {
    position: relative;
    max-width: 250px;
  }
  .contentBox .content .page {
    font-size: 12px;
    font-weight: 500;
    text-align: justify;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0px;
  }
  .contentBox .judul {
    margin-top: 50px;
    font-size: 16px;
    font-weight: 700;
  }
  .contentBox .tombol .button {
    width: 100px;
    height: 35px;
    background: #212c5f;
    margin-top: 0px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  .contentBox .tombol .button .text1 {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    background: #212c5f;
    border-radius: 50%;
    cursor: pointer;
  }
  .close .text {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    margin-top: 5px;
    margin-left: 2px;
  }
}
</style>