import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js';

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        async fetchUsers({state, commit}){
            commit('setItems', {resource: 'users', items: []}, {root: true})
            return await axiosInstance.get('/api/user/manage/search')
             .then(res => {
                 const users = res.data.users
                 commit('setItems', {resource: 'users', items: users}, {root: true})
                 return state.items
              })
             },
       async searchUsers({state, commit}, params){
            commit('setItems', {resource: 'users', items: []}, {root: true})
            return await axiosInstance.get(`/api/user/manage/search?${params}`)
             .then(res => {
                 const results = res.data
                 commit('setItems', {resource: 'users', items: results}, {root: true})
                 return state.items
              })
             },
       async fetchUserByCredential({state, commit}, userCredential){
            commit('setItem', {resource: 'users', item: {}}, {root: true})
            return await axiosInstance.get(`/api/user/manage/view/${userCredential}`)
            .then(res => {
                const users = res.data               
                commit('setItem', {resource: 'users', item: users}, {root: true})
                return state.item
                })
            },     
        createUser(context, payload){
            const headers = {
                'Content-Type': 'application/json',
              }
            return axiosInstance.post('/api/user/manage/register', 
            payload,{
                headers: headers
              })
              .then(res => res.data)
        }, 
        updateUser(context, payload){
             
            const headers = {
                'Content-Type': 'application/json',
              }   
                     
                return axiosInstance.put(`/api/user/manage/update/${payload.credential}`, payload,
                {
                  headers: headers
                })             
                 
        },
        changePassword(context,  payload){
             
            const headers = {
                'Content-Type': 'application/json',
              }   
              
                return axiosInstance.put(`/api/user/manage/psswd/${payload.credential}`, payload,
                {
                  headers: headers
                })             
                 
        },    
        deletUser({state, commit}, credential){                            
            commit('setItem', {resource: 'users', item: {}}, {root: true})
            return axiosInstance.delete(`/api/user/manage/delete/${credential}`)
            .then(res => {
                const users = res.data                 
                commit('setItem', {resource: 'users', item: users}, {root: true})
                return state.item
             })
          },
        blockUser({state, commit}, credential){                            
            commit('setItem', {resource: 'users', item: {}}, {root: true})
            return axiosInstance.put(`/api/user/manage/block/${credential}`)
            .then(res => {
                const users = res.data                 
                commit('setItem', {resource: 'users', item: users}, {root: true})
                return state.item
             })
          },
          removePos({state, commit}, credential){                            
            commit('setItem', {resource: 'users', item: {}}, {root: true})
            return axiosInstance.put(`/api/user/manage/removepos/${credential}`)
            .then(res => {
                const users = res.data                 
                commit('setItem', {resource: 'users', item: users}, {root: true})
                return state.item
             })
          },    
    },
    mutations: {

    }
}