<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->

            <div class="title-wrap">
              <h1 class="title is-4">Input Data Kualitas Air</h1>
            </div>
          </div>

          <div class="page-content-inner">
            <!--Form Layout 1-->
            <div class="form-layout">
              <div class="form-outer">
                <div class="form-header stuck-header">
                  <div class="form-header-inner">
                    <div class="left">
                      <h3>Form Input</h3>
                    </div>
                    <div class="right">
                      <div class="buttons">
                        <button
                         @click="$router.go(-1)"
                          class="button h-button is-light is-dark-outlined"
                        >
                          <span class="icon">
                            <i class="lnir lnir-arrow-left rem-100"></i>
                          </span>
                          <span>Batal</span>
                        </button>
                        <button
                          class="button h-button is-primary is-raised"
                          @click.prevent="sendSungai"
                          :disabled="isFormInvalid"
                        >
                          Simpan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-body">
                  <!--Fieldset-->
                  <div class="form-fieldset">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Nama Sungai</label>
                            <div class="select is-medium is-link pilih">
                              <b-autocomplete
                                v-model="name"
                                placeholder="e.g. Cimanuk"
                                :keep-first="keepFirst"
                                :open-on-focus="openOnFocus"
                                :data="filteredDataObj"
                                field="namaSungai"
                                @select="
                                  (option) =>
                                    option !== null
                                      ? (form.sungai = option.namaSungai)
                                      : String()
                                "
                                :clearable="clearable"
                              >
                                <template #empty
                                  >No results for {{ name }}</template
                                >
                              </b-autocomplete>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label is-normal">Latitude</label>
                            <input
                              @blur="$v.form.lat.$touch()"
                              v-model="form.lat"
                              type="number"
                              class="input"
                              placeholder="Latitude"
                            />
                            <div v-if="$v.form.lat.$error">
                              <span
                                v-if="!$v.form.lat.required"
                                class="help is-danger"
                                >Latitude Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label is-normal">Longitude</label>
                            <input
                              @blur="$v.form.lng.$touch()"
                              v-model="form.lng"
                              type="number"
                              class="input"
                              placeholder="Longitude"
                            />
                            <div v-if="$v.form.lng.$error">
                              <span
                                v-if="!$v.form.lng.required"
                                class="help is-danger"
                                >Longitude Pos Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label is-normal">Periode</label>
                            <date-picker
                              v-model="form.periode"
                              type="month"
                              valueType="YYYY-MM-DD"
                              placeholder="Select month"
                              
                            >
                            </date-picker>
                            <div v-if="$v.form.periode.$error">
                              <span
                                v-if="!$v.form.periode.required"
                                class="help is-danger"
                                >Periode Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Index Analisa</label>
                            <div class="select is-normal">
                              <select
                                v-model="form.hasilAnalisa"
                                @blur="$v.form.hasilAnalisa.$touch()"
                                aria-placeholder="pilih Mutu Air"
                              >
                                <option value="baku_mutu">
                                  Memenuhi Baku Mutu
                                </option>
                                <option value="cemar_ringan">
                                  Cemar Ringan
                                </option>
                                <option value="cemar_sedang">
                                  Cemar Sedang
                                </option>
                                <option value="cemar_berat">
                                  Cemar Berat
                                 </option>
                              </select>
                            </div>
                            <div v-if="$v.form.hasilAnalisa.$error">
                              <span
                                v-if="!$v.form.hasilAnalisa.required"
                                class="help is-danger"
                                >Hasil Analisa Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label is-normal"
                              >Index Pencemaran</label
                            >
                            <input
                              @blur="$v.form.indexPencemaran.$touch()"
                              v-model="form.indexPencemaran"
                              type="number"
                              class="input"
                              placeholder="Index Pencemaran"
                            />
                              <div v-if="$v.form.indexPencemaran.$error">
                  <span v-if="!$v.form.indexPencemaran.required" class="help is-danger"
                    >Index Pencemaran Harus Diisi</span
                  >
                </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end form -->
                </div>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import moment from 'moment';
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "FormInputKualitasAir",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    DatePicker,
  },
  data() {
    return {
      isOpen: false,
      date: null,
      form: {
        sungai: null,
        lat: null,
        lng: null,
        periode: null,
        hasilAnalisa: null,
        indexPencemaran: null,
      },
      keepFirst: false,
      openOnFocus: false,
      name: "",
      selected: null,
      clearable: false,
    };
  },
  validations: {
    form: {
      sungai: { required },
      lat: { required },
      lng: { required },
      periode: { required },
      hasilAnalisa: { required },
      indexPencemaran: { required },
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
    ...mapState({
      sungaiList: (state) => state.sungai.items,
    }),
    filteredDataObj() {
       return this.sungaiList.filter(option => {
           return (
                    option.namaSungai
                        .toString()
                        .indexOf(this.name) >= 0
                )
            })
    }
  },
  created() {
    this.fetchSungai();
  },
  methods: {
    ...mapActions("sungai", ["fetchSungai"]),
    sendSungai() {
      this.$store.dispatch("kualitas/createKualitasAir", this.form);
      this.$toasted.success("Tambah Data Kualitas Air Berhasil", { duration: 5000 });
      this.$router.push('/dashboard/kualitasair'); 
    },
    
    validateSelection(selection) {
      this.selected = selection;
      this.form.sungaiId = selection.id;
       
    },
    getDropdownValues(keyword) {
      console.log(
        "You could refresh options by querying the API with " + keyword
      );
    },
    customDateFormat(date) {
       return moment(date).format('DD/MM/YY');
    }
  },
};
</script>

 <style scoped>

 </style>