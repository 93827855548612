import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js' 


export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {
        getImage(state) {         
            return state.item;
          }
    },
    actions: {
        searchImage({state, commit}){
            commit('setItems', {resource: 'image', items: []}, {root: true})
           return axiosInstance.get('/api/pos/image/search/')
            .then(res => {
                const image = res.data
               
                commit('setItems', {resource: 'image', items: image}, {root: true})
                return state.items
             })
        },
        createImage(context, payload){
             
            const headers = {
                'Content-Type': 'multipart/form-data',
              }
            return axiosInstance.post('/api/pos/image/upload', 
            payload,{
                headers: headers
              })
              .then(res => res.data)
        },
        getGambar({state, commit}, posId){
            commit('setItem', {resource: 'image', item: {}}, {root: true})
            return axiosInstance.get(`/api/kadaster/manage/view/${posId}`)
            .then(res => {
                const filename = res.data.gambar
                const urlImg = `/api/pos/image/download/${filename}`
               
                commit('setItem', {resource: 'image', item: urlImg}, {root: true})                              
                return state.item                
             })             
        },
        getPic({state, commit}, images){
            commit('setItem', {resource: 'image', item: {}}, {root: true})
            return axiosInstance.get(`/api/pos/image/download/${images}`)
            .then(res => {
                const filename = res              
                commit('setItem', {resource: 'image', item: filename}, {root: true})                              
                return state.item                
             })             
        }
    },
    mutations: {

    }
}