import axios from 'axios'
import jwt from 'jsonwebtoken'
import axiosAdmin from '@/conf/axiosAdmin' 
import { rejectError } from '@/helpers'

function checkTokenValidity (token) {
  if (token) {
    const decodedToken = jwt.decode(token)

    return decodedToken && (decodedToken.exp * 6000) > new Date().getTime()
  }

  return false
}

export default {
  namespaced: true,
  state: {
    user: null,
    isAuthResolved: false
  },
  getters: {
    authUser (state) {
      return state.user || null
    },
    isAuthenticated (state) {
      return !!state.user
    },
    
  },
  actions: {
    loginWithUsernameAndPassword ({commit}, userData) {
      return axios.post('/api/auth/authenticate', userData)
        .then(res => {
          const user = res.data
          if(user.authorityType != 'PENGAMAT'){
            localStorage.setItem('access_token',user.access_token)
            localStorage.setItem('refresh_token',user.refresh_token)
            localStorage.setItem('authority',user.authorityType)
            localStorage.setItem('expires_in',user.expires_in)
            localStorage.setItem('isAuth', true )
            commit('setAuthUser', user)
            return user
          }else{
            return user
          }
        }
        
        )
        .catch(err => rejectError(err))
    },
    logout({commit}) {
         const refreshToken = localStorage.getItem('refresh_token')
        axios.delete(`/api/auth/revoke/${refreshToken}`)
        return new Promise((resolve) => {
          localStorage.removeItem('access_token')
          localStorage.removeItem('refresh_token')
          localStorage.removeItem('authority')
          localStorage.removeItem('expires_in')
          localStorage.removeItem('isAuth')
          commit('setAuthUser', null)
          commit('setAuthState', false)
          resolve(true)
        })   
    },
    getAuthUser ({commit, getters}) {
      const authUser = getters['authUser']
      const token = localStorage.getItem('access_token')
      const isAuth = localStorage.getItem('isAuth') 
      const isTokenValid = checkTokenValidity(token)

      if (authUser && isTokenValid) {         
         return Promise.resolve(authUser) 
        }
      if(isAuth && isTokenValid) {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          }
        }
        return axiosAdmin.get('/api/profile', config)
          .then((res) => {
            const user = res.data
            commit('setAuthUser', user)
            commit('setAuthState', true)
            return user
          })
          .catch(err => {
            commit('setAuthUser', null)
            commit('setAuthState', false)           
            return err
          }) 
      }  
      
    },
    
  },
  mutations: {
    setAuthUser (state, user) {
      return state.user = user
    },
    setAuthState (state, authState) {
      return state.isAuthResolved = authState
    },
    
  }
}











