<template>
  <div class="container">
    <form class="">
      <div class="field">
        <label class="label">Masukan Gambar Pos</label>
        <div class="file has-name">
          <label class="file-label">
            <input
              class="file-input"
              id="file"
              type="file"
              name="file"
              @change="uploadGambar"
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label"> Pilih file </span>
            </span>
            <span v-if="file" class="file-name">
              {{ file.name }}
            </span>
          </label>
        </div>
      </div>

      <div class="btn2">
        <!-- <button 
            @click.prevent="sendGambar" 
            class="button is-normal is-link"
            >
          kirim gambar
        </button> -->
      </div>
    </form>
  </div>
</template>

<script>
export default { 
  data() {
    return {
      file: null,
      fileName: null
    };
  },
  methods: {
    uploadGambar(event) {
      console.log(event);
      this.file = event.target.files[0];
      let formData = new FormData();
      formData.append('file', this.file);
      this.$store.dispatch("image/createImage", formData)
      .then(response => {
           console.log(response);
           this.fileName = response.fileName
           this.$emit('gambarSubmitted', this.fileName)
           this.$toasted.success('Tambah Gambar Berhasil', {duration: 5000})
      }
       
      )        
      .catch((errorMessage) => {
            this.$toasted.error(errorMessage, {duration: 5000})
      })
      
    },
    sendGambar() {
      let formData = new FormData();
      formData.append('file', this.file);
      this.$store.dispatch("image/createImage", formData)
      .then(response => {
           console.log(response);
           this.gambarId = response.id
           this.$emit('gambarSubmitted', this.gambarId)
           this.$toasted.success('Tambah Gambar Berhasil', {duration: 5000})
      }
       
      )        
      .catch((errorMessage) => {
            this.$toasted.error(errorMessage, {duration: 5000})
      })
    },
  },
};
</script>

<style scoped>

.label {
  font-size: 16px;
  font-weight: 400;
}
.btn2 {
  padding-top: 10px;
}
@media (max-width: 768px) {
  
}
</style>