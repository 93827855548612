import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js';

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {            
        fetchHidrologiTahunan({state,commit}, payload) {
            commit('setItem', {resource: 'hidrologiTahunan', item: {}}, {root: true})
            return axiosInstance.post(`/api/hidrologi/pda/tahunan/${payload.registerId}`,payload)
            .then(res => {
                const hidroTahunan = res.data
                commit('setItem', {resource: 'hidrologiTahunan', item: hidroTahunan}, {root: true})
                return state.item
             })
        }, 
        fetchHidrologiPchTahunan({state,commit}, payload) {
            commit('setItem', {resource: 'hidrologiTahunan', item: {}}, {root: true})
            return axiosInstance.post(`/api/hidrologi/pch/tahunan/${payload.registerId}`,payload)
            .then(res => {
                const hidroTahunan = res.data
                commit('setItem', {resource: 'hidrologiTahunan', item: hidroTahunan}, {root: true})
                return state.item
             })
        }, 
        
    },
    mutations: {

    }
}