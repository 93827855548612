<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->

            <div class="title-wrap">
              <h1 class="title is-4">Input Cluster Sungai</h1>
            </div>
          </div>

          <div class="page-content-inner">
            <!--Form Layout 1-->
            <div class="form-layout">
              <div class="form-outer">
                <div class="form-header stuck-header">
                  <div class="form-header-inner">
                    <div class="left">
                      <h3>Form Input</h3>
                    </div>
                    <div class="right">
                      <div class="buttons">
                        <button
                          @click="$router.go(-1)"
                          class="button h-button is-light is-dark-outlined"
                        >
                          <span class="icon">
                            <i class="lnir lnir-arrow-left rem-100"></i>
                          </span>
                          <span>Batal</span>
                        </button>
                        <button
                          class="button h-button is-primary is-raised"
                          @click.prevent="send"
                          :disabled="isFormInvalid"
                        >
                          Simpan
                        </button>
                        <button
                          class="button h-button is-danger is-raised"
                          @click.prevent="hapus"
                          type="is-success"
                          outlined
                        >
                          Hapus
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-body">
                  <!--Fieldset-->
                  <div class="form-fieldset">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <div class="file has-name">
                              <label class="file-label">
                                <input
                                  class="file-input"
                                  id="file"
                                  type="file"
                                  name="file"
                                  @change="uploadFile"
                                />
                                <span class="file-cta">
                                  <span class="file-icon">
                                    <i class="fas fa-upload"></i>
                                  </span>
                                  <span class="file-label">
                                    Pilih file Cluster</span
                                  >
                                </span>
                                <span v-if="file" class="file-name">
                                  {{ file.name }}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Pos Hulu</label>
                            <div class="select is-normal">
                              <select
                                v-model="form.posHuluId"
                                @blur="$v.form.posHuluId.$touch()"
                                aria-placeholder="Pilih Pos Hulu"
                              >
                                <option
                                  v-for="(kadaster, idx) in kadasters"
                                  :key="idx"
                                  :value="kadaster.registerId"
                                >
                                  {{ kadaster.namaPos }}
                                </option>
                              </select>
                            </div>
                            <div v-if="$v.form.posHuluId.$error">
                              <span
                                v-if="!$v.form.posHuluId.required"
                                class="help is-danger"
                                >Pos Hulu Harus DiPilih</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Pos Hilir</label>
                            <div class="select is-normal">
                              <select
                                v-model="form.posHilirId"
                                @blur="$v.form.posHilirId.$touch()"
                                aria-placeholder="Pilih Pos Hilir"
                              >
                                <option
                                  v-for="(kadaster, idx) in kadasters"
                                  :key="idx"
                                  :value="kadaster.registerId"
                                >
                                  {{ kadaster.namaPos }}
                                </option>
                              </select>
                            </div>
                             
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end form -->
                </div>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
// import AppSpinner from '../shared/AppSpinner.vue'
export default {
  name: "FormUpdateForecasting",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    // AppSpinner
  },
  data() {
    return {
      form: {
        id: null,
        posHuluId: null,
        posHilirId: null,
        uratSungai: null,
      },
      file: null,
    };
  },
  validations: {
    form: {
      posHuluId: { required },
    },
  },

  created() {
    this.id = this.$route.params.id;
    this.fetchClusterByid(this.id).then((resp) => {
      this.isLoaded = true;
      this.form.id = resp.id;
      this.form.posHuluId = resp.posHuluId;
      this.form.posHilirId = resp.posHilirId;
      this.form.uratSungai = resp.uratSungai;
    });
    this.getKadasters();
  },
  computed: {
    ...mapState({
      clusterSungai: (state) => state.clusterSungai.item,
      kadasters: (state) => state.kadasters.items,
    }),
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
  },
  methods: {
    ...mapActions("clusterSungai", ["fetchClusterByid"]),
    ...mapActions("kadasters", ["getKadasters"]),
    hapusCluster() {
      this.$swal({
        title: "Apakah anda yakin akan menghapus Data Cluster Pos tersebut?!",
        text: "Setelah dihapus anda tidak dapat mengembalikannya!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#c0392b",
        confirmButtonText: "YA, HAPUS!",
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if confirmed
          this.$store.dispatch("clusterSungai/deleteCluster", this.id);
          this.$router.push("/dashboard/cluster");
          this.$toasted.success("Hapus Data Cluster Pos Berhasil", {
            duration: 5000,
          });
        }
      });
    },
    uploadFile(event) {
      // console.log(event);
      this.file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
      this.$store
        .dispatch("clusterFile/fileUpload", formData)
        .then((response) => {
          this.form.uratSungai = response.fileName;
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },
    send() {
      this.$store
        .dispatch("clusterSungai/updateCluster", this.form)
        .then(() => {
          this.$router.push("/dashboard/cluster");
          this.$toasted.success("Rubah Data Cluster Berhasil", {
            duration: 5000,
          });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },
    hapus(){       
      this.$swal({
                  title: "Apakah anda yakin akan menghapus Data Cluster tersebut?!",
                  text: "Setelah dihapus anda tidak dapat mengembalikannya!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#c0392b",
                  confirmButtonText: "YA, HAPUS!"
                }).then((result) => { // <--
                  if (result.value) { // <-- if confirmed
                    this.$store.dispatch("clusterSungai/deleteCluster", this.form.id)
                    this.$router.push('/dashboard/cluster')
                    this.$toasted.success("Hapus Data Cluster Berhasil", { duration: 5000 }) 
                    
                  }
              });
 
    },
  },
};
</script>

 <style scoped>
</style>