<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
            <div
              class="huro-hamburger nav-trigger push-resize"
              data-sidebar="layouts-sidebar"
            >
              <span class="menu-toggle has-chevron">
                <span class="icon-box-toggle">
                  <span class="rotate">
                    <i class="icon-line-top"></i>
                    <i class="icon-line-center"></i>
                    <i class="icon-line-bottom"></i>
                  </span>
                </span>
              </span>
            </div>

            <div class="title-wrap">
              <h1 class="title is-4">User List</h1>
            </div>
          </div>

          <div class="list-flex-toolbar">
            <div class="control has-icon">
              <input
                class="input custom-text-filter"
                placeholder="Search..."
                @keydown="cari"
                v-model="carilah"
              />
              <div class="form-icon">
                <i class="fa fa-search"></i>
              </div>
            </div>
            <div class="tabs-inner">
                   <router-link  to="/dashboard/inputuser" class="button h-button is-success is-outlined is-raised">
                   <i class="fal fa-plus"></i> <span>user</span> </router-link>
                </div>
          </div>

          <div class="page-content-inner">
            <div class="flex-list-wrapper flex-list-v1">
              <div class="flex-table">
                <!--Table header-->
                <div class="flex-table-header">
                  <span class="is-grow">username</span>
                  <span>name</span>
                  <span>Status</span>
                  <span>Otoritas</span>
                  <span>Nama Pos</span>
                  <span>Actions</span>
                </div>

                <div v-if="isLoaded" class="flex-list-inner">
                  <!--Table item-->
                  <div
                    class="flex-table-item"
                    v-for="user in data"
                    :key="user.id"
                  >
                    <div class="flex-table-cell is-media is-grow">
                      <div>
                        <span class="item-name dark-inverted">{{
                          user.username
                        }}</span>
                      </div>
                    </div>
                    <div class="flex-table-cell" data-th="Name">
                      <span class="light-text">{{ user.name }}</span>
                    </div>

                    <div class="flex-table-cell" data-th="Status">
                      <span
                        class="tag is-danger  is-rounded"
                        v-if="user.isBlocked"
                        >BLOCKED</span
                      >
                      <span class="tag is-success is-rounded" v-else>
                        UNBLOCKED 
                      </span>
                    </div>
                    <div class="flex-table-cell" data-th="Level">
                      <div class="avatar-stack is-pushed-mobile">
                        {{ user.authorityType }}
                      </div>
                    </div>
                     <div class="flex-table-cell" data-th="Level">
                      <div class="avatar-stack is-pushed-mobile">
                        {{ user.namaPos ? user.namaPos : '-' }}
                      </div>
                    </div>
                    <div class="flex-table-cell">
                      <router-link
                        class="button h-button is-info is-elevated"
                        :to="`/dashboard/users/${user.credential}`"
                      >
                        <span class="icon">
                          <i aria-hidden="true" class="lnir lnir-scan"></i>
                        </span>
                        <span>pratinjau</span>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div v-else class="flex-list-inner">
                  <Skeleton />
                </div>
              </div>

              <!--Table Pagination-->
              <nav
                class="flex-pagination pagination is-rounded"
                aria-label="pagination"
                data-filter-hide
              >
                <b-button
                  @click="decrement"
                  type="pagination-previous has-chevron"
                  icon-pack="fas"
                  icon-left="chevron-left"
                  :disabled="hasDisablePreviousPage"
                ></b-button>
                <b-button
                  @click="increment"
                  type="pagination-next has-chevron"
                  icon-pack="fas"
                  icon-left="chevron-right"
                  :disabled="hasDisableNextPage"
                ></b-button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
import Skeleton from "../shared/Skeleton.vue";
export default {
  name: "UsersList",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    Skeleton,
  },
  data() {
    return {
      isBlocked: false,
      isLoaded: false,
      carilah: "",
      data: [],
      total: 0,
      loading: false,
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      sortField: "created",
      page: 0,
      perPage: 10,
    };
  },
  created() {     
    this.fetchUsers().then(() => {
      this.isLoaded = true;
    });
  },
  beforeMount(){
     
    const authority = localStorage.getItem('authority')
    if(authority === 'DINAS'){
      this.$router.push('/dashboard/dinas')
    }
  },
  computed: {
    isAuthResolved() {
      return this.$store.state.auth.isAuthResolved;
    },
    ...mapState({ 
      users: (state) => state.users.items,
    }),
  },
  methods: {
    ...mapActions("users", ["fetchUsers", "searchUsers", "createUser"]),
    /*
     * Load async data
     */
    loadAsyncData() {
      const params = [
        `username=${this.carilah}`,
        `page=${this.page}`,
        `limit=${this.perPage}`,
      ].join("&");
      this.loading = true;
      this.searchUsers(params).then(() => {
        this.data = [];
        let enablePreviousPage = this.users.hasPreviousPage;
        let enableNextPage = this.users.hasNextPage;
        if (enablePreviousPage) {
          this.hasDisablePreviousPage = false;
        } else {
          this.hasDisablePreviousPage = true;
        }

        if (enableNextPage) {
          this.hasDisableNextPage = false;
        } else {
          this.hasDisableNextPage = true;
        }

        this.users.users.forEach((item) => {
          this.loading = false;
          this.data.push(item);
        });
      });
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.loading = false;
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.loading = false;
      this.page -= 1;
      this.loadAsyncData();
    },
    cari() {
      this.loading = false;
      this.loadAsyncData();
      // this.carilah = "";
    },
    remove(index) {
      console.log("index" + index);
    },
  },

  mounted() {
    this.loadAsyncData();
  },
};
</script>