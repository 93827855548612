<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
            <div
              class="huro-hamburger nav-trigger push-resize"
              data-sidebar="layouts-sidebar"
            >
              <span class="menu-toggle has-chevron">
                <span class="icon-box-toggle">
                  <span class="rotate">
                    <i class="icon-line-top"></i>
                    <i class="icon-line-center"></i>
                    <i class="icon-line-bottom"></i>
                  </span>
                </span>
              </span>
            </div>

            <div class="title-wrap">
              <h1 class="title is-4">Data Konten Informasi</h1>
            </div>
          </div>

          <div class="list-flex-toolbar">
            <div class="control has-icon">
              <input class="input custom-text-filter" placeholder="Search..." />
              <div class="form-icon">
                <i class="fa fa-search"></i>
              </div>
            </div>

            <div class="tabs-inner">
              <router-link
                to="/dashboard/inputkonteninformasi"
                class="button h-button is-success is-outlined is-raised"
              >
                <i class="fal fa-plus"></i>
                <span class="tambah">Tambah Konten Informasi</span>
              </router-link>
            </div>
          </div>

          <div class="page-content-inner">
            <div class="table-wrapper">
              <b-table
                class="table is-datatable is-hoverable table-is-bordered"
                :data="isEmpty ? [] : data"
                :bordered="isBordered"
                :striped="isStriped"
                :narrowed="isNarrowed"
                :hoverable="isHoverable"
                :loading="isLoading"
                :focusable="isFocusable"
                :mobile-cards="hasMobileCards"
              >
                <b-table-column
                  field="created"
                  label="Tanggal"
                  :td-attrs="columnTdAttrs"
                  v-slot="props"
                >
                  {{ props.row.fileContent.created }}
                </b-table-column>
                <b-table-column
                  field="title"
                  label="Judul"
                  :td-attrs="columnTdAttrs"
                  v-slot="props"
                >
                  {{ props.row.title }}
                </b-table-column>

                <b-table-column
                  field="category"
                  label="Kategori"
                  :td-attrs="columnTdAttrs"
                  v-slot="props"
                >
                  {{ kategori(props.row.category) }}
                </b-table-column>

                <b-table-column
                  field="fileContent"
                  label="File Konten"
                  :td-attrs="columnTdAttrs"
                  v-slot="props"
                >
                  <router-link
                    target="_blank"
                    :to="`/konteninfo/pratinjau/${props.row.fileContent.fileName}`"
                  >
                    {{ props.row.fileContent.oriFileName }}
                  </router-link>
                </b-table-column>

                <!-- <b-table-column
                    field="fileName"
                    label="File"
                    :td-attrs="columnTdAttrs"
                    v-slot="props"
                  >
                    {{ props.row.fileContent.fileName }}
                  </b-table-column> -->

                <b-table-column field="id" label="Action" v-slot="props">
                  <router-link
                    class="button is-success is-focused"
                    :to="`/dashboard/konteninformasi/${props.row.id}`"
                  >
                    Pratinjau
                  </router-link>
                </b-table-column>

                <template #empty>
                  <div class="has-text-centered">No records</div>
                </template>
              </b-table>

              <!--Table Pagination-->
              <nav
                class="flex-pagination pagination is-rounded"
                aria-label="pagination"
                data-filter-hide
              >
                <b-button
                  @click="decrement"
                  type="pagination-previous has-chevron"
                  icon-pack="fas"
                  icon-left="chevron-left"
                  :disabled="hasDisablePreviousPage"
                ></b-button>
                <b-button
                  @click="increment"
                  type="pagination-next has-chevron"
                  icon-pack="fas"
                  icon-left="chevron-right"
                  :disabled="hasDisableNextPage"
                ></b-button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
//import AppSpinner from "../shared/AppSpinner.vue";
export default {
  name: "KontenInfoList",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    //AppSpinner,
  },
  data() {
    return {
      isLoaded: false,
      inputCari: "",
      cariKategori: "",
      selected: null,
      data: [],
      total: 0,
      loading: false,
      sortField: "created",
      sortOrder: "ASC",
      defaultSortOrder: "ASC",
      page: 0,
      perPage: 10,

      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
    };
  },
  created() {
    this.searchContent().then(() => {
      this.isLoaded = true;
    });
  },
  beforeMount() {
    const authority = localStorage.getItem("authority");
    if (authority === "DINAS") {
      this.$router.push("/dashboard/dinas");
    }
  },
  computed: {
    ...mapState({
      results: (state) => state.content.items,
    }),
  },
  methods: {
    ...mapActions("content", [
      "searchContent",
      "getContentFile",
      "downloadContentFile",
    ]),
    downloadFile(fileName) {
      this.downloadContentFile(fileName);
    },
    loadAsyncData() {
      const params = [
        `category=${this.cariKategori}`,
        `page=${this.page}`,
        `sortType=${this.sortOrder}`,
        `limit=${this.perPage}`,
      ].join("&");

      this.loading = true;

      this.searchContent(params).then(() => {
        let response = this.results;
        this.data = [];
        let enablePreviousPage = response.hasPreviousPage;
        let enableNextPage = response.hasNextPage;
        if (enablePreviousPage) {
          this.hasDisablePreviousPage = false;
        } else {
          this.hasDisablePreviousPage = true;
        }

        if (enableNextPage) {
          this.hasDisableNextPage = false;
        } else {
          this.hasDisableNextPage = true;
        }

        this.results.contentInfoList.forEach((item) => {
          this.loading = false;
          item.fileContent.created = moment(
            String(item.fileContent.created)
          ).format("YYYY-MM-DD");
          this.data.push(item);
        });
        // .catch((error) => {
        //   this.data = [];
        //   this.total = 0;
        //   this.loading = false;
        //   throw error;
        // });
      });
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
    cari() {
      this.loadAsyncData();
    },
    dateThAttrs(column) {
      return column.label === "Date"
        ? {
            title: 'This title is sponsored by "th-attrs" prop',
            class: "has-text-success",
          }
        : null;
    },
    columnTdAttrs(row, column) {
      if (row.id === "Total") {
        if (column.label === "ID") {
          return {
            colspan: 4,
            class: "has-text-weight-bold",
            style: {
              "text-align": "left !important",
            },
          };
        } else if (column.label === "Gender") {
          return {
            class: "has-text-weight-semibold",
          };
        } else {
          return {
            style: { display: "none" },
          };
        }
      }
      return null;
    },
    remove(index) {
      console.log("index" + index);
    },
    kategori(value) {
      if (value === "data_olahan") {
        value = "Data Olahan";
      } else if (value === "hasil_analisa") {
        value = "Hasil Analisa";
      } else {
        value = "Informasi Umum";
      }
      return value;
    },
  },

  mounted() {
    this.loadAsyncData();
  },
};
</script>

<style scoped>
.headers {
  font-size: 20px;
}

.item-name {
  text-align: justify;
}

.fal {
  margin-right: 10px;
  font-weight: 400;
}

@media (max-width: 768px) {
}
</style>