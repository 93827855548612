import axios from '../../conf/axios';
// import ApiMethods from '@/conf/config.js'

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fetchLandingKualitas({ state, commit }) {
            commit('setItems', { resource: 'landingKualitas', items: [] }, { root: true })
            return axios.get('/api/landing/kualitasair/search')
                .then(res => {
                    const dataList = res.data.data                    
                    commit('setItems', { resource: 'landingKualitas', items: dataList }, { root: true })
                    return state.items
                })
        }, 
      
              
    }
}