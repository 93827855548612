import axios from '../../conf/axios';

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        createLandingKepuasan(context, payload){
            const headers = {
                'Content-Type': 'application/json',
              }
            return axios.post('/api/landing/add/formkepuasan', 
            payload,{
                headers: headers
              })
              .then(res => res.data)
        },
      
              
    }
}