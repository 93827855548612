<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->

            <div class="title-wrap">
              <h1 class="title is-4">Update Data Konten Informasi</h1>
            </div>
          </div>

          <div class="page-content-inner">
            <!--Form Layout 1-->
            <div class="form-layout">
              <div class="form-outer">
                <div class="form-header stuck-header">
                  <div class="form-header-inner">
                    <div class="left">
                      <h3>Form Input</h3>
                    </div>
                    <div class="right">
                      <div class="buttons">
                        <button
                          @click="$router.go(-1)"
                          class="button h-button is-light is-dark-outlined"
                        >
                          <span class="icon">
                            <i class="lnir lnir-arrow-left rem-100"></i>
                          </span>
                          <span>Batal</span>
                        </button>
                        <button
                          class="button h-button is-primary is-raised"
                          @click.prevent="sendContent"
                          :disabled="isFormInvalid"
                        >
                          Simpan
                        </button>
                          <div class="tombol">
                        <button
                          class="button h-button is-danger is-raised"
                          @click.prevent="hapus"
                          type="is-success"
                          outlined
                        >
                          Hapus
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-body">
                  <!--Fieldset-->
                  <div class="form-fieldset">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <div class="file has-name">
                              <label class="file-label">
                                <input
                                  class="file-input"
                                  id="file"
                                  type="file"
                                  name="file"
                                  @change="uploadFile"
                                />
                                <span class="file-cta">
                                  <span class="file-icon">
                                    <i class="fas fa-upload"></i>
                                  </span>
                                  <span class="file-label"> Pilih file </span>
                                </span>                                
                                <span v-if="file" class="file-name">
                                  {{ file.name }}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Judul</label>

                            <input
                              @blur="$v.form.title.$touch()"
                              v-model="form.title"
                              type="text"
                              class="input"
                              placeholder="Masukan Judul"
                            />
                            <div v-if="$v.form.title.$error">
                              <span
                                v-if="!$v.form.title.required"
                                class="help is-danger"
                                >Judul Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Kategori</label>

                            <div class="select">
                              <select
                                @blur="$v.form.category.$touch()"
                                v-model="form.category"
                                type="text"
                                class="input"
                                placeholder="Masukan Kategori"
                              >
                                <option disabled>Pilih Kategori</option>
                                <option value="data_olahan">Data Olahan</option>
                                <option value="hasil_analisa">
                                  Hasil Analisa
                                </option>
                                <option value="informasi_umum">
                                  Informasi Umum
                                </option>
                              </select>
                              <div v-if="$v.form.category.$error">
                                <span
                                  v-if="!$v.form.category.required"
                                  class="help is-danger"
                                  >Kategori Harus Diisi</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Deskripsi</label>

                            <textarea
                              @blur="$v.form.description.$touch()"
                              v-model="form.description"
                              type="text"
                              class="textarea is-medium"
                              placeholder="Masukan Deskripsi"
                            />
                          </div>
                          <div v-if="$v.form.description.$error">
                            <span
                              v-if="!$v.form.description.required"
                              class="help is-danger"
                              >Deskripsi Harus Diisi</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end form -->
                </div>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";

export default {
  name: "FormUpdateKontenInformasi",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
  },
  data() {
    return {
      file: null,
      form: {
        id: null,
        title: null,
        category: null,
        description: null,
        fileContentId: null
      },
    };
  },
  validations: {
    form: {
      title: { required },
      category: { required },
      description: { required },
    },
  },
  created(){
     this.id = this.$route.params.id;
      this.fetchContentById(this.id).then((resp) => {
        this.isLoaded = true 
        this.form.id = resp.id;
        this.form.title = resp.title;
        this.form.category = resp.category;
        this.form.description = resp.description;
        this.form.fileContentId = resp.fileContent.id;
        
    });
  },
  computed: {
    ...mapState({
      content: (state) => state.content.item,
   }),
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
  },
  methods:{
       ...mapActions("content", ["fetchContentById"]),
       uploadFile(event) { 
      this.file = event.target.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
      this.$store
        .dispatch("contentFile/fileUpload", formData)
        .then((response) => { 
          this.form.fileContentId = response.id;
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },
     sendContent() {
      
     this.$store
        .dispatch("content/updateContent", this.form)
        .then(() => {
          this.$router.push("/dashboard/konteninformasi");
          this.$toasted.success("Rubah Data Cluster Berhasil", {
            duration: 5000,
          });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },
     hapus(){       
      this.$swal({
                  title: "Apakah anda yakin akan menghapus Konten tersebut?!",
                  text: "Setelah dihapus anda tidak dapat mengembalikannya!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#c0392b",
                  confirmButtonText: "YA, HAPUS!"
                }).then((result) => { // <--
                  if (result.value) { // <-- if confirmed
                    this.$store.dispatch("content/deletContentById", this.form.id)
                    this.$router.push('/dashboard/konteninformasi')
                    this.$toasted.success("Hapus Data Konten Berhasil", { duration: 5000 }) 
                    
                  }
              });
 
    },
  }
};
</script>

 <style scoped>
 @media (max-width: 768px) 
{
 .tombol
 {
    margin-top: 10px;
    width: 300px;
 }
}
</style>