import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js' 

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {            
        async fetchPchHidrologiKodeRegister({state,commit}, registerId) {
            commit('setItems', {resource: 'hidrologiCh', items: []}, {root: true})
            return await axiosInstance.get(`/api/hidrologi/search/pch/${registerId}`)
            .then(res => {
                const hidrologi = res.data.kanalList
                commit('setItems', {resource: 'hidrologiCh', items: hidrologi}, {root: true})
                return state.items
                })
        },

    },
    mutations: {

    }
}