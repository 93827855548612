import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js' 

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fetchLevelSungai({state, commit}){
            commit('setItems', {resource: 'level', items: []}, {root: true})
           return axiosInstance.get('/api/level/manage/search')
            .then(res => {
                const level = res.data.levelsiagaList
                commit('setItems', {resource: 'level', items: level}, {root: true})
                return state.items
             })
        },
        createLevelSungai(context, payload){
            const headers = {
                'Content-Type': 'application/json',
              }
            return axiosInstance.post('/api/level/manage/create', 
            payload,{
                headers: headers
              })
              .then(res => res.data)
        },
        fetchLevelById({state, commit}, levelId){
            commit('setItem', {resource: 'level', item: {}}, {root: true})
            return axiosInstance.get(`/api/level/manage/view/${levelId}`)
                .then(res => {
                    const level = res.data                 
                    commit('setItem', {resource: 'level', item: level}, {root: true})
                    return state.item
                 })          
           
        },
        fetchLevelByRegisterId({state, commit}, registerId){    
            commit('setItem', {resource: 'level', item:{}}, {root: true})
            return axiosInstance.get(`/api/level/manage/registerid/${registerId}`)
            .then(res => {
                const level = res.data
                commit('setItem', {resource: 'level', item: level}, {root: true})                
                return state.item
            })
        },
        updateLevel(context, payload){
             
            const headers = {
                'Content-Type': 'application/json',
              }   
                     
                return axiosInstance.put(`/api/level/manage/update/${payload.id}`, payload,
                {
                  headers: headers
                })             
                 
        },
        deleteLevel({state, commit}, payload){
            commit('setItem', {resource: 'level', item: {}}, {root: true})
            return axiosInstance.delete(`/api/level/manage/remove/${payload.id}`)
            .then(() => {
              //   const debit = res.data                 
                commit('setItem', {resource: 'level', item: {}}, {root: true})
                return state.item
             })
             .catch(error=> {
                 console.log(error)   
             })
        }
        
    },
    mutations: {
        mergeLevel (state,  updateLevel) {
              state.item = {...state.item, ... updateLevel}
        },
        } 
}