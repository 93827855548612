 <template>
   <div>
    <DinasSidebarNav />
    <MobileNavbarDash />
    <DinasMobileSidebarDash />
    
      <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->

             
          </div>
          <div class="page-content-inner">

                <!--Form Layout 1-->
                <div class="form-layout">
                    <div class="form-outer">
                        <div class="form-header stuck-header">
                            <div class="form-header-inner">
                                <div class="left">
                                    <h3>Form Perubahan Data</h3>
                                </div>
                                <div class="right">
                                    <div class="buttons">
                                        <button @click="$router.go(-1)"
                                            class="button h-button is-light is-dark-outlined">
                                            <span class="icon">
                                                <i class="lnir lnir-arrow-left rem-100"></i>
                                            </span>
                                            <span>Batal</span>
                                        </button>
                                        <button 
                                          class="button h-button is-primary is-raised"
                                          @click.prevent="updatedPos"
                                          :disabled="isFormInvalid">Simpan</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-body">
                            <!--Fieldset-->
                            <div class="form-fieldset">
                              <div class="columns is-multiline">                                     
                                     <div class="column is-6">
                                        <div class="field">
                                          <label>Jenis Pos</label>
                                            <div class="control">
                                            <select
                                              @blur="$v.form.jenisPos.$touch()"
                                              v-model="form.jenisPos"
                                              type="text"
                                              class="input"
                                              placeholder="Masukan Kategori"
                                            >
                                              <option disabled>Pilih Pos</option>
                                              <option>PDA</option>
                                              <option>PCH</option>
                                            </select>
                                            <div v-if="$v.form.jenisPos.$error">
                                              <span
                                                v-if="!$v.form.jenisPos.required"
                                                class="help is-danger"
                                                >Jenis Pos Harus Diisi</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                     <div class="column is-6">
                                        <div class="field">
                                            <label>Dinas Pengelola</label>
                                            <div class="control has-icon">
                                                <input
                                                @blur="$v.form.dinasPengelola.$touch()"
                                                v-model="form.dinasPengelola"
                                                type="text"
                                                class="input"
                                                placeholder="Dinas PUPR Kab...."
                                              />
                                                
                                                <div v-if="$v.form.dinasPengelola.$error">
                                                <span v-if="!$v.form.dinasPengelola.required" class="help is-danger"
                                                  >Dinas Pengelola Harus Diisi</span
                                                >
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                

                                <div class="columns is-multiline">
                                     <div class="column is-6">
                                        <div class="field">
                                            <label>Nama DAS</label>
                                            <div class="control has-icon">
                                                <b-autocomplete
                                                  v-model="form.namaDas"            
                                                  placeholder="e.g. Cimanuk"
                                                  :keep-first="keepFirst"
                                                  :open-on-focus="openOnFocus"
                                                  :data="filteredDataDas"
                                                  field="namaDas"
                                                  @select="option => option !== null ? form.dasId = option.id : Number()"
                                                  :clearable="clearable" 
                                                  >
                                                    <template #empty >No results for {{form.namaDas}}</template>
                                              </b-autocomplete>
                                                <div class="form-icon">
                                                   <i class="lnil lnil-trees-alt-1"></i>
                                                </div>
                                                  
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column is-6">
                                        <div class="field">
                                            <label>Nama Sungai</label>
                                            <div class="control has-icon">
                                                <b-autocomplete
                                                :data="filteredDataSungai"
                                                v-model="form.namaSungai"                          
                                                placeholder="e.g. Cimanuk"                                                 
                                                @typing="getAsyncData"
                                                :loading="isFetching"
                                                field="namaSungai"
                                                @select="option => option !== null ? form.sungaiId = option.id : Number()"
                                                :clearable="clearable" 
                                                >
                                                      <template #empty >No results for {{form.namaSungai}}</template>
                                                </b-autocomplete>
                                                <div class="form-icon">
                                                     <i class="lnil lnil-air-flow"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <!--Fieldset-->
                            <div class="form-fieldset">
                                <div class="fieldset-heading">
                                    <h4>Informasi Pos</h4>
                                  
                                </div>

                                <div class="columns is-multiline">
                                    <div class="column is-6">
                                        <div class="field">
                                            <label>Nama Pos</label>
                                            <div class="control has-icon">
                                                <input
                                                @blur="$v.form.namaPos.$touch()"
                                                v-model="form.namaPos"
                                                type="text"
                                                class="input"
                                                placeholder="Nama Pos"
                                              />
                                               
                                                <div v-if="$v.form.namaPos.$error">
                                                <span v-if="!$v.form.namaPos.required" class="help is-danger"
                                                  >Nama Pos Harus Diisi</span
                                                >
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column is-6">
                                        <div class="field">
                                            <label>Kode Register</label>
                                            <div class="control">
                                                 <input
                                                @blur="$v.form.kodeRegister.$touch()"
                                                v-model="form.kodeRegister"
                                                type="text"
                                                class="input"
                                                placeholder="Register"
                                                disabled
                                              />
                                               
                                        </div>
                                    </div>
                                    </div>
                                    <div class="column is-6">
                                        <div class="field">
                                            <label>Latitude</label>
                                            <div class="control">
                                                <input
                                                  @blur="$v.form.lat.$touch()"
                                                  v-model="form.lat"
                                                  type="number"
                                                  class="input"
                                                  placeholder="Latitude"
                                                />
                                                <div v-if="$v.form.lat.$error">
                                                  <span v-if="!$v.form.lat.required" class="help is-danger"
                                                    >Latitude Harus Diisi</span
                                                  >
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="column is-6">
                                        <div class="field">
                                            <label>Longitude</label>
                                            <div class="control">
                                                 <input
                                                @blur="$v.form.lng.$touch()"
                                                v-model="form.lng"
                                                type="number"
                                                class="input"
                                                placeholder="Longitude"
                                              />
                                              <div v-if="$v.form.lng.$error">
                                                <span v-if="!$v.form.lng.required" class="help is-danger"
                                                  >Longitude Harus Diisi</span
                                                >
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column is-6">
                                        <div class="field">
                                            <label>Elevasi</label>
                                            <div class="control">
                                                 <input
                                                  @blur="$v.form.elevasi.$touch()"
                                                  v-model="form.elevasi"
                                                  type="number"
                                                  class="input"
                                                  placeholder="elevasi"
                                                />
                                                <div v-if="$v.form.elevasi.$error">
                                                  <span v-if="!$v.form.elevasi.required" class="help is-danger"
                                                    >elevasi Harus Diisi</span
                                                  >
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column is-6">
                                        <div class="field">
                                            <label>Lokasi Pos</label>
                                            <div class="control">
                                                 <textarea
                                                  @blur="$v.form.lokasiPos.$touch()"
                                                  v-model="form.lokasiPos"
                                                  type="text"
                                                  class="textarea is-normal"
                                                  placeholder="Lokasi Pos"
                                                />
                                                <div v-if="$v.form.lokasiPos.$error">
                                                  <span
                                                    v-if="!$v.form.lokasiPos.required"
                                                    class="help is-danger"
                                                    >lokasi Pos Harus Diisi</span
                                                  >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     
                                </div>
                            </div>
                            <!--Fieldset-->
                             
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
      </div>
    </div>
   </div>
</template>


<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import DinasSidebarNav from "../dashboardnav/DinasSidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import DinasMobileSidebarDash from "../dashboardnav/DinasMobileSidebarDash.vue";
// import AppSpinner from "../shared/AppSpinner.vue"  
import debounce from 'lodash/debounce'

export default {
  name: "FormUpdatePos",
  components: { 
    DinasSidebarNav,
    MobileNavbarDash,
    DinasMobileSidebarDash,
    // AppSpinner 

  },

  data() {
    return {
      sungaiData: [],
      dasData: [],
       form: { 
        namaPos: null,
        kodeRegister: null,
        lat: null,
        lng: null,
        elevasi: null,
        lokasiPos: null,
        dasId: null,
        namaDas: null,
        sungaiId: null,
        namaSungai: null,
        jenisPos: null,
        dinasPengelola: null,
      }, 
      keepFirst: false,
      openOnFocus: false,
       
      selected: null,
      clearable: false,
      isFetching: false
       
    };
  },
  validations: {
    form: {
      namaPos: { required },
      lat: { required },
      lng: { required },
      elevasi: { required },
      lokasiPos: { required },
      jenisPos: {required },
      dinasPengelola: {required}
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
    ...mapState({
      sungaiList: (state) => state.sungai.items,
      images: (state) => state.image.items,
      das: (state) => state.das.items
    }),
    filteredDataDas() {
       return this.das.filter(option => {
           return (
                    option.namaDas
                        .toString()
                        .indexOf(this.form.namaDas) >= 0
                )
            })
    },
    filteredDataSungai() {
       return this.sungaiData.filter(option => {
           return (
                    option.namaSungai
                        .toString()
                        .indexOf(this.form.namaSungai) >= 0
                )
            })
    },
    
    
  },
  created() {
     this.fetchSungai(); 
     this.fetchDas();
     this.fetchPosDinas().then((res) => {       
      this.form = res;            
    }); 
     

  },
  methods: {
    
    ...mapActions("posKedinasan", ["fetchPosDinas", "updatePos"]), 
    ...mapActions("sungai", ["fetchSungai", "searchDas"]),
    ...mapActions("image", ["fetchImage"]),
    ...mapActions("das", ["fetchDas"]),
    // ini gambar idnya
    getGambar: function (fileName) {
      this.form.gambar = fileName;
      
    },
    updatedPos() {
      this.updatePos(this.form)
      .then(() => {
            this.$toasted.success('Rubah Data Pos Berhasil', {duration: 3000})
            this.$router.push(`/dashboard/posdinas/${this.registerId}`)
          })
      .catch(error => {
        
        this.$toasted.error(error.response.data.message, {duration: 3000})
      })

    },
    deletPos(){
       this.$swal({
                  title: "Apakah anda yakin akan menghapus Data Pos tersebut?!",
                  text: "Mohon pastikan kembali!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#c0392b",
                  confirmButtonText: "YA, HAPUS!",
                  cancelButtonText: "Batal!"
                }).then((result) => { // <--
                  if (result.value) { // <-- if confirmed
                    this.$router.push('/kadaster')                   
                    this.$store.dispatch("kadaster/deleteKadasterById", this.registerId)
                    this.$toasted.success("Hapus Data Kadaster Berhasil", { duration: 5000 });  
                    this.$emit('kadasterRemoveSubmitted', this.registerId)
                  }
              });
       
    },

    dasAsyncData: debounce(function (namaDas) { 
      if (!namaDas.length) {
          this.dasData = []
          return          
       }
       this.isFetching = true 
        
     this.fetchDas()
                    .then((response) => {
                        this.dasData = []
                         response.forEach((item) => {
                          this.isFetching = true;
                          this.dasData.push(item);
                        });
                    })
                    .catch((error) => {
                        this.dasData = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500),

    getAsyncData: debounce(function (dasId) { 
      if (!dasId.length) {
          this.sungaiData = []
          return          
       }
       this.isFetching = true 
       const params = [
        `dasId=${this.form.dasId}`
      ].join("&");

     this.searchDas(params)
                    .then((response) => {
                        this.sungaiData = []
                         response.sungai.forEach((item) => {
                          this.isFetching = true;
                          this.sungaiData.push(item);
                        });
                    })
                    .catch((error) => {
                        this.sungaiData = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500)


  },
  
};
</script>

 