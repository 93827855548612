import axios from '../../conf/axios';

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {        
        getGambar({state, commit}, registerId){
            commit('setItem', {resource: 'landingImage', item: {}}, {root: true})
            return axios.get(`/api/landing/kadaster/view/${registerId}`)
            .then(res => {
                const filename = res.data.gambar
                const urlImg = `/api/landing/kadaster/gambar/download/${filename}`                
                commit('setItem', {resource: 'landingImage', item: urlImg}, {root: true})                              
                return state.item                
             })
             
        }      
    },
    mutations: {

    }
}