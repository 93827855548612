import axiosInstance from '../../conf/axiosAdmin'; 


export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fileUpload(context, payload) {
            const headers = {
                'Content-Type': 'multipart/form-data',
            }
            return axiosInstance.post('/api/content/file/upload',
                payload, {
                headers: headers
            })
                .then(res => res.data)
        },
        
    },
    mutations: {

    }
}