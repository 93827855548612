<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->

            <div class="title-wrap">
              <h1 class="title is-4">Update Data Pengumuman</h1>
            </div>
          </div>

          <div class="page-content-inner">
            <!--Form Layout 1-->
            <div class="form-layout">
              <div class="form-outer">
                <div class="form-header stuck-header">
                  <div class="form-header-inner">
                    <div class="left">
                      <h3>Form Input Data Pengumuman</h3>
                    </div>
                    <div class="right">
                      <div class="buttons">
                        <button
                          @click="$router.go(-1)"
                          class="button h-button is-light is-dark-outlined"
                        >
                          <span class="icon">
                            <i class="lnir lnir-arrow-left rem-100"></i>
                          </span>
                          <span>Batal</span>
                        </button>
                        <button
                          class="button h-button is-primary is-raised"
                          @click.prevent="sendUpdate"
                          :disabled="isFormInvalid"
                        >
                          Simpan
                        </button>

                        <!-- <button
                          v-if="form.isActive === true"
                          class="button h-button is-primary is-raised"
                          @click.prevent="aktif"
                          type="is-success"
                          outlined
                        >
                          Tidak Aktif
                        </button>
                        <button
                          v-else
                          class="button h-button is-danger is-raised"
                          @click.prevent="aktif"
                          type="is-success"
                          outlined
                        >
                          Aktif
                        </button> -->

                        <button
                          class="button h-button is-danger is-raised"
                          @click.prevent="hapus"
                          type="is-success"
                          outlined
                        >
                          Hapus
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-body">
                  <!--Fieldset-->
                  <div class="form-fieldset">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Judul</label>
                            <input
                              @blur="$v.form.title.$touch()"
                              v-model="form.title"
                              type="text"
                              class="input"
                              placeholder="Masukan Judul"
                            />
                            <div v-if="$v.form.title.$error">
                              <span
                                v-if="!$v.form.title.required"
                                class="help is-danger"
                                >Judul Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label"></label>

                            <div class="quill">
                              <!-- Two-way Data-Binding -->
                              <quill-editor
                                ref="myQuillEditor"
                                v-model="form.body"
                                :options="editorOption"
                                @blur="onEditorBlur($event)"
                                @focus="onEditorFocus($event)"
                                @ready="onEditorReady($event)"
                              />
                              <div v-if="$v.form.body.$error">
                                <span
                                  v-if="!$v.form.body.required"
                                  class="help is-danger"
                                  >Text Harus Diisi</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
<!-- 
                      <div class="column is-12">
                        <div class="field tombol">
                          <div class="control status">
                            <label class="label">Status Active</label>                            
                            <b-switch
                              type="is-success"
                              passive-type="is-danger"
                              size="is-medium"
                              v-model="form.isActive"
                              @input="buttonActive"
                            >
                            </b-switch>                         
                          </div>
                          
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <!-- end form -->
              </div>
            </div>
          </div>
          <!-- end -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { mapActions, mapState } from "vuex";
import { quillEditor } from "quill-vuejs";
import { required } from "vuelidate/lib/validators";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";

export default {
  name: "FormInputPengumuman",
  components: {
    quillEditor,
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
  },
  data() {
    return {
      editorOption: {
        debug: "info",
        placeholder: "Masukan Text...",
        theme: "snow",
      },
      form: {
        id: null,
        title: null,
        body: null,
        isActive: false,
      },
    };
  },
  validations: {
    form: {
      title: { required },
      body: { required },
    },
  },
  computed: {
    ...mapState({
      annoucements: (state) => state.pengumuman.items,
    }),

    isFormInvalid() {
      return this.$v.form.$invalid;
    },
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchPengumumanById(this.id).then((resp) => {
      this.form = resp;
    });
  },
  methods: {
    ...mapActions("pengumuman", [
      "fetchPengumuman",
      "updatePenguman",
      "deletPengumumanById",
      "fetchPengumumanById",
      "deactivePengumumanById",
    ]),
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    sendUpdate() {
      this.updatePenguman(this.form)
        .then(() => {
          this.$toasted.success("Rubah Data Pengumuman Berhasil", {
            duration: 3000,
          });
          this.$router.push("/dashboard/pengumuman");
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 3000 });
        });
    },
    hapus() {
      this.$swal({
        title: "Apakah anda yakin akan menghapus Info Pengumuman tersebut?!",
        text: "Setelah dihapus anda tidak dapat mengembalikannya!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#c0392b",
        confirmButtonText: "YA, HAPUS!",
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if confirmed
          this.$store.dispatch("pengumuman/deletPengumumanById", this.id);
          this.$router.push("/dashboard/pengumuman");
          this.$toasted.success("Hapus Data Info Pengumuman Berhasil", {
            duration: 3000,
          });
        }
      });
    },
    buttonActive() {
      const buttonActive = { id: this.form.id, isActive: this.form.isActive };
      this.$store.dispatch("pengumuman/deactivePengumumanById", buttonActive);
      // console.log(buttonActive);
    },
  },

  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
};
</script>

 <style>
.ql-editor {
  height: 400px;
}
.tombol {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
}
.status {
  width: 500px;
}
.switch {
  width: 120px;
  margin-top: 15px;
  margin-left: 20px;
}
</style>