<template>
   <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
      <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->

            <div class="title-wrap">
              <h1 class="title is-4">Data DAS</h1>
            </div>
          </div>
 
          <div class="page-content-inner">
              <!--Form Layout 1-->
                <div class="form-layout">
                    <div class="form-outer">
                        <div class="form-header stuck-header">
                            <div class="form-header-inner">
                                <div class="left">
                                    <h3>Form Input</h3>
                                </div>
                                <div class="right">
                                    <div class="buttons">
                                        <button @click="$router.go(-1)"
                                            class="button h-button is-light is-dark-outlined">
                                            <span class="icon">
                                                <i class="lnir lnir-arrow-left rem-100"></i>
                                            </span>
                                            <span>Batal</span>
                                        </button>
                                        <button 
                                          class="button h-button is-primary is-raised"
                                          @click.prevent="sendDas"
                                          :disabled="isFormInvalid">Simpan</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-body">
                            <!--Fieldset-->
                            <div class="form-fieldset"> 
                                <div class="columns is-multiline">
                                    <div class="column is-12">
                                        <div class="field">
                                             <label>Nama DAS</label>
                                            <div class="control has-icon">
                                                 <input
                                                  @blur="$v.form.namaDas.$touch()"
                                                  v-model="form.namaDas"
                                                  type="text"
                                                  class="input"
                                                  placeholder="Nama DAS"
                                                />
                                                <div class="form-icon">
                                                    <i class="lnil lnil-air-flow"></i>
                                                </div>
                                                <div v-if="$v.form.namaDas.$error">
                                                  <span v-if="!$v.form.namaDas.required" class="help is-danger"
                                                    >Nama DAS Harus Diisi</span
                                                  >
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <!-- end form -->
                        </div>
                    </div>
                </div>
                <!-- end -->

          </div>
        </div>
      </div>
    </div>
   </div>
</template>


<script>
import { required } from "vuelidate/lib/validators";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
export default {
  name: "FormInputDas",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
  },
  data() {
    return {
      form: {
        namaDas: null, 
      },
    };
  },
  validations: {
    form: {
      namaDas: { required }, 
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
  },
  
  methods: {    
    sendDas() {
      this.$store.dispatch("das/createDas", this.form)
      .then(() => this.$router.push('/dashboard/das'))
      this.$toasted.success("Tambah Data DAS Berhasil", { duration: 5000 });
    },
  },
};
</script>

 