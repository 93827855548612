<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />

    <!-- Content Wrapper -->
    <div
      class="view-wrapper"
      data-naver-offset="214"
      data-menu-item="#layouts-sidebar-menu"
      data-mobile-item="#home-sidebar-menu-mobile"
    >
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->

            <div class="title-wrap">
              <h1 class="title is-4">Device</h1>
            </div>
          </div>

          <div class="page-content-inner">
            <!--Invoice-->
            <div class="invoice-wrapper">
              <div class="invoice-header">
                <div class="left">
                  <h3>{{ device.serialNumber }}</h3>
                </div>
              </div>
              <div class="invoice-body">
                <div class="invoice-card">
                  <div class="invoice-section">
                    <div v-if="isLoaded" class="flex-table">
                      <!--Table item-->
                      <div class="flex-table-item">
                        <div class="flex-table-cell is-grow" data-th="">
                          <span class="dark-text">Jenis Pos</span>
                        </div>

                        <div class="flex-table-cell has-text-right" data-th="">
                          <span class="dark-inverted">
                            {{ device.jenisDevice }}</span
                          >
                        </div>
                      </div>

                      <!--Table item-->
                      <div class="flex-table-item">
                        <div class="flex-table-cell is-grow" data-th="">
                          <span class="dark-text">Status</span>
                        </div>

                        <div class="flex-table-cell has-text-right" data-th="">
                          <span class="dark-inverted">{{
                            device.status
                          }}</span>
                        </div>
                      </div>

                      <!--Table item-->
                      <div class="flex-table-item">
                        <div class="flex-table-cell is-grow" data-th="">
                          <span class="dark-text">Nama Pos</span>
                        </div>

                        <div class="flex-table-cell has-text-right" data-th="">
                          <span class="dark-inverted">{{
                            device.namaPos ? device.namaPos : "-"
                          }}</span>
                        </div>
                      </div>

                      <!--Table item-->
                      <div class="flex-table-item">
                        <div class="flex-table-cell is-grow" data-th="">
                          <span class="dark-text">Tanggal Registrasi</span>
                        </div>

                        <div class="flex-table-cell has-text-right" data-th="">
                          <span class="dark-inverted">{{
                            device.timestamp | formatDate
                          }}</span>
                        </div>
                      </div>
                        
                    </div>
                    <div v-else>
                       <AppSpinner/>
                    </div>
                    <div class="flex-table sub-table">
                      <!--Table item-->
                      <div class="flex-table-item">
                         
                         <RegisterDeviceModal 
                             :deviceId="device.serialNumber"
                             :status="device.status"
                          />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
import AppSpinner from '../shared/AppSpinner.vue'
import RegisterDeviceModal from './RegisterDeviceModal.vue'
export default {
  name: "DeviceDetail",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    AppSpinner,
    RegisterDeviceModal
  },
  data() {
    return {
      isLoaded: false,
      serialNumber: "", 
    };
  },
  computed: {
    ...mapState({
      device: state => state.device.item,
    }),
  },
  created() {
    this.serialNumber = this.$route.params.serialNumber;
    this.fetchDeviceBySn(this.serialNumber).then(() => { 
      this.isLoaded = true;
    });
  },
  methods: {
    ...mapActions("device", ["fetchDeviceBySn"]),
  },
};
</script>