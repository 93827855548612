import axiosInstance from '../../conf/axiosAdmin'; 


export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
      async  getKadasters({state, commit}){
            commit('setItems', {resource: 'kadasters', items: []}, {root: true})
            return await axiosInstance.get('/api/kadaster/manage/search')
                .then(res => {
                    const kadaster = res.data.kadaster
                    commit('setItems', {resource: 'kadasters', items: kadaster}, {root: true})
                    return state.items
                })
        },
      async  fetchKadasters({state, commit}){
            commit('setItems', {resource: 'kadasters', items: []}, {root: true})
            return await axiosInstance.get('/api/kadaster/manage/search')
                .then(res => {
                    const kadaster = res.data 
                    commit('setItems', {resource: 'kadasters', items: kadaster}, {root: true})
                    return state.items
                })
        },
         createKadaster({commit, state}, payload){
           
            return axiosInstance.post('/api/kadaster/manage/create', payload)
            .then(res => {
                const createdKadaster = res.data
                const index = state.items.length
      
                commit('addItemToArray', {item: createdKadaster, index, resource: 'kadasters'}, {root: true})
                return createdKadaster
              })


        },
      async  fetchKadasterById({state, commit}, registerId){            
            commit('setItem', {resource: 'kadasters', item: {}}, {root: true})
            return await axiosInstance.get(`/api/kadaster/manage/view/${registerId}`)
            .then(res => {
                const kadaster = res.data               
                commit('setItem', {resource: 'kadasters', item: kadaster}, {root: true})
                return state.item
             })
             .catch(()=>{
                  
             });
        },
      async  searchKadaster({state, commit}, params){           
            commit('setItems', {resource: 'kadasters', items: []}, {root: true})
           return await axiosInstance.get(`/api/kadaster/manage/search?${params}`)
            .then(res => {
                const results = res.data                             
                commit('setItems', {resource: 'kadasters', items: results}, {root: true})
                return state.items
             })
            },
        updateKadaster(context, payload){
            
            const headers = {
                'Content-Type': 'application/json',
              }          
                return axiosInstance.put(`/api/kadaster/manage/update/${payload.registerId}`, payload,
                {
                  headers: headers
                })             
                 
        },
        deleteKadasterById({state, commit}, id){                            
                commit('setItem', {resource: 'kadasters', item: {}}, {root: true})
                return axiosInstance.delete(`/api/kadaster/manage/remove/${id}`)
                .then(res => {
                    const kadaster = res.data                 
                    commit('setItem', {resource: 'kadasters', item: kadaster}, {root: true}) 
                    commit('removeItemFromArray', {resource: 'kadasters', registerId: id},{root: true}); 
                    return state.item
                 })
                 
            },
        
        
    },
    mutations: {

    }
}