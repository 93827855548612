
import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js';


export default {
    namespaced: true,
    // in state we are keeping out data and sharing with components
    state: {
        items: [],
        item: {},
    },
    // as computed Properties in Vuex to get a state
    getters: {

    },
    // as method in vue
    actions: {
      fetchDevice({state, commit}){
        commit('setItems', {resource: 'device', items: []}, {root: true})
       return axiosInstance.get('/api/device/manage/search')
        .then(res => {
            const devices = res.data.devices
                           
            commit('setItems', {resource: 'device', items: devices}, {root: true})
            return state.items
         })
       },
       createDevice({commit,state}, payload){
           commit('setItems', {resource: 'device', items: []}, {root: true})
            return axiosInstance.post('/api/device/manage/register',payload)
              .then(res => {
                res.data
                let index = state.items.length   
                commit('addItemToArray', {item: payload, index, resource: 'device'}, {root: true})             
                return state.items
              })
           
        },
        unregisterDevice({dispatch}, serialNumber){
            const headers = {
                'Content-Type': 'application/json',
              }
            return axiosInstance.get(`/api/device/manage/unreg/${serialNumber}`, { headers: headers })
              .then(res => {
                res.data
                dispatch('dispatchDevice', serialNumber)
              })
        },
        addDevice({commit,state}, device) {
          const devices = state.items           
          commit('setItems', {resource: 'device', items: [...devices, device]}, {root: true})
          return state.items
        },
        searchDevice({state, commit}, params){
          commit('setItems', {resource: 'device', items: []}, {root: true})
           return axiosInstance.get(`/api/device/manage/search?${params}`)
            .then(res => {
                const results = res.data                
                commit('setItems', {resource: 'device', items: results}, {root: true})
                return state.items
             })
        },
        fetchDeviceBySn({state, commit}, serialNumber){            
          commit('setItem', {resource: 'device', item: {}}, {root: true})
          return axiosInstance.get(`/api/device/manage/view/${serialNumber}`)
          .then(res => {
              const device = res.data                              
              commit('setItem', {resource: 'device', item: device}, {root: true})
              return state.item
           })
        },
        dispatchDevice({state, commit}, serialNumber){        
          commit('removeItemFromArray', {resource: 'device', registerId: serialNumber},{root: true}); 
          return state.items
        },
        postDevice({state, commit}, payload){            
          let index = state.items.length        
          commit('addItemToArray', {item: payload, index, resource: 'device'}, {root: true})             
          return state.items
        },
    },
    mutations: {

    }
}