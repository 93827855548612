import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store' 
import Home from '../views/Home' 
import KualitasAir from '../views/KualitasAir.vue'
import KontenInfo from '../views/KontenInfo' 
import Auth from '../components/landing/Auth'
import Profile from '../components/dashboard/Profile'
import DeviceList from '../components/dashboard/DeviceList'
import DeviceDetail from '../components/device/DeviceDetail'
import PosKadasterList from '../components/dashboard/PosKadasterList'
import PosDetail from '../components/pos/PosDetail'
import KualitasAirList from '../components/dashboard/KualitasAirList'
import SungaiList from '../components/dashboard/SungaiList';
import DasList from '../components/dashboard/DasList';
import KontenInfoList from '../components/dashboard/KontenInfoList' 

import ForecastingList from '../components/dashboard/ForecastingList'
import FormInputKadaster from '../components/pos/FormInputKadaster';
import FormUpdateKadaster from '../components/pos/FormUpdateKadaster'

import FormInputKualitasAir from '../components/kualitasair/FormInputKualitasAir'
import FormUpdateKualitasAir from '../components/kualitasair/FormUpdateKualitasAir'

import FormInputSungai from '../components/sungai/FormInputSungai'
import FormUpdateSungai from '../components/sungai/FormUpdateSungai'

import FormInputDas from '../components/das/FormInputDas'
import FormUpdateDas from '../components/das/FormUpdateDas'

import FormInputKontenInformasi from '../components/konteninfo/FormInputKontenInformasi'
import FormUpdateKontenInformasi from '../components/konteninfo/FormUpdateKontenInformasi'

import PageNotAuthenticated from '../views/PageNotAuthenticated'
import PageNotFound from '../views/PageNotFound'

// tambahan baru

import PageInfoUmum from '../components/landing/PageInfoUmum'
import PageDataOlahan from '../components/landing/PageDataOlahan'
import PageInformasiAnalisa from '../components/landing/PageInformasiAnalisa' 
import LandingPdfPratinjau from '../components/landing/LandingPdfPratinjau'

import FormInputForecasting from '../components/forecasting/FormInputForecasting'
import FormUpdateForecasting from '../components/forecasting/FormUpdateForecasting'
// end

import TelemetryPdaSearchData from '../components/landing/TelemetryPdaSearchData'
import TelemetryPchSearchData from '../components/landing/TelemetryPchSearchData'
import HidrologiPdaSearchData from '../components/landing/HidrologiPdaSearchData'
import HidrologiPchSearchData from '../components/landing/HidrologiPchSearchData'


import ClusterList from '../components/dashboard/ClusterList'
import FormInputCluster from '../components/cluster/FormInputCluster'
import FormUpdateCluster from '../components/cluster/FormUpdateCluster'
import PosDinasDetail from '../components/posDinas/PosDinasDetail'
import PosDinasList from '../components/dashboard/PosDinasList'
import FormInputPos from '../components/posDinas/FormInputPos' 
import FormUpdatePos from '../components/posDinas/FormUpdatePos'

import PosDinasLanding from '../views/PosDinasLanding.vue'

import UsersList from '../components/dashboard/UsersList'
import FormInputUser from '../components/user/FormInputUser'
import FormUpdateUser from '../components/user/FormUpdateUser'

import PosKedinasanDetail from '../components/kedinasan/PosKedinasanDetail'
import FormUpdatePosDinas from '../components/kedinasan/FormUpdatePosDinas'


import CsvFileList from '../components/dashboard/CsvFileList' 
import PengumumanList from '../components/dashboard/PengumumanList'
import FormInputPengumuman from '../components/pengumuman/FormInputPengumuman'
import UpdateFormPengumuman from '../components/pengumuman/UpdateFormPengumuman'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: Home
  },
  {
    path: '/posdinas',
    name: 'PosDinasLanding',
    component: PosDinasLanding 
  },   
  {
    path: '/kualitasair',
    name: 'KualitasAir',
    component: KualitasAir 
  },
  {
    path: '/konteninfo',
    name: 'KontenInfo',
    component: KontenInfo
  },
  {
    path: '/konteninfo/informasiumum',
    name: 'PageInfoUmum',
    component: PageInfoUmum
  },
  {
    path: '/konteninfo/informasianalisa',
    name: 'PageInformasiAnalisa',
    component: PageInformasiAnalisa
  },
  {
    path: '/konteninfo/dataolahan',
    name: 'PageDataOlahan',
    component: PageDataOlahan
  },
  {
    path: '/konteninfo/pratinjau/:filename',
    name: 'pdfpratinjau',
    component: LandingPdfPratinjau,
    // meta: {onlyAuthUser: true}
  }, 
  {
    path: '/caridata/telemetry/pda/:registerId',
    name: 'SearchDataTelemetryPda',
    component: TelemetryPdaSearchData,
  },
  {
    path: '/caridata/telemetry/pch/:registerId',
    name: 'SearchDataTelemetryPch',
    component: TelemetryPchSearchData,
  },
  {
    path: '/caridata/hidrologi/pda/:registerId',
    name: 'SearchDataHidrologiPda',
    component: HidrologiPdaSearchData,
  },
  {
    path: '/caridata/hidrologi/pch/:registerId',
    name: 'SearchDataHidrologiPch',
    component: HidrologiPchSearchData,
  },
  {
    path: '/login',
    name: 'Auth',
    component: Auth,
    meta: {onlyGuestUser: true}
  },
  {
    path: '/dashboard/profile',
    name: 'Profile',
    component: Profile,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/users',
    name: 'UsersList',
    component: UsersList,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/inputuser',
    name: 'FormInputUser',
    component: FormInputUser,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/users/:credential',
    name: 'FormUpdateUser',
    component: FormUpdateUser,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/device',
    name: 'Device',
    component: DeviceList,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/device/:serialNumber',
    name: 'DeviceDetail',
    component: DeviceDetail,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/pos',
    name: 'Pos',
    component: PosKadasterList,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/posdinas',
    name: 'posDinasList',
    component: PosDinasList,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/posdinas/input',
    name: 'FormInputPos',
    component: FormInputPos,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/posdinas/:registerId',
    name: 'PosDinasDetail',
    component: PosDinasDetail,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/posdinas/update/:registerId',
    name: 'FormUpdatePos',
    component: FormUpdatePos,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/pos/input',
    name: 'inputKadaster',
    component: FormInputKadaster,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/pos/update/:registerId',
    name: 'updateKadaster',
    component: FormUpdateKadaster,
    meta: {onlyAuthUser: true}
  },   
  {
    path: '/dashboard/pos/:registerId',
    name: 'PosDetail',
    component: PosDetail,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/kualitasair',
    name: 'KualitasAirList',
    component: KualitasAirList,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/inputkualitasair',
    name: 'FormInputKualitasAir',
    component: FormInputKualitasAir,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/kualitasair/:id',
    name: 'FormUpdateKualitasAir',
    component: FormUpdateKualitasAir,
    meta: {onlyAuthUser: true}
  },
  
  {
    path: '/dashboard/sungai',
    name: 'SungaiList',
    component: SungaiList,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/das',
    name: 'DasList',
    component: DasList,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/inputdas',
    name: 'FormInputDas',
    component: FormInputDas,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/das/:id',
    name: 'FormUpdateDas',
    component: FormUpdateDas,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/inputsungai',
    name: 'FormInputSungai',
    component: FormInputSungai,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/sungai/:id',
    name: 'FormUpdateSungai',
    component: FormUpdateSungai,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/konteninformasi',
    name: 'KontenInfoList',
    component: KontenInfoList,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/inputkonteninformasi',
    name: 'InputKontenInformasi',
    component: FormInputKontenInformasi,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/konteninformasi/:id',
    name: 'UpdateKontenInformasi',
    component: FormUpdateKontenInformasi,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/forecasting',
    name: 'ForecastingList',
    component: ForecastingList,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/inputforecasting',
    name: 'inputForecasting',
    component: FormInputForecasting,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/updateforecasting/:id',
    name: 'updateForecasting',
    component: FormUpdateForecasting,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/cluster',
    name: 'ClusterList',
    component: ClusterList,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/inputcluster',
    name: 'FormInputCluster',
    component: FormInputCluster,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/updatecluster/:id',
    name: 'FormUpdateCluster',
    component: FormUpdateCluster,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/dinas',
    name: 'PosKedinasanDetail',
    component: PosKedinasanDetail,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/dinas/update',
    name: 'FormUpdatePosDinas',
    component: FormUpdatePosDinas,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/csvfile',
    name: 'CsvFileList',
    component: CsvFileList,
    meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/pengumuman',
    name: 'PengumumanList',
    component: PengumumanList,
    meta: {onlyAuthUser: true}
  },
  {
  path: '/dashboard/inputpengumuman',
  name: 'inputPengumuman',
  component: FormInputPengumuman,
  meta: {onlyAuthUser: true}
  },
  {
    path: '/dashboard/updatepengumuman/:id',
    name: 'updatePengumuman',
    component: UpdateFormPengumuman,
    meta: {onlyAuthUser: true}
    },
  {
    path:'/401',
    name: 'PageNotAuthenticated',
    component: PageNotAuthenticated
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound
  },
 
]

const router = new VueRouter({
  linkActiveClass: "is-info",
  linkExactActiveClass: "exact-active",
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
    store.dispatch('auth/getAuthUser')
    .then(() => {
      const isAuthenticated = store.getters['auth/isAuthenticated']

      if (to.meta.onlyAuthUser) {
        if (isAuthenticated) {
          next()
        } else {
          next({name: 'PageNotAuthenticated'})
        }
      } else if (to.meta.onlyGuestUser) {
        if (isAuthenticated) {
          next({name: 'LandingPage'})
        } else {
          next()
        }
      } else {
        next()
      }
    })
  
})

export default router
