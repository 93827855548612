import axios from '../../conf/axios';

export default {
    namespaced: true,
    state: {
        items: [],
        item: {
            id: null,
            uratSungai: null
        }
    },
    getters: {

    },
    actions: { 

        fileDownlaod({state, commit}, payload){
            commit('setItem', {resource: 'clusterFile', item: {}}, {root: true})
            return axios.get(`/api/landing/cluster/file/download/${payload.uratSungai}`)
             .then(res => {
                 const filename = res.data
                 state.item.id = payload.id
                 state.item.uratSungai = filename
                 commit('setItem', {resource: 'landingClusterFile', item: state.item}, {root: true})
                 return state.item
              })
        }
        
    },
    mutations: {

    }
}