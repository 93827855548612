<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->

            <div class="title-wrap">
              <h1 class="title is-4">Input Forecasting</h1>
            </div>
          </div>

          <div class="page-content-inner">
            <!--Form Layout 1-->
            <div class="form-layout">
              <div class="form-outer">
                <div class="form-header stuck-header">
                  <div class="form-header-inner">
                    <div class="left">
                      <h3>Detail Forecasting</h3>
                    </div>
                    <div class="right">
                      <div class="buttons">
                        <button
                          @click="$router.go(-1)"
                          class="button h-button is-light is-dark-outlined"
                        >
                          <span class="icon">
                            <i class="lnir lnir-arrow-left rem-100"></i>
                          </span>
                          <span>Batal</span>
                        </button>
                        <button
                          class="button h-button is-warning is-raised"
                          @click.prevent="deactiveForecasting"
                          :disabled="!isActived"
                        >
                          Deactive
                        </button>
                         <button
                          class="button h-button is-danger is-raised"
                          @click.prevent="hapusForecasting"
                          
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-body">
                  <!--Fieldset-->
                 <div class="invoice-wrapper">
              <div class="invoice-header">
                <div class="left">
                  <h3> </h3>
                </div>
              </div>
              <div class="invoice-body">
                <div class="invoice-card">
                  <div class="invoice-section">
                    <div v-if="isLoaded" class="flex-table">
                      <!--Table item-->
                      <div class="flex-table-item">
                        <div class="flex-table-cell is-grow" data-th="">
                          <span class="dark-text">Start Show Periode</span>
                        </div>

                        <div class="flex-table-cell has-text-right" data-th="">
                          <span class="dark-inverted">{{ forecasting.startShowPeriod}}
                            </span
                          >
                        </div>
                      </div>

                      <!--Table item-->
                      <div class="flex-table-item">
                        <div class="flex-table-cell is-grow" data-th="">
                          <span class="dark-text">End Show Period</span>
                        </div>

                        <div class="flex-table-cell has-text-right" data-th="">
                          <span class="dark-inverted">{{ forecasting.endShowPeriod}}</span>
                        </div>
                      </div>

                      <!--Table item-->
                      <div class="flex-table-item">
                        <div class="flex-table-cell is-grow" data-th="">
                          <span class="dark-text">Status</span>
                        </div>

                        <div class="flex-table-cell has-text-right" data-th="">
                          <span class="tag is-success" v-if="forecasting.isActive">
                                    {{forecasting.isActive ? 'AKTIF' : 'TIDAK AKTIF' }}
                           </span>
                                <span class="tag is-danger" v-else>
                                    {{ forecasting.isActive ? 'AKTIF' : 'TIDAK AKTIF' }}
                                </span>
                        </div>
                      </div>

                      <!--Table item-->
                      <div class="flex-table-item">
                        <div class="flex-table-cell is-grow" data-th="">
                          <span class="dark-text">Nama File</span>
                        </div>

                        <div class="flex-table-cell has-text-right" data-th="">
                          <span class="dark-inverted">{{forecasting.forecastFile.oriFileName}} </span>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                       <AppSpinner/>
                    </div>
                    <div class="flex-table sub-table">
                      <!--Table item-->
                      <div class="flex-table-item">
                         
                         
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
                  <!-- end form -->
                </div>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
import AppSpinner from '../shared/AppSpinner.vue'
export default {
  name: "FormUpdateForecasting",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    AppSpinner
   
  },
  data() {
    return {
      id: null,
      isLoaded: false,
      isActived: false
    };
  },
   
  created(){
     this.id = this.$route.params.id;
      this.fetchForecastById(this.id).then((resp) => {
        this.isLoaded = true
        this.isActived = resp.isActive
        this.forecasting = resp;
    });
  },
  computed: {
   ...mapState({
      forecasting: (state) => state.forecasting.item,
   }),
    
  },
  methods: {
   ...mapActions("forecasting", ["fetchForecastById"]),
  hapusForecasting(){       
      this.$swal({
                  title: "Apakah anda yakin akan menghapus Data Forecasting tersebut?!",
                  text: "Setelah dihapus anda tidak dapat mengembalikannya!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#c0392b",
                  confirmButtonText: "YA, HAPUS!"
                }).then((result) => { // <--
                  if (result.value) { // <-- if confirmed
                    this.$store.dispatch("forecasting/deleteForecastingById", this.id)
                    this.$router.push('/dashboard/forecasting')
                    this.$toasted.success("Hapus Data Forecasting Berhasil", { duration: 5000 }) 
                    
                  }
              });
 
    },
    deactiveForecasting(){       
      this.$swal({
                  title: "Apakah anda yakin akan MenonAktifkan Data Forecasting tersebut?!",
                  text: "Setelah diNon Aktif anda tidak dapat mengembalikannya!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#c0392b",
                  confirmButtonText: "YA, NON AKTIFKAN!"
                }).then((result) => { // <--
                  if (result.value) { // <-- if confirmed
                    this.$store.dispatch("forecasting/deactiveForecastingById", this.id)
                    this.$router.push('/dashboard/forecasting')
                    this.$toasted.success("MenonAktifkan Forecasting Berhasil", { duration: 5000 }) 
                    
                  }
              });
 
    },
  },
};
</script>

 <style scoped>
</style>