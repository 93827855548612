<template>
  <div class="modal-user">
    <div :class="['modal', { 'is-active': buka }]">
      <div class="modal-background"></div>
       <div class="progress-bar">
        <span class="bar">
            <span class="progress"></span>
        </span>
        </div>
      
  </div>
  </div>
</template>



<script>
export default {
  components: {},
  props: {
    buka: {
      type: Boolean,
      required: false,
    },
  },
 
};
</script>


<style lang="scss" scoped>
  // Color Variables ======================================
$green: #6dcdb1;
$gray-light: #eef1f3;

// Bar Variables ========================================
$bar-size: 5px;
$bar-radius: 60px;
$bar-bg: rgba(0,0,0,0.075);

body {
  background: $gray-light;
}

// Animation =============================================
// Edit these at random intervals to change the animation.
@keyframes loader {

	0% {
		width: 0;
	}

	20% {
		width: 10%;
	}

	25% {
		width: 24%;
	}

	43% {
		width: 41%;
	}

	56% {
		width: 50%;
	}

	66% {
		width: 52%;
	}

	71% {
		width: 60%;
	}

	75% {
		width: 76%;
    
	}

	94% {
		width: 86%;
	}

	100% {
		width: 100%;
	}

}

// Bar ============================================
.progress-bar {
	border-radius: $bar-radius;
	overflow: hidden;
  width: 100%;

	span {
		display: block;
	}

}

.bar {
  background: $bar-bg;
}

.progress {
  animation: loader 8s ease infinite;
  // Change the animation fill mode 'infinite' to 'forwards' to stop the animation from repeating.
  background: $green;
  color: #fff;
  padding: $bar-size;
  width: 0;
}

// Vertical Centering ==============================
// You don't need this to make it work on your site.
.progress-bar {
  left: 50%;
  max-width: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%,-50%,0);
}

/* BODY CSS*/
@media (max-width: 768px) {
  .modal-card {
    height: 600px;
    width: 350px;
  }
}
</style>