<template>
  <div v-if="isLoading">
    <section>
      <div class="datatable-toolbar">
        <div class="buttons">
          <button
            class="button h-button is-success is-raised"
            @click.prevent="cariChannelPch"
          >
            <i class="fas fa-search"></i>
            Cari Data
          </button>
        </div>
      </div>
    </section>
    <br />
    <app-spinner />
  </div>
  <div v-else>
   <section>
      <div class="datatable-toolbar">
        <div class="buttons">
          <button
            class="button h-button is-success is-raised"
            @click.prevent="cariChannelPch"
          >
            <i class="fas fa-search"></i>
            Cari Data
          </button>
        </div>
      </div>
    </section>
    <br />
    <section class="columns">
      <div class="column is-12 tma">
        <div class="card-debit">
          <div class="card">
            <div class="card-header"></div>
            <div class="card-conten chart">
              <v-chart autoresize class="chart" :option="arr" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <br />
     
    <section class="columns">
      <div class="column is-12 tma">
        <div class="card-debit">
          <div class="card">
            <div class="card-header"> 
            </div>
            <!-- Datatable -->
            <div class="table-wrapper">
              <b-table
                class="table is-datatable is-hoverable table-is-bordered"
                :data="pch"
                :columns="columnsPch"
                focusable
                paginated
                :total="totalPch"
                :per-page="perPage"
                @page-change="onPageChange"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                :default-sort-direction="defaultSortOrder"
                :default-sort="[sortField, sortOrder]"
                @sort="onSort"
                icon-pack="fas"
                icon-left="chevron-right"
              >
                <b-table-column field="tanggal" label="Tanggal" v-slot="props">
                  {{ props.row.tanggal }}
                </b-table-column>

                <b-table-column field="ch1" label="Volume (mm)" v-slot="props">
                  {{ props.row.ch1 }}
                </b-table-column>

                <template #empty>
                  <div class="has-text-centered">Tidak Ada Data</div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import AppSpinner from "../shared/AppSpinner.vue"; 
export default {
  name: "ModalTablePchManual",
  components: {
    AppSpinner, 
  },
  props: {
    registerId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      pch: [],
      totalPch: 0,
      perPage: 10,
      sortField: "created",
      sortOrder: "DESC",
      defaultSortOrder: "DESC",
      page: 0,
      columnsPch: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "value",
          label: "value (mm)",
        },
      ],
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: false,
      isFocusable: false,
      isLoading: true,
      hasMobileCards: true,
      arr: {},
    };
  },
  created() {
    this.isLoading = false;
  },
  computed: {
    ...mapState({
      hidrologiPchLanding: (state) => state.hidrologiPchLanding.items,
    }),
  },
  methods: {
    ...mapActions("hidrologiPchLanding", ["fetchPchManual"]),
    /*
     * Load async data
     */
    loadAsyncData() {
        this.isLoading = true;

      this.fetchPchManual(this.registerId).then((resp) => {
        this.pch = [];
        let currentTotal = resp.length;
        if (currentTotal / this.perPage > 1000) {
          currentTotal = this.perPage * 1000;
        }
        this.totalPch = currentTotal;
        resp.forEach((item) => {
          this.isLoading = false;
          this.pch.push(item);
        }); 
         this.arr = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: resp.map((r) => r.tanggal).reverse(),
              axisTick: {
                alignWithLabel: true
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                  formatter: '{value} mm'
               }
            },
             
          ],
          series: [
            {
              name: 'Volume',
              type: 'bar',
              barWidth: '60%',
              data: resp.map((r) => r.value).reverse()
            }
          ]
         }


      });
    },
    cariChannelPch() {
      this.$router.push(`/caridata/hidrologi/pch/${this.registerId}`);
    },
    addChannelMethod(chan) {
      this.addChannel(chan);
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
    cariChannel() {
      this.$router.push(`/lihatdata/${this.registerId}`);
    },
  },
  mounted() {
    this.loadAsyncData();
  },
};
</script>

<style scoped>
.fa-search
{
  margin-right: 10px;
}
.chart {
  height: 400px;
  width: 100%;
}
</style>