import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js' 



export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fetchDebitSungai({state, commit}){
            commit('setItems', {resource: 'debit', items: []}, {root: true})
           return axiosInstance.get('/api/debit/search/rumus')
            .then(res => {
                const debit = res.data                 
                commit('setItems', {resource: 'debit', items: debit}, {root: true})
                return state.items
             })
        },
        createDebitSungai(context, payload){
            const headers = {
                'Content-Type': 'application/json',
              }
            return axiosInstance.post('/api/debit/add/rumus', 
            payload,{
                headers: headers
              })
              .then(res => res.data)
        },
        fetchDebitRegisterId({state,commit}, registerId) {
            commit('setItems', {resource: 'debit', items: []}, {root: true})
            return axiosInstance.get(`/api/debit/get/${registerId}`)
            .then(res => {
                const debits = res.data.data                 
                commit('setItems', {resource: 'debit', items: debits}, {root: true})
                return state.items
                })
                .catch(()=>{
                  
                });    
        },
        fetchRumusDebitRegisterId({state,commit}, registerId) {
            commit('setItem', {resource: 'debit', item: {}}, {root: true})
            return axiosInstance.get(`/api/debit/registerid/rumus/${registerId}`)
            .then(res => {
                const debit = res.data               
                commit('setItem', {resource: 'debit', item: debit}, {root: true})
                return state.item
                })
                .catch(()=>{
                  
                });
        },
        fetchDebitById({state,commit}, debitId) {
          commit('setItem', {resource: 'debit', item: {}}, {root: true})
          return axiosInstance.get(`/api/debit/view/rumus/${debitId}`)
          .then(res => {
              const debit = res.data               
              commit('setItem', {resource: 'debit', item: debit}, {root: true})
              return state.item
              })
              .catch(()=>{
                
              });
      },
        updateDebit(context, payload){
            const headers = {
                'Content-Type': 'application/json',
              }          
                return axiosInstance.put(`/api/debit/update/rumus/${payload.id}`, payload,
                {
                  headers: headers
                })             
                 
        },
        deleteDebit({state, commit}, id){             
          commit('setItem', {resource: 'debit', item: {}}, {root: true})
          return axiosInstance.delete(`/api/debit/remove/rumus/${id}`)
          .then(res => {
              const debit = res.data                 
              commit('setItem', {resource: 'debit', item: debit}, {root: true})
              return state.item
           })
           .catch(()=> {

           })
        }
        
    },
     
}