import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js';

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {            
      async fetchPdaTahunan({state,commit}, payload) {
            commit('setItem', {resource: 'dinasTahunan', item: {}}, {root: true})
            return await axiosInstance.post('/api/kedinasan/pda/tahunan',payload)
            .then(res => {
                const hidroTahunan = res.data
                commit('setItem', {resource: 'dinasTahunan', item: hidroTahunan}, {root: true})
                return state.item
             })
        }, 
      async fetchPchTahunan({state,commit}, payload) {
            commit('setItem', {resource: 'dinasTahunan', item: {}}, {root: true})
            return  await axiosInstance.post('/api/kedinasan/pch/tahunan',payload)
            .then(res => {
                const hidroTahunan = res.data
                commit('setItem', {resource: 'dinasTahunan', item: hidroTahunan}, {root: true})
                return state.item
             })
        }, 
        
    },
    mutations: {

    }
}