<template>
<div class="table-wrapper" v-if="isLoading">
      <app-spinner/>
  </div>
  <div class="table-wrapper" v-else>
    <b-table
      class="table is-datatable is-hoverable table-is-bordered"
      :data="pch"
      :columns="columnsPch"
      focusable
      paginated
      :total="totalPch"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      icon-pack="fas"
      icon-left="chevron-right"
    >
      <b-table-column field="tanggal" label="Tanggal" v-slot="props">
        {{ props.row.tanggal }}
      </b-table-column>

      <b-table-column field="ch1" label="PCH (mm)" v-slot="props">
        {{ props.row.ch1 }}
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">No records</div>
      </template>
    </b-table>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import AppSpinner from '../shared/AppSpinner.vue'
export default {
  name: "ModalTablePch",
  components: {
      AppSpinner
  },
  props: {
    registerId: {
      type: String,
      required: false,
    },
  },
  data() {
    return { 
        pch: [],
        totalPch: 0,
        perPage: 10,
        sortField: "created",
        sortOrder: "DESC",
        defaultSortOrder: "DESC",
        page: 0,
        columnsPch: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
          {
          field: "ch1",
          label: "Curah Hujan (mm)",
        },
      ],
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: false,
      isFocusable: false,
      isLoading: true,
      hasMobileCards: true,
    };
  },
   created(){
      this.isLoading = true
  },
  computed: {
    ...mapState({
      pchchannels: (state) => state.landingPchChannel.items,
    }),
  },
  methods: {
   ...mapActions("landingPchChannel", ["fetchLandingPchChannel", "addPchChannel"]),
    /*
     * Load async data
     */
    loadAsyncData() {
    //   this.isLoading = true;

     this.fetchLandingPchChannel(this.registerId).then((resp) => {
        this.pch = []; 
        let currentTotal = resp.length;
        if (currentTotal / this.perPage > 1000) {
          currentTotal = this.perPage * 1000;
        }
         this.totalPch = currentTotal
        resp
          .slice()
          .reverse()
          .forEach((item) => {
            this.isLoading = false;
            item.tanggal = moment(String(item.tanggal)).format('YYYY-MM-DD HH:mm:ss')
            this.pch.push(item);
          });
      });
    },
     addChannelMethod(chan) {
      this.addChannel(chan);
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
    cariChannel(){
      this.$router.push(`/lihatdata/${this.registerId}`)    
    }
  },
   mounted() {
    this.loadAsyncData();
  },
};
</script>