import axios from '../../conf/axios';
// import ApiMethods from '@/conf/config.js'

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fetchLandingContentInfo({ state, commit }) {
            commit('setItems', { resource: 'landingContent', items: [] }, { root: true })
            return axios.get('/api/landing/contentinfo/search')
                .then(res => {
                    const landingContent = res.data.contentInfoList
                    commit('setItems', { resource: 'landingContent', items: landingContent }, { root: true })
                    return state.items
                })
        },
        searchLandingContent({ state, commit }, params) {
            commit('setItems', { resource: 'landingContent', items: [] }, { root: true })
            return axios.get(`/api/landing/contentinfo/search?${params}`)
                .then(res => {
                    const results = res.data
                    commit('setItems', { resource: 'landingContent', items: results }, { root: true })
                    return state.items
                })
        },
        getLandingContentFile({ state, commit}, payload) {
            commit('setItem', { resource: 'landingContent', item: {} }, { root: true })
            return axios.get(`/api/landing/contentinfo/download/${payload}`, {
                method: 'get',
                responseType: 'arraybuffer',
              })
                .then(res => {
                     const files = res.data;
                     const url = window.URL.createObjectURL(new Blob([res.data]))
                     const link = document.createElement('a')
                     link.href = url
                    //  link.setAttribute('download', payload)
                     document.body.appendChild(link)
                    //  link.click()
                    commit('setItem', { resource: 'landingContent', item: files }, { root: true })
                    return state.item
                })
        },
        fetchContentInfoDataOlahan({ state, commit }) {
            commit('setItems', { resource: 'landingContent', items: [] }, { root: true })
            return axios.get('/api/landing/contentinfo/search?category=data_olahan')
                .then(res => {
                    const landingContent = res.data.contentInfoList
                    commit('setItems', { resource: 'landingContent', items: landingContent }, { root: true })
                    return state.items
                })
        },
        fetchContentInfoAnalisa({ state, commit }) {
            commit('setItems', { resource: 'landingContent', items: [] }, { root: true })
            return axios.get('/api/landing/contentinfo/search?category=hasil_analisa')
                .then(res => {
                    const landingContent = res.data.contentInfoList
                    commit('setItems', { resource: 'landingContent', items: landingContent }, { root: true })
                    return state.items
                })
        },
        fetchContentInfoUmum({ state, commit }) {
            commit('setItems', { resource: 'landingContent', items: [] }, { root: true })
            return axios.get('/api/landing/contentinfo/search?category=informasi_umum')
                .then(res => {
                    const landingContent = res.data.contentInfoList
                    commit('setItems', { resource: 'landingContent', items: landingContent }, { root: true })
                    return state.items
                })
        },
    },
    mutations: {

    }
}