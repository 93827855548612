
import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js';


export default {
    namespaced: true,
    // in state we are keeping out data and sharing with components
    state: {
        items: [],
    },
    // as computed Properties in Vuex to get a state
    getters: {

    },
    // as method in vue
    actions: {
      fetchProfile({state, commit}){
        commit('setItem', {resource: 'profile', item: []}, {root: true})
       return axiosInstance.get('/api/profile')
        .then(res => {
            const profile = res.data                
            commit('setItem', {resource: 'profile', item: profile}, {root: true})
            return state.item
         })
      }, 
    },
    mutations: {

    }
}