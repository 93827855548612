<template>
  <div class="page-content-wrapper">
    <div class="page-content-inner">
      <div class="datatable-toolbar">
        <div class="buttons">
          <InputFileBatch :registerId="registerId" @submitted="submitted" />
        </div>
      </div>
       <div class="flex-list-wrapper flex-list-v1">
      <div class="flex-table">
        <!--Table header-->
         

        <div v-if="isLoaded" class="flex-list-inner">
          <!--Table item-->
           <div class="column is-12">
        <!-- Datatable -->

        <div class="table-wrapper">
          <b-table
            class="table is-datatable is-hoverable table-is-bordered"
            :data="data"
            :columns="columns" 
            focusable
            paginated
            backend-pagination
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @sort="onSort"
            icon-pack="fas"
            icon-left="chevron-right"
          >
          </b-table>
        </div>
      </div>
        </div>
        <div v-else class="flex-list-inner">
          <AppSpinner />
        </div>
      </div>

      
    </div>
       
  </div>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import AppSpinner from '../shared/AppSpinner.vue'
import InputFileBatch from './InputFileBatch.vue'
import moment from 'moment'
export default {
  name: "Tablebatch",
  components: { 
    AppSpinner,
    InputFileBatch
  },
  props: {
    registerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,   
      data: [],
      total: 0, 
      sortField: "created",
      sortOrder: "ASC",
      defaultSortOrder: "ASC",
      page: 0,
      perPage: 10,
      columns: [
        {
          field: "created",
          label: "Tanggal",
        },
        {
          field: "originalFilename",
          label: "Nama File",
        },
         {
          field: "type",
          label: "Tipe Pos",
        },
        {
          field: "status",
          label: "status",
        },
      ],
     hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
    };
  },
  computed: {
    ...mapState({
       uploadHidrologi: (state) => state.uploadHidrologi.items, 
    }),
  },
  created(){
    this.loadAsyncData();
  },
  methods:  { 
    ...mapActions("uploadHidrologi", ["fetchHidrologiKodeRegister"]),
   
     /*
     * Load async data
     */
   loadAsyncData() {
    this.isLoaded = true; 
     this.fetchHidrologiKodeRegister(this.registerId).then(() => {
        this.data = [];
         let currentTotal = this.uploadHidrologi.length;
        if (currentTotal / this.perPage > 1000) {
          currentTotal = this.perPage * 1000;
        }
        this.total = currentTotal
        this.uploadHidrologi.forEach((item) => {
          this.isLoaded = true;
          item.type = item.type === 'POS_CURAH_HUJAN' ? 'PCH' : 'PDA'
          item.created =  moment(String(item.created)).format('YYYY-MM-DD HH:mm:ss')
          this.data.push(item);
        });
         
      });
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
      
    
    remove(index) {
      console.log("index" + index);
    },
    submitted(){
     this.loadAsyncData();
    },
  },

  mounted() {
    this.loadAsyncData();
  },
};
</script>