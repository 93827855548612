<template>
  <div class="dashboard-map-wrapper">
    <div class="dashboard-map-wrapper-inner is-reversed">
      <div class="maps">
        <l-map :zoom="zoom" :center="center">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-geo-json :geojson="geojson" :options-style="styleFunction" /> 
           <l-geo-json :geojson="forecast" :options-style="styleForecast" />         
        </l-map>
      </div>
    </div>
  </div>
</template>

<script>
 
import { LMap, LTileLayer, LGeoJson } from "vue2-leaflet"; 
export default {
  name: "KualitasAirMap",
  components: {
    LMap,
    LTileLayer,    
    LGeoJson,
     
  },
  props: {
    forecastFile: {
      type: String,
      required: false,
    },
  },  
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 9.4,
      center: [-6.7473905, 108.5505455],
      markerLatLng: [-6.73003, 108.54273],
      newLoc: "",
      newLt: 0,
      newLng: 0,      
      geojson: require("@/assets/geojson/SUNGAI_LN.json"),
      forecast: null,
    };
  },
   created(){
    this.isLoading = true;
    
  },
  computed: {
    
    styleFunction() {
      const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        return {
          weight: 1,
          color: "#3498db",
          opacity: 1,
          fillColor: fillColor,
          fillOpacity: 1,
        };
      };
    },
    styleForecast() {       
      return () => {
        return {
          weight: 3,
          color: "#5E2C0B",
          opacity: 1,
          fillColor: "#5E2C0B",
          fillOpacity: 1
        };
      };
    },
  },
  methods: {
     getFiles(forecastFile){
        //  console.log(forecastFile)
         fetch( `/api/landing/forecast/file/download/${forecastFile}`)
            .then(response => response.json())
            .then(data => (this.forecast = data));

          // Promise.all(this.forecastFiles).then(function() {
          //     // console.log("print");
          // }); 
     }
  },
  watch: {
      forecastFile: {
        // the callback will be called immediately after the start of the observation
        immediate: true, 
        deep: true,
        handler (val, oldVal) {
          // console.log(val)
          // console.log(oldVal)
          if(val != null){
             this.getFiles(val)
          }else if(oldVal != null){
              this.forecast = null 
              // console.log(oldVal)
          }
          
        }
      },
       
  },
   
};
</script>

<style scoped>
.maps {
  width: 100%;
  height: 60vh;
  z-index: 1;
}
.fas
{
  margin-right: 10px;
  color: #2d6efd;
}
.layer-control
{
  position: relative;
  width: 140px;
  height: 135px;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.383);
  border: 1px solid rgba(233, 231, 231, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.171);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  border-radius: 10px;
  transition: 0,5s; 
}
.cards
{
 background: #2d6efd;
}

.control
{
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  
}

.title1
{
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding-top: 10px;
  margin-left: 15px;
 
}

.title2
{
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  padding-top: 2px;
}

.hijau
{
  width: 20px;
  height: 20px;
  background: #20bf6b;
  border-radius: 3px;
}

.kuning
{
  width: 20px;
  height: 20px;
  background: #fbd104;
  border-radius: 3px;
}

.merah
{
  width: 20px;
  height: 20px;
  background: #fc0000ff;
  border-radius: 3px;
}

@media screen and (max-width: 768px){
.layer-control
{
  margin-left: 12px;
  margin-bottom: 20px;
 }

 /* .maps
 {
  margin-top: 60px;
  min-width: 100%;
 } */
}

</style>
 