import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js'


export default {
    namespaced: true,
    state: {
        items: []
    },
    getters: {

    },
    actions: {
        fetchOrdos({state, commit}){
            commit('setItems', {resource: 'ordo', items: []}, {root: true})
           return axiosInstance.get('/api/sungai/manage/search-ordo')
            .then(res => {
                const ordo = res.data.ordo                 
                commit('setItems', {resource: 'ordo', items: ordo}, {root: true})
                return state.items
             })
        }
    },
    mutations: {

    }
}