<template>
  <div class="">
    <div class="nav">
      <div class="linknav">
        <LinkNavigationBaru />
      </div>
    </div>

    <div class="layer2">
      <div class="field">
        <div class="control">
          <InformasiPosBaruShare />
        </div>
      </div>
    </div>

    <div class="">
      <l-map
        class="maps"
        :zoom="zoom"
        :center="center"
        :options="{ zoomControl: false }"
        @click="onMapClick"
      >
        <l-control-zoom position="topright"></l-control-zoom>
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-geo-json :geojson="geojson" :options-style="styleFunction" />
        <l-marker
          v-for="(kualitas, index) in dataList"
          :lat-lng="[kualitas.lat, kualitas.lng]"
          :key="index"
          :icon="getIcon(kualitas)"
        >
          <l-tooltip>
            <i class="fas fa-water"></i>
            <span>{{ kualitas.sungai }}</span>
          </l-tooltip>
          <l-popup class="popup">
            <table class="table">
              <tbody>
                <tr>
                  <td>Nama Sungai</td>
                  <td>:</td>
                  <td>{{ kualitas.sungai }}</td>
                </tr>
                <tr>
                  <td>Latitude</td>
                  <td>:</td>
                  <td>{{ kualitas.lat }}</td>
                </tr>
                <tr>
                  <td>Longitude</td>
                  <td>:</td>
                  <td>{{ kualitas.lng }}</td>
                </tr>
                <tr>
                  <td>Periode</td>
                  <td>:</td>
                  <td>{{ periode(kualitas.periode) }}</td>
                </tr>
                <tr>
                  <td>Mutu Air</td>
                  <td>:</td>
                  <td>{{ kategori(kualitas.hasilAnalisa) }}</td>
                </tr>
                <tr>
                  <td>Index Pencemaran</td>
                  <td>:</td>
                  <td>{{ kualitas.indexPencemaran }}</td>
                </tr>
              </tbody>
            </table>
          </l-popup>
        </l-marker>

        <!-- <l-control class="layer-control" position="topleft">
            <div class="grup">
              <LinkNavigationBaru />
            </div>
          </l-control> -->

        <l-control class="layer-control" position="bottomright">
          <!-- legenda info pos -->

          <div class="layer1">
            <div class="cards">
              <span class="title1">Informasi Cemar </span>
            </div>
            <div class="field">
              <div class="control">
                <div class="lcontrol">
                  <div class="field">
                    <div class="">
                      <div class="biru"></div>
                    </div>

                    <span>Memenuhi Baku Mutu</span>
                  </div>

                  <div class="field">
                    <div class="">
                      <div class="hijau"></div>
                    </div>

                    <span>Cemar Ringan</span>
                  </div>

                  <div class="field">
                    <div class="sungai"></div>
                    <span>Cemar Sedang</span>
                  </div>

                  <div class="field">
                    <div class="merah"></div>
                    <span>Cemar Berat</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end -->
        </l-control>
      </l-map>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import {
  LMap,
  LTileLayer,
  LMarker,
  LGeoJson,
  LControl,
  LControlZoom,
} from "vue2-leaflet";
import { icon } from "leaflet";
import LinkNavigationBaru from "../navigation/LinkNavigationBaru.vue";
import InformasiPosBaruShare from "./InformasiPosBaruShare.vue";
export default {
  name: "KualitasAirMap",
  components: {
    LMap,
    LControlZoom,
    LTileLayer,
    LMarker,
    LGeoJson,
    LControl,
    LinkNavigationBaru,
    InformasiPosBaruShare,
  },
  data() {
    return {
      zoom: 9.5,
      center: [-6.7473905, 108.5505455],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors - Unit Hidrologi dan Kualitas Air - BBWS Cimanuk Cisanggarung © 2024',
      markerLatLng: [-6.73003, 108.54273],
      newLoc: "",
      newLt: 0,
      newLng: 0,
      locations: [
        {
          name: "BBWS Cimanuk",
          position: [-6.73003, 108.54273],
        },
      ],

      icon: null,
      geojson: require("@/assets/geojson/SUNGAI_LN.json"),
    };
  },
  computed: {
    ...mapState({
      dataList: (state) => state.landingKualitas.items,
    }),
    styleFunction() {
      const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        return {
          weight: 1,
          color: "#3498db",
          opacity: 1,
          fillColor: fillColor,
          fillOpacity: 1,
        };
      };
    },
  },
  methods: {
    ...mapActions("landingKualitas", ["fetchLandingKualitas"]),
    onMapClick(e) {
      this.newLt = e.latlng.lat;
      this.newLng = e.latlng.lng;
      this.newLoc =
        "Location on click: " +
        e.latlng.lat.toFixed(6) +
        "," +
        e.latlng.lng.toFixed(6);
    },
    lokasi() {
      this.fetchLandingKualitas();
    },
    kategori(value) {
      if (value === "baku_mutu") {
        value = "Memenuhi Baku Mutu";
      } else if (value === "cemar_ringan") {
        value = "Cemar Ringan";
      } else if (value === "cemar_sedang") {
        value = "Cemar Sedang";
      } else if (value === "cemar_ringan") {
        value = "Cemar Berat";
      }
      return value;
    },
    periode(value) {
      return moment(String(value)).format("YYYY-MM");
    },
    getIcon(kualitas) {
      if (kualitas.hasilAnalisa === "baku_mutu") {
        return icon({
          iconUrl: require("../../assets/image/mutu_baku.png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      } else if (kualitas.hasilAnalisa === "cemar_ringan") {
        return icon({
          iconUrl: require("../../assets/image/ringan.png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      } else if (kualitas.hasilAnalisa === "cemar_sedang") {
        return icon({
          iconUrl: require("../../assets/image/sedang.png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      } else if (kualitas.hasilAnalisa === "cemar_berat") {
        return icon({
          iconUrl: require("../../assets/image/berat.png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      }
    },
  },
  mounted() {
    this.newLoc = this.locations[0].name;
    this.newLt = this.locations[0].position[0];
    this.newLng = this.locations[0].position[1];
    this.lokasi();
  },
};
</script>

<style scoped>

.maps {
  width: 100%;
  height: 100vh;
  z-index: 1;
}
.nav {
  position: absolute;
  margin-top: 5px;
  margin-left: 10px;
  z-index: 9;
}
.layer2 {
  position: absolute;
  margin-top: 125px;
  right: 5px;
  z-index: 9;
}
.cards {
  background: #212c5f;
  text-align: center;
}

.title1 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding-top: 10px;
  margin-left: 15px;
}

.lcontrol {
  display: flex;
  justify-content: space-evenly;
}

/* legenda info pos  */

.layer1 {
  position: relative;
  width: 500px;
  height: 75px;
  backdrop-filter: blur(5px);
  background: rgba(255, 254, 254, 0.369);
  border: 1px solid rgba(233, 231, 231, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.171);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  border-radius: 0 0 10px 10px;
  transition: 0.5s;
}

span {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: black;
}

.biru {
  background-color: #0e6ad3;
  width: 20px;
  height: 20px;
  margin-top: 5px;
  border-radius: 50px;
}

.hijau {
  background-color: #20bf6b;
  width: 20px;
  height: 20px;
  margin-top: 5px;
  border-radius: 50px;
}

.sungai {
  background-color: #fbd104;
  width: 20px;
  height: 20px;
  margin-top: 5px;
  border-radius: 50px;
}

.merah {
  width: 20px;
  height: 20px;
  margin-top: 5px;
  border-radius: 50px;
  background: #fc0000ff;
}

@media screen and (max-width: 1366px) {
  .layer2 {
  right: 22px;
}
}

@media screen and (max-width: 1024px) {
  .layer2 {
  overflow: hidden;
}
}

@media screen and (max-width: 768px) {

  .nav {
    margin-top: 5px;
    margin-left: 4px;
  }
  .maps {
    width: 100%;
    height: 92vh;
  }
  .layer2 {
   display: none;
  }
  .layer1 {
    position: relative;
    width: 340px;
    height: 75px;
    backdrop-filter: blur(5px);
    background: rgba(255, 254, 254, 0.369);
    border: 1px solid rgba(233, 231, 231, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.171);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
    border-radius: 10px;
    transition: 0.5s;
  }

  span {
    font-family: "Roboto", Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
    color: black;
  }

  .biru {
    background-color: #0e6ad3;
    width: 15px;
    height: 15px;
    margin-top: 5px;
    border-radius: 50px;
  }

  .hijau {
    background-color: #20bf6b;
    width: 15px;
    height: 15px;
    margin-top: 5px;
    border-radius: 50px;
  }

  .sungai {
    background-color: #fbd104;
    width: 15px;
    height: 15px;
    margin-top: 5px;
    border-radius: 50px;
  }

  .merah {
    width: 15px;
    height: 15px;
    margin-top: 5px;
    border-radius: 50px;
    background: #fc0000ff;
  }
}
</style>
 