<template>
    <div class="flex-list-inner">
              <!--Table item placeload-->
              <div class="flex-table-item placeload-wrap">
                <div class="flex-table-cell is-media is-grow">
                  <div
                    class="placeload-avatar is-medium is-rounded-full loads"
                  ></div>
                  <div class="content-shape-group mx-2">
                    <div class="content-shape is-grow-1 mw-80 loads"></div>
                    <div class="content-shape mw-60 loads"></div>
                  </div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="avatar-stack is-pushed-mobile">
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                  </div>
                </div>
                <div class="flex-table-cell cell-end" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
              </div>
              <!--Table item placeload-->
              <div class="flex-table-item placeload-wrap">
                <div class="flex-table-cell is-media is-grow">
                  <div
                    class="placeload-avatar is-medium is-rounded-full loads"
                  ></div>
                  <div class="content-shape-group mx-2">
                    <div class="content-shape is-grow-1 mw-80 loads"></div>
                    <div class="content-shape mw-60 loads"></div>
                  </div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="avatar-stack is-pushed-mobile">
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                  </div>
                </div>
                <div class="flex-table-cell cell-end" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
              </div>
              <!--Table item placeload-->
              <div class="flex-table-item placeload-wrap">
                <div class="flex-table-cell is-media is-grow">
                  <div
                    class="placeload-avatar is-medium is-rounded-full loads"
                  ></div>
                  <div class="content-shape-group mx-2">
                    <div class="content-shape is-grow-1 mw-80 loads"></div>
                    <div class="content-shape mw-60 loads"></div>
                  </div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="avatar-stack is-pushed-mobile">
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                  </div>
                </div>
                <div class="flex-table-cell cell-end" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
              </div>
              <!--Table item placeload-->
              <div class="flex-table-item placeload-wrap">
                <div class="flex-table-cell is-media is-grow">
                  <div
                    class="placeload-avatar is-medium is-rounded-full loads"
                  ></div>
                  <div class="content-shape-group mx-2">
                    <div class="content-shape is-grow-1 mw-80 loads"></div>
                    <div class="content-shape mw-60 loads"></div>
                  </div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="avatar-stack is-pushed-mobile">
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                  </div>
                </div>
                <div class="flex-table-cell cell-end" data-th="Actions">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
              </div>
              <!--Table item placeload-->
              <div class="flex-table-item placeload-wrap">
                <div class="flex-table-cell is-media is-grow">
                  <div
                    class="placeload-avatar is-medium is-rounded-full loads"
                  ></div>
                  <div class="content-shape-group mx-2">
                    <div class="content-shape is-grow-1 mw-80 loads"></div>
                    <div class="content-shape mw-60 loads"></div>
                  </div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="avatar-stack is-pushed-mobile">
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                  </div>
                </div>
                <div class="flex-table-cell cell-end" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
              </div>
              <!--Table item placeload-->
              <div class="flex-table-item placeload-wrap">
                <div class="flex-table-cell is-media is-grow">
                  <div
                    class="placeload-avatar is-medium is-rounded-full loads"
                  ></div>
                  <div class="content-shape-group mx-2">
                    <div class="content-shape is-grow-1 mw-80 loads"></div>
                    <div class="content-shape mw-60 loads"></div>
                  </div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="avatar-stack is-pushed-mobile">
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                  </div>
                </div>
                <div class="flex-table-cell cell-end" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
              </div>
              <!--Table item placeload-->
              <div class="flex-table-item placeload-wrap">
                <div class="flex-table-cell is-media is-grow">
                  <div
                    class="placeload-avatar is-medium is-rounded-full loads"
                  ></div>
                  <div class="content-shape-group mx-2">
                    <div class="content-shape is-grow-1 mw-80 loads"></div>
                    <div class="content-shape mw-60 loads"></div>
                  </div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="avatar-stack is-pushed-mobile">
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                  </div>
                </div>
                <div class="flex-table-cell cell-end" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
              </div>
              <!--Table item placeload-->
              <div class="flex-table-item placeload-wrap">
                <div class="flex-table-cell is-media is-grow">
                  <div
                    class="placeload-avatar is-medium is-rounded-full loads"
                  ></div>
                  <div class="content-shape-group mx-2">
                    <div class="content-shape is-grow-1 mw-80 loads"></div>
                    <div class="content-shape mw-60 loads"></div>
                  </div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="avatar-stack is-pushed-mobile">
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                  </div>
                </div>
                <div class="flex-table-cell cell-end" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
              </div>
              <!--Table item placeload-->
              <div class="flex-table-item placeload-wrap">
                <div class="flex-table-cell is-media is-grow">
                  <div
                    class="placeload-avatar is-medium is-rounded-full loads"
                  ></div>
                  <div class="content-shape-group mx-2">
                    <div class="content-shape is-grow-1 mw-80 loads"></div>
                    <div class="content-shape mw-60 loads"></div>
                  </div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="avatar-stack is-pushed-mobile">
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                  </div>
                </div>
                <div class="flex-table-cell cell-end" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
              </div>
              <!--Table item placeload-->
              <div class="flex-table-item placeload-wrap">
                <div class="flex-table-cell is-media is-grow">
                  <div
                    class="placeload-avatar is-medium is-rounded-full loads"
                  ></div>
                  <div class="content-shape-group mx-2">
                    <div class="content-shape is-grow-1 mw-80 loads"></div>
                    <div class="content-shape mw-60 loads"></div>
                  </div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
                <div class="flex-table-cell" data-th="">
                  <div class="avatar-stack is-pushed-mobile">
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                    <div
                      class="placeload-avatar is-small is-rounded-full loads mx-1"
                    ></div>
                  </div>
                </div>
                <div class="flex-table-cell cell-end" data-th="Actions">
                  <div class="content-shape is-grow-1 mx-1 loads"></div>
                </div>
              </div>
            </div>
</template>


<script>
export default {
   name: 'Spiner'
}
</script>
