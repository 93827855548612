<template>
 <div>
        <SidebarNav/>
        <MobileNavbarDash/>
        <MobileSidebarDash/>
        <SidebarNav/>
<div id="app-list" class="view-wrapper" data-naver-offset="214" data-menu-item="#layouts-sidebar-menu"
    data-mobile-item="#layouts-sidebar-menu-mobile">

    <div class="page-content-wrapper">
        <div class="page-content is-relative tabs-wrapper is-slider is-squared is-inverted">

            <div class="page-title has-text-centered">
                <!-- Sidebar Trigger -->
                <div class="huro-hamburger nav-trigger push-resize" data-sidebar="layouts-sidebar">
                    <span class="menu-toggle has-chevron">
                        <span class="icon-box-toggle">
                            <span class="rotate">
                                <i class="icon-line-top"></i>
                                <i class="icon-line-center"></i>
                                <i class="icon-line-bottom"></i>
                            </span>
                        </span>
                    </span>
                </div>

                <div class="title-wrap">
                    <h1 class="title is-4">Data Pos Pengelolaan Dinas</h1>
                </div>
 
            </div>

            <div class="list-view-toolbar">
                <div class="control has-icon">
                   <div class="control has-icon">
                    <input class="input custom-text-filter" v-model="cariNamaPos" placeholder="Search..." @keydown="cari">
                    <div class="form-icon">
                        <i class="fa fa-search"></i>
                    </div>
                     
                </div>
                </div>

                <div class="tabs-inner">                   
                   <b-button
                    class="button h-button is-success is-outlined is-raised"
                    type="is-info"
                    outlined
                    tag="router-link"
                    :to="'/dashboard/posdinas/input'"
                    ><i class="fal fa-plus"></i>
                    <span>Tambah Pos</span>
                  </b-button>
                </div>
            </div>

            <div class="page-content-inner">

                <!--List-->
                <div class="list-view list-view-v2">
 
                    <!--Active Tab-->
                    <div id="active-items-tab" class="tab-content is-active">
                        <div v-if="isLoaded" class="list-view-inner">
                            <!--List Item-->
                           <div class="table-wrapper">
                                 
                                 <b-table
                                    class="table is-datatable is-hoverable table-is-bordered"
                                    :data="isEmpty ? [] : data"
                                    :bordered="isBordered"
                                    :striped="isStriped"
                                    :narrowed="isNarrowed"
                                    :hoverable="isHoverable"
                                    :loading="isLoading"
                                    :focusable="isFocusable"
                                    :mobile-cards="hasMobileCards"
                                    
                                    >
                                  
                                <b-table-column
                                    field="namaPos"
                                    label="Nama Pos"
                                    :td-attrs="columnTdAttrs"
                                    v-slot="props"
                                >
                                    {{ props.row.namaPos }}
                                </b-table-column>
                                <b-table-column
                                    field="lokasiPos"
                                    label="Lokasi Pos"
                                    :td-attrs="columnTdAttrs"
                                    v-slot="props"
                                >
                                    {{ props.row.lokasiPos }}
                                </b-table-column>
                                  <b-table-column
                                    field="namaDas"
                                    label="namaDas"
                                    :td-attrs="columnTdAttrs"
                                    v-slot="props"
                                >
                                    {{ props.row.namaDas }}
                                </b-table-column>  
                                  <b-table-column
                                    field="jenisPos"
                                    label="Jenis Pos"
                                    :td-attrs="columnTdAttrs"
                                    v-slot="props"
                                >
                                    {{ props.row.jenisPos }}
                                </b-table-column>    
                                <b-table-column 
                                field="id" 
                                label="Action"
                                v-slot="props">

                                    <router-link 
                                    class="button h-button is-success is-outlined is-raised"
                                    :to="`/dashboard/posdinas/${props.row.registerId}`">
                                        Pratinjau
                                    </router-link>
                                                    
                                </b-table-column>

                                <template #empty>
                                    <div class="has-text-centered">No records</div>
                                </template>
                                
                                </b-table>
                            </div>
                            <div class="buttons is-right">
                            <b-button
                                @click="decrement"
                                type="is-info"
                                icon-pack="fas"
                                icon-left="chevron-left"
                                :disabled="hasDisablePreviousPage"
                            ></b-button>
                            <b-button
                                @click="increment"
                                type="is-info"
                                icon-pack="fas"
                                icon-left="chevron-right"
                                :disabled="hasDisableNextPage"
                            ></b-button>
                        </div>
                        <!-- <div v-else class="list-view-inner" > -->
                          
                        </div> 
                    </div>

                   
                </div>

            </div>
        </div>
    </div>
</div>
 </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
export default {
  name: "posDinasList",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
  },
  data() {
    return {
      isLoaded: false,
      inputCari: "",
      cariNamaPos: "",
      selected: null,
      data: [],      
      total: 0,
      loading: false,
      sortField: "created",
      sortOrder: "DESC",
      defaultSortOrder: "DESC",
      page: 0,
      perPage: 5,
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true, 
    };
  },
  created() {
    this.fetchPos().then(() => {
      this.isLoaded = true;
    });
  },
  beforeMount(){
    const authority = localStorage.getItem('authority')
    if(authority === 'DINAS'){
      this.$router.push('/dashboard/dinas')
    }
  },
  computed: {
    ...mapState({
      posDinas: (state) => state.posDinas.items,
    }),
  },
  methods: {
    ...mapActions("posDinas", ["searchPos", "fetchPos"]),
    /*
     * Load async data
     */
    loadAsyncData() {
      const params = [
        `namaPos=${this.cariNamaPos}`,
        `page=${this.page}`,
        `sortType=${this.sortOrder}`,
        `limit=${this.perPage}`,
      ].join("&");

      this.loading = true;

      this.searchPos(params).then(() => {
        let response = this.posDinas;
        this.data = [];
        let enablePreviousPage = response.hasPreviousPage;
        let enableNextPage = response.hasNextPage;
        if (enablePreviousPage) {
          this.hasDisablePreviousPage = false;
        } else {
          this.hasDisablePreviousPage = true;
        }

        if (enableNextPage) {
          this.hasDisableNextPage = false;
        } else {
          this.hasDisableNextPage = true;
        }

        this.posDinas.pos.forEach((item) => {
          this.data.push(item);
        });
      });
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
    cari() {
      this.loadAsyncData();
    },
    dateThAttrs(column) {
      return column.label === "Date"
        ? {
            title: 'This title is sponsored by "th-attrs" prop',
            class: "has-text-success",
          }
        : null;
    },
    columnTdAttrs(row, column) {
      if (row.id === "Total") {
        if (column.label === "ID") {
          return {
            colspan: 4,
            class: "has-text-weight-bold",
            style: {
              "text-align": "left !important",
            },
          };
        } else if (column.label === "Gender") {
          return {
            class: "has-text-weight-semibold",
          };
        } else {
          return {
            style: { display: "none" },
          };
        }
      }
      return null;
    },
    getImgUrl(params) {
      var images = "";
      if (params !== null) {
        images = this.url + this.path + params;
        return images;
      } else {
        images = "https://via.placeholder.com/150x150";
      }
      return images;
    },
    onImageLoadFailure(event) {
      event.target.src = "https://via.placeholder.com/150x150";
    },
  },

  mounted() {
    this.loadAsyncData();
  },
};
</script>

<style scoped>
.fal {
  margin-right: 10px;
  font-weight: 400;
}
</style>