<template>
<div id="app-search" class="view-wrapper" data-naver-offset="214" data-menu-item="#layouts-sidebar-menu"
    data-mobile-item="#home-sidebar-menu-mobile">

    <div class="page-content-wrapper">
        <div class="page-content is-relative tabs-wrapper is-triple-slider is-squared is-inverted">

            <div class="page-title has-text-centered">
                <!-- Sidebar Trigger -->
                

                <div class="title-wrap">
                   
                </div>
 
            </div>

            <div class="page-content-inner">

                <!--Empty Search-->
                <div class="search-results-wrapper">

                    <div class="search-results-header">
                        <div class="control has-icon">
                             
                            
                        </div>

                        

                        
                    </div>

                    <div class="search-results-body">

                        <!--Search Placeholder -->
                        <div class="page-placeholder">
                            <div class="placeholder-content">
                               <img
                                  v-if="status===404"
                                  class="light-image"
                                  src="../assets/img/illustrations/placeholders/error-4.svg"
                                  alt=""
                                />   
                                <img
                                v-else
                                  class="light-image"
                                  src="../assets/img/illustrations/placeholders/error-2.svg"
                                  alt=""
                                />      
                                <h3>{{ status }}</h3>
                                <p class="is-larger">{{ title }}</p>
                                
                                 <div v-if="status===404"  class="is-larger">                                     
                                    <button @click="$router.go(-1)" class="button h-button is-primary is-raised">Kembali</button>
                                </div>
                                <div v-else class="is-larger">                                     
                                    <router-link  to="/login" class="button h-button is-primary is-raised">Beranda</router-link>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
 
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    status: {
      required: true,
      type: String,
    },
    navigateToPage: {
      required: true,
      type: String,
    },
    navigateToText: {
      required: true,
      type: String,
    },
  },
};
</script>
 