<template>
  <div> 
    <ModalPosTelemetry/>
    <!-- <MobileNavbar />
    <MobileSidebarMain :isActive="true" /> 
    <Sidebar /> -->
    <LandingBaru/>
  </div>
</template>

<script>
// import Sidebar from "../components/navigation/Sidebar.vue";
// import MobileNavbar from "../components/navigation/MobileNavbar.vue";
// import MobileSidebarMain from "../components/navigation/MobileSidebarMain.vue";
// import Landing from "../components/landing/Landing.vue"; 
import ModalPosTelemetry from '../components/landing/ModalPosTelemetry.vue'
import LandingBaru from '../components/landing/LandingBaru.vue' 
export default {
  name: "LandingPage",
  components: {
    // Sidebar,
    // MobileNavbar,
    // MobileSidebarMain,
    // Landing,
    LandingBaru,
    ModalPosTelemetry
  },
};
</script>
