<template>
  <div>
    <button
      class="button h-button is-primary is-elevated"
      @click="isOpen = !isOpen"
      type="is-success"
      outlined
    >
      <span class="icon">
        <i aria-hidden="true" class="fas fa-plus"></i> </span
      ><span>Input Rumus</span>
    </button>

    <div :class="['modal', { 'is-active': isOpen }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Masukan Data Rumus</p>
          <!-- TODO: Close Modal set isOpen to false -->
          <button
            @click="isOpen = false"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <form>
            <div class="field">
              <div class="control">
                <label class="label is-normal">Koef A</label>
                <input
                  @blur="$v.form.koefA.$touch()"
                  v-model="form.koefA"
                  type="number"
                  class="input"
                  placeholder="Masukan Angka"
                />
                <div v-if="$v.form.koefA.$error">
                  <span v-if="!$v.form.koefA.required" class="help is-danger"
                    >Angka Harus Diisi</span
                  >
                </div>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="label is-normal">Koef B</label>
                <input
                  @blur="$v.form.koefB.$touch()"
                  v-model="form.koefB"
                  type="number"
                  class="input"
                  placeholder="Masukan Angka"
                />
                <div v-if="$v.form.koefB.$error">
                  <span v-if="!$v.form.koefB.required" class="help is-danger"
                    >Angka Harus Diisi</span
                  >
                </div>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="label is-normal">Koef C</label>
                <input
                  @blur="$v.form.koefC.$touch()"
                  v-model="form.koefC"
                  type="number"
                  class="input"
                  placeholder="Masukan Angka"
                />
                <div v-if="$v.form.koefC.$error">
                  <span v-if="!$v.form.koefC.required" class="help is-danger"
                    >Angka Harus Diisi</span
                  >
                </div>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <div class="control btn1">
              <button
                @click.prevent="saveDebit"
                :disabled="isFormInvalid"
                class="button h-button is-link is-elevated"
              >
                <span> Simpan </span>
              </button>
            </div>

            <div class="control btn">
              <button
                @click="isOpen = false"
                class="button  h-button is-danger is-elevated"
              >
                <span> Batal </span>
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>


<script>
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    registeredId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
      form: {
        registerId: this.registeredId,
        koefA: null,
        koefB: null,
        koefC: null,
      },
    };
  },
  validations: {
    form: {
      koefA: { required },
      koefB: { required },
      koefC: { required },
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
    isDisable() {
      return this.registerId.length > 0;
    },
  },
  methods: {
    saveDebit() {
      this.$store.dispatch("debit/createDebitSungai", this.form).then(() => {
        this.$emit("submitted", this.registerId);
        this.$toasted.success("Input Debit Success", { duration: 5000 });
      });

      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
.modal {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

.modal-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
}

i {
  margin-right: 10px;
}
</style>