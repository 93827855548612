 <template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
          </div>
          <div class="page-content-inner">
            <!--Form Layout 1-->
            <div class="form-layout">
              <div class="form-outer">
                <div class="form-header stuck-header">
                  <div class="form-header-inner">
                    <div class="right">
                      <div class="buttons">
                        <button
                          @click="$router.go(-1)"
                          class="button h-button is-light is-dark-outlined"
                        >
                          <span class="icon">
                            <i class="lnir lnir-arrow-left rem-100"></i>
                          </span>
                          <span>Batal</span>
                        </button>
                        <button
                          class="button h-button is-primary is-raised"
                          @click.prevent="sendUser"
                          :disabled="isFormInvalid"
                        >
                          Simpan
                        </button>

                        <button
                          class="button h-button is-warning is-raised"
                          @click.prevent="blockUser"
                          :disabled="isFormInvalid"
                        >
                          {{ isBlocked ? "Cabut Blokir" : "Blokir" }}
                        </button>
                        <button
                          class="button h-button is-danger is-raised"
                          @click.prevent="deletUser"
                        >
                          Hapus
                        </button>
                        <button
                          class="button h-button is-success is-raised"
                          @click.prevent="cabutPos"
                          :disabled="isUserHavePos"
                        >
                          Cabut Pos
                        </button>
                        <ChangePasswordUser :cred="form.credential" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-body">
                  <div class="left">
                    <h3>Form Update User</h3>
                  </div>
                  <!--Fieldset-->
                  <div class="form-fieldset">
                    <div class="columns is-multiline">
                       <div class="column is-12">
                        <div class="field">
                          
                          <div class="control has-icon">
                             <span
                        class="tag is-danger  is-rounded"
                        v-if="isBlocked"
                        >BLOCKED</span
                      >
                      <span class="tag is-success is-rounded" v-else>
                        UNBLOCKED 
                      </span>
                            
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="field">
                          <label>Email</label>
                          <div class="control has-icon">
                            <input
                              @blur="$v.form.email.$touch()"
                              v-model="form.email"
                              type="text"
                              class="input"
                              placeholder="your@mail.address"
                            />
                            <div class="form-icon">
                              <i class="lnil lnil-envelope-alt"></i>
                            </div>
                            <div v-if="$v.form.email.$error">
                              <span
                                v-if="!$v.form.email.required"
                                class="help is-danger"
                                >Email Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="field">
                          <label>Username</label>
                          <div class="control has-icon">
                            <input
                              @blur="$v.form.username.$touch()"
                              v-model="form.username"
                              type="text"
                              class="input"
                              placeholder="username"
                            />
                            <div class="form-icon">
                              <i class="lnil lnil-user-alt-1"></i>
                            </div>
                            <div v-if="$v.form.username.$error">
                              <span
                                v-if="!$v.form.username.required"
                                class="help is-danger"
                                >Username Harus Diisi</span
                              >
                              <span
                                v-if="!$v.form.username.minLength"
                                class="help is-danger"
                                >Username Min 6 Karakter</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="field">
                          <label>Name</label>
                          <div class="control has-icon">
                            <input
                              @blur="$v.form.name.$touch()"
                              v-model="form.name"
                              type="text"
                              class="input"
                              placeholder="name"
                            />
                            <div class="form-icon">
                              <i class="lnil lnil-user-alt-1"></i>
                            </div>
                            <div v-if="$v.form.name.$error">
                              <span
                                v-if="!$v.form.name.required"
                                class="help is-danger"
                                >Name Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-fieldset">
                    <div class="fieldset-heading">
                      <h4>Informasi Pos</h4>
                      <h6>
                        Apabila Roles user adalah PENGAMAT atau DINAS pastikan
                        dipilih pos terkait
                      </h6>
                    </div>
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="field">
                          <label>Roles</label>
                          <div class="control has-icon">
                            <select
                              v-model="form.authorityType"
                              type="text"
                              class="input"
                              placeholder="Pilih Roles"
                            >
                              <option disabled>Pilih Roles</option>
                              <option>ADMIN</option>
                              <option>OPERATOR</option>
                              <option>PENGAMAT</option>
                              <option>DINAS</option>
                            </select>

                            <!-- <div v-if="$v.form.roles.$error">
                                                <span v-if="!$v.form.roles.required" class="help is-danger"
                                                  >Roles Harus Diisi</span
                                                >
                                              </div> -->
                          </div>
                        </div>
                      </div>
                      <div
                        class="column is-6"
                        v-if="form.authorityType === 'PENGAMAT'"
                      >
                        <div class="field">
                          <div class="control is-expanded">
                            <label class="label">Pos</label>
                            <div class="select is-normal">
                              <select
                                v-model="form.registerId"
                                type="text"
                                class="input"
                                aria-placeholder="Pilih Jenis Pos"
                              >
                                <option
                                  v-for="(kadaster, idx) in kadasters"
                                  :key="idx"
                                  :value="kadaster.registerId"
                                >
                                  {{ kadaster.namaPos }}
                                </option>
                                
                              </select>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="column is-12"
                        v-if="form.authorityType === 'DINAS'"
                      >
                        <div class="field">
                          <div class="control">
                            <label class="label">Pos</label>
                            <div class="select is-normal">
                              <select
                                v-model="form.registerId"
                                type="text"
                                class="input"
                                aria-placeholder="Pilih Jenis Pos"
                              >
                                <option
                                  v-for="(posDinas, idx) in posDinas"
                                  :key="idx"
                                  :value="posDinas.registerId"
                                >
                                  {{ posDinas.namaPos }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Fieldset-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
import ChangePasswordUser from "./ChangePasswordUser.vue";

export default {
  name: "FormInputUser",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    // AppSpinner
    ChangePasswordUser,
  },

  data() {
    return {
      show: false,
      sungaiData: [],
      form: {
        credential: null,
        email: null,
        username: null,
        name: null,
        authorityType: null,
        registerId: null,
      },
      isBlocked: false,
      keepFirst: false,
      openOnFocus: false,
      selected: null,
      clearable: false,
      isFetching: false,
    };
  },
  validations: {
    form: {
      username: { required, minLength: minLength(6) },
      name: { required },
      authorityType: { required },
      email: { required, email },
    },
  },
  created() {
    
    this.loadAsyncData();
    this.getKadasters();
    this.getPos();
  },
  computed: {
    ...mapState({
      kadasters: (state) => state.kadasters.items,
      users: (state) => state.users.items,
      posDinas: (state) => state.posDinas.items,
    }),
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
    isUserHavePos(){
      return !this.form.registerId;
    }
  },

  methods: {
    ...mapActions("users", ["fetchUserByCredential"]),
    ...mapActions("kadasters", ["getKadasters"]),
    ...mapActions("posDinas", ["getPos"]),
    loadAsyncData() {
      const credential = this.$route.params.credential;
      this.fetchUserByCredential(credential).then((res) => {
      this.form = res;
      this.isBlocked = res.isBlocked;
    });
    },
    sendUser() {
      console.log(this.form);

      this.$store
        .dispatch("users/updateUser", this.form)
        .then(() => {
          // this.$emit("kadasterAddSubmitted", this.form)
          this.$toasted.success("Update Data user Berhasil", {
            duration: 5000,
          });
          this.$router.push("/dashboard/users");
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 3000 });
        });
    },
    deletUser() {
      this.$swal({
        title: "User Akan Dihapus?",
        text: "Setelah dihapus anda tidak dapat mengembalikannya!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#c0392b",
        confirmButtonText: "YA, HAPUS!",
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if confirmed
          this.$store.dispatch("users/deletUser", this.form.credential);
          this.$router.push("/dashboard/users");
          this.$toasted.success("Delete User Success", { duration: 5000 });
        }
      });
    },
    blockUser() {
      this.$swal({
        title: "apakah anda setuju?",
        text: this.isBlocked
          ? "user akan di UnBlock, klik Unblock untuk Buka blokir!"
          : "User akan diblokir",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#c0392b",
        confirmButtonText: this.isBlocked ? "YA, Unblock!" : "YA, Blokir!",
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if confirmed
          this.$store.dispatch("users/blockUser", this.form.credential);
          this.$router.push("/dashboard/users");           
          this.$toasted.success(
          this.isBlocked
              ? "User berhasil di Unblock!"
              : "User berhasil di Blokir!",
            { duration: 5000 }
          );
        }
      });
    },
    cabutPos() {
      this.$swal({
        title: "apakah anda yakin?",
        text: "User akan dilepas dari Pos",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#c0392b",
        confirmButtonText: "YA, Lepas!",
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if confirmed
          this.$store.dispatch("users/removePos", this.form.credential);
          this.$router.push("/dashboard/users");
          this.$toasted.success("Pos berhasil di Cabut!",
            { duration: 5000 }
          );
        }
      });
    },
  },
};
</script>

 <style scoped>
.icons {
  position: absolute;
  margin-top: 70px;
  margin-left: 310px;
  cursor: pointer;
  z-index: 10000;
}
</style>