<template>
  <div class="modal-channel">
    <button
      class="button is-normal is-link is-focused"
      @click="isOpen = !isOpen"
      type="is-success"
      outlined
    >
      <i class="fas fa-search"></i><span>Cari Data</span>
    </button>

    <div :class="['modal', { 'is-active': isOpen }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Tabel Data Pos Hidrologi PCH Tahunan</p>
          <!-- TODO: Close Modal set isOpen to false -->
          <button
            @click="isOpen = false"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <form>

            <div class="form">
              <div class="field">
                <div class="control">
                  <label class="label">Periode</label>
                  <div class="periode">
                    <date-picker
                      class="periode"
                      @blur="$v.form.tahun.$touch()"
                      v-model="form.tahun"
                      format="YYYY"
                      valueType="YYYY"
                      type="year"
                      placeholder="Pilih Tahun"
                    ></date-picker>
                    <div v-if="$v.form.tahun.$error">
                      <span
                        v-if="!$v.form.tahun.required"
                        class="help is-danger"
                        >Periode Harus Diisi</span
                      >
                    </div>
                  </div>
                </div>
              </div>

            <div class="is-grup">
              <div class="field">
                <div class="control">
                  <button
                    :disabled="isFormInvalid"
                    class="button is-normal is-link is-focused buttons1"
                    @click.prevent="cariData"
                  >
                    <span class="titles">
                      <i class="fas fa-search"></i>
                      Lihat Data
                    </span>
                  </button>
                </div>
              </div>
            </div>   

            </div>

            <ModalLoading :buka="isOpenModal"/>

             <div 
              style="overflow-x:auto;"
              class="table-wrapper">

              <table class="table is-datatable is-hoverable table-is-bordered">
            <thead class="is-primary">
              <tr class="head">
                <th>TANGGAL</th>
                <th>JAN</th>
                <th>FEB</th>
                <th>MAR</th>
                <th>APR</th>
                <th>MEI</th>
                <th>JUN</th>
                <th>JUL</th>
                <th>AGS</th>
                <th>SEP</th>
                <th>OKT</th>
                <th>NOV</th>
                <th>DES</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(hidro, index) in dataTahunan" :key="index">
               <td 
                class="tanggal"
                data-label="TANGGAL">{{hidro.tanggal}}</td>
                <td data-label="JANUARI">{{hidro.januari}}</td>
                <td data-label="FEBUARI">{{hidro.februari}}</td>
                <td data-label="MARET">{{hidro.maret}}</td>
                <td data-label="APRIL">{{hidro.april}}</td>
                <td data-label="MEI">{{hidro.mei}}</td>
                <td data-label="JUNI">{{hidro.juni}}</td>
                <td data-label="JULI">{{hidro.juli}}</td>
                <td data-label="AGUSTUS">{{hidro.agustus}}</td>
                <td data-label="SEPTEMBER">{{hidro.september}}</td>
                <td data-label="OKTOBER">{{hidro.oktober}}</td>
                <td data-label="NOVEMBER">{{hidro.november}}</td>
                <td data-label="DESEMBER">{{hidro.desember}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td 
                data-label="MIN"><span class="title">Min</span></td>
                <td data-label="JANUARI">{{min.januari}}</td>
                <td data-label="FEBUARI">{{min.februari}}</td>
                <td data-label="MARET">{{min.maret}}</td>
                <td data-label="APRIL">{{min.april}}</td>
                <td data-label="MEI">{{min.mei}}</td>
                <td data-label="JUNI">{{min.juni}}</td>
                <td data-label="JULI">{{min.juli}}</td>
                <td data-label="AGUSTUS">{{min.agustus}}</td>
                <td data-label="SEPTEMBER">{{min.september}}</td>
                <td data-label="OKTOBER">{{min.oktober}}</td>
                <td data-label="NOVEMBER">{{min.november}}</td>
                <td data-label="DESEMBER">{{min.desember}}</td>
              </tr>
               <tr>
                <td 
                data-label="MAKS"><span class="title">Maks</span></td>
                 <td data-label="JANUARI">{{max.januari}}</td>
                <td data-label="FEBUARI">{{max.februari}}</td>
                <td data-label="MARET">{{max.maret}}</td>
                <td data-label="APRIL">{{max.april}}</td>
                <td data-label="MEI">{{max.mei}}</td>
                <td data-label="JUNI">{{max.juni}}</td>
                <td data-label="JULI">{{max.juli}}</td>
                <td data-label="AGUSTUS">{{max.agustus}}</td>
                <td data-label="SEPTEMBER">{{max.september}}</td>
                <td data-label="OKTOBER">{{max.oktober}}</td>
                <td data-label="NOVEMBER">{{max.november}}</td>
                <td data-label="DESEMBER">{{max.desember}}</td>
              </tr>
               <tr>
                <td 
                data-label="RATA-TATA"><span class="title">Rata-rata</span></td>
                <td data-label="JANUARI">{{rerata.januari}}</td>
                <td data-label="FEBUARI">{{rerata.februari}}</td>
                <td data-label="MARET">{{rerata.maret}}</td>
                <td data-label="APRIL">{{rerata.april}}</td>
                <td data-label="MEI">{{rerata.mei}}</td>
                <td data-label="JUNI">{{rerata.juni}}</td>
                <td data-label="JULI">{{rerata.juli}}</td>
                <td data-label="AGUSTUS">{{rerata.agustus}}</td>
                <td data-label="SEPTEMBER">{{rerata.september}}</td>
                <td data-label="OKTOBER">{{rerata.oktober}}</td>
                <td data-label="NOVEMBER">{{rerata.november}}</td>
                <td data-label="DESEMBER">{{rerata.desember}}</td>
              </tr>
            </tfoot>
          </table>

            </div>

          </form>

        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <div class="control btn1">
              <button 
              class="button is-success is-focused"
              :disabled="isShow"
              @click.prevent="onGenerateExcel"
              >
                <i class="fas fa-file-excel"></i>
                 Unduh Excel
                <i class="fas fa-angle-down"></i>
              </button>
            </div>

            <div class="control btn1">
              <button
                @click="isOpen = false"
                class="button is-normal is-danger is-focused"
              >
                <span> Batal </span>
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>


<script>

import { required } from "vuelidate/lib/validators"; 
import { mapActions, mapState } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ModalLoading from "../shared/ModalLoading"


export default {
  components: {
    DatePicker,
    ModalLoading
  },
    props: {
    registerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpenModal: false,
      isOpen: false,
      isShow: true,
      dataTahunan: [], 
      min: '', 
      max: '', 
      rerata: '',
      form: {
        registerId: null,
        tahun: null,
        
      },
    };
  },
  validations: {
    form: {
        tahun: {
          required,
      },
    },
  },
  created() { 
  //  
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
      ...mapState({
     hidroTahunan: (state) => state.hidrologiTahunan.item,
     fileName: (state) => state.posDinasGeneratePch.item,
    }),
  },
  methods: {
      ...mapActions("hidrologiTahunan", ["fetchHidrologiPchTahunan"]),
      ...mapActions("posDinasGeneratePch", ["generatePchTahunan","downloadExcelFile"]),
     cariData(){   
      this.form.registerId = this.registerId;           
      this.fetchHidrologiPchTahunan(this.form)
      .then(() => {
          this.dataTahunan = this.hidroTahunan.dataTahunan;
          this.min = this.hidroTahunan.min;
          this.max = this.hidroTahunan.max;
          this.rerata = this.hidroTahunan.rerata; 
          let currentTotal = this.dataTahunan.length; 
            if (currentTotal / this.perPage > 1000) {
              currentTotal = this.perPage * 1000;
            }
             if(currentTotal > 0){
              this.isShow = false
            }else{
              this.isShow = true
            }
        })
        .catch(error => {         
            this.$toasted.error(error.response.data.message, {duration: 3000})
        }); 
    },
    onGenerateExcel() {
      this.form.registerId = this.registerId; 
      this.isOpenModal = true,
      this.isShow = true 
      this.generatePchTahunan(this.form).then(() => {              
            let filename = this.fileName;  
            this.downloadExcelFile(filename);
            this.isOpenModal = false  
          });
       
       }
    },

    
    
  
};
</script>

<style scoped>

.modal {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}



.modal-card
{
  width: 1200px;  
}


.modal-card-title {
  font-size: 18px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
}

.form {
  display: flex;
  margin-left: 10px;
  padding-bottom: 10px;
  padding-top: 20px;
}

.is-grup
{  
   padding-top: 30px; 
}

.modal-card-foot
{
   display: flex;
   justify-content: flex-end;
}

.tables
{
   border: 1.5px solid #000;
}

.title
{
  font-size: 14px;
  font-weight: 500;
}

/* thead {
  border-top: 1.5px solid #70707046;
} */

th {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.head {
  background-color: #ffff;
}

td {
  font-size: 14px;
  font-weight: 400;
}

tfoot {
  font-size: 12px;
  font-weight: 400;
}

.periode {
  margin-right: 10px;
}

select {
  max-height: 37px;
  width: 120px;
  margin-top: -1px;
}


.button {
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  width: 150px;
  letter-spacing: 1px;
  margin-left: 10px;
}

.buttons1
{
  height: 35px;
  width: 210px;  
}

.kembali i
{
  margin-left: 10px;
  margin-right: 10px;  
}

i {
 margin-left: 10px;
 margin-right: 10px;
}

.fal {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .modal-card {
    height: 600px;
    width: 350px;
  }

  .modal-card-title {
  font-size: 16px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 0;
}


  .btn1 {
    margin-left: -10px;
    padding-bottom: 5px;
  }

  .form
{
  display: flex;
  flex-direction: column;
  margin-left: 0px;
}

.periode {
  margin-left: 0px;
}

.is-grup
{
   padding-top: 0px; 
   margin-left: -10px;
   padding-bottom: 40px;
}

 
}
</style>