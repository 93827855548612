import axios from '../../conf/axios';

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fetchLandingPos({ state, commit }) {
            commit('setItems', { resource: 'landingPosDinas', items: [] }, { root: true })
            return axios.get('/api/landing/pos/dinas/search')
                .then(res => {
                    const dataList = res.data.pos           
                    commit('setItems', { resource: 'landingPosDinas', items: dataList }, { root: true })
                    return state.items
                })
        }, 
        fetchLandingPosById({state, commit}, registerId){            
            commit('setItem', {resource: 'landingPosDinas', item: {}}, {root: true})
            return axios.get(`/api/landing/pos/dinas/view/${registerId}`)
            .then(res => {
                const landingKadaster = res.data                 
                commit('setItem', {resource: 'landingPosDinas', item: landingKadaster}, {root: true})
                return state.item
             })
        },
      
              
    }
}