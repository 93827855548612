
import axiosInstance from '../../conf/axiosAdmin';
import moment from "moment";

export default {
    namespaced: true,
    state: {
        items: [],
    },
    getters: {

    },
    actions: {
        async fetchPch({ state, commit }, registerId) {
            commit('setItems', { resource: 'kanalPch', items: [] }, { root: true })
            return await axiosInstance.get(`/api/channel/view/pch/${registerId}`)
                .then(res => {
                    let payloads = res.data.channels
                    const channels = [];
                    payloads.forEach((item) => {
                            item.tanggal = moment(String(item.tanggal)).format('YYYY-MM-DD HH:mm:ss')
                            channels.push(item);
                        });
                    commit('setItems', { resource: 'kanalPch', items: channels }, { root: true })
                    return state.items
                })
        },
        async fetchPchHourly({ state, commit }, payload) {
            commit('setItems', { resource: 'kanalPch', items: [] }, { root: true })
            return await axiosInstance.post(`/api/channel/search/hourly/pch/${payload.registerId}`, payload.search)
                .then(res => {
                    const pchchannels = res.data.channels
                    commit('setItems', { resource: 'kanalPch', items: pchchannels }, { root: true })
                    return state.items
                })
        },
        async fetchPchDaily({ state, commit }, payload) {
            commit('setItems', { resource: 'kanalPch', items: [] }, { root: true })
            return await axiosInstance.post(`/api/channel/search/daily/pch/${payload.registerId}`, payload.search)
                .then(res => {
                    const pchchannels = res.data.channels
                    commit('setItems', { resource: 'kanalPch', items: pchchannels }, { root: true })
                    return state.items
                })
        },
        addPch({ state, commit }, payload) {
            let index = state.items.length
            let channel = payload
            channel.tanggal = moment(String(channel.tanggal)).format('YYYY-MM-DD HH:mm:ss')
            commit('addItemToArray', { item: channel, index, resource: 'kanalPch' }, { root: true })
            return state.items
        },
    }
}