import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js' 


export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fileUpload(context, payload) {
            const headers = {
                'Content-Type': 'multipart/form-data',
            }
            return axiosInstance.post('/api/cluster/file/upload',
                payload, {
                headers: headers
            })
                .then(res => res.data)
        },
       async fileDownlaod({state, commit}, payload){
            commit('setItem', {resource: 'clusterFile', item: {}}, {root: true})
            return await axiosInstance.get(`/api/cluster/file/download/${payload}`)
             .then(res => {
                 const filename = res.data
                 commit('setItem', {resource: 'clusterFile', item: filename}, {root: true})
                 return state.item
              })
        }
        
    },
    mutations: {

    }
}