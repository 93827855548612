import axios from '../../conf/axios';
// import ApiMethods from '@/conf/config.js'

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {
        fetchLandingMonitor({ state, commit }) {
            commit('setItems', { resource: 'monitorPda', items: [] }, { root: true })
            return axios.get('/api/landing/monitor/pda')
                .then(res => {
                    const pos = res.data.pos                     
                    commit('setItems', { resource: 'monitorPda', items: pos }, { root: true })
                    return state.items
                })
        },
        dispatchPdaMonitor({state, commit}, payload){   
            const registerId = payload.registerId;         
            commit('removeItemFromArray', {resource: 'monitorPda', registerId: registerId},{root: true}); 
            return state.items
        },
        postPdaMonitor({state, commit}, payload){            
            let index = state.items.length
            // let tmaCheck = state.items.some(item => item.tma >= payload.tma)  
 
            // if(tmaCheck){
            //   index = state.items.length
            // }else{
            //   index = 0;
            // }
            
            commit('addItemToArray', {item: payload, index, resource: 'monitorPda'}, {root: true})             
            return state.items
          },
            
    },
    mutations: {

    }
}