import axiosInstance from '../../conf/axiosAdmin';
// import ApiMethods from '../../conf/config.js' 

export default {
    namespaced: true,
    state: {
        items: [],
        item: {}
    },
    getters: {

    },
    actions: {            
        fetchPchManual({state,commit}, registerId) {
            commit('setItems', {resource: 'hidrologiPchLanding', items: []}, {root: true})
            return axiosInstance.get(`/api/landing/hidrologi/search/pch/${registerId}`)
            .then(res => {
                const hidrologi = res.data.kanalList
                commit('setItems', {resource: 'hidrologiPchLanding', items: hidrologi}, {root: true})
                return state.items
                })
        },
         
        

    },
    mutations: {

    }
}