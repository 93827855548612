<template>
<div> 
    <ModalPosDinas/>  
    <!-- <MobileNavbar />
    <MobileSidebarMain />
    <Sidebar /> -->
    <!-- <PosDinasMap/> -->
    <PosDinasMapBaru />
</div> 
</template>

<script>
// import Sidebar from "../components/navigation/Sidebar.vue";
// import MobileNavbar from "../components/navigation/MobileNavbar.vue";
// import MobileSidebarMain from "../components/navigation/MobileSidebarMain.vue";
// import PosDinasMap from "../components/landing/PosDinasMap.vue";
import ModalPosDinas from "../components/landing/ModalPosDinas.vue"
import PosDinasMapBaru from "../components/landing/PosDinasMapBaru.vue"

export default {
   name: 'PosDinasLanding',
   components: {       
    // Sidebar,
    // MobileNavbar,
    // MobileSidebarMain,
    // PosDinasMap,
     ModalPosDinas,
    PosDinasMapBaru  
  },
}
</script>