<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
            <div
              class="huro-hamburger nav-trigger push-resize"
              data-sidebar="layouts-sidebar"
            >
              <span class="menu-toggle has-chevron">
                <span class="icon-box-toggle">
                  <span class="rotate">
                    <i class="icon-line-top"></i>
                    <i class="icon-line-center"></i>
                    <i class="icon-line-bottom"></i>
                  </span>
                </span>
              </span>
            </div>

            <div class="title-wrap">
              <h1 class="title is-4">Data Informasi Pengumuman.</h1>
            </div>
          </div>

          <!--Header-->

          <div class="page-content-inner">
            <div class="lifestyle-dashboard lifestyle-dashboard-v4">
              <!--Header-->
              <div class="column is-12">
                <div class="illustration-header-2">
                  <div class="header-image">
                    <img
                      src="../../assets/img/hand.png"
                      alt=""
                      width="250"
                      height="250"
                    />
                  </div>
                  <div class="header-meta">
                    <h3>Data Pengumuman.</h3>
                    <p>
                      Data Informasi Pengumuman yang dikelola oleh BBWS Cimanuk
                      - Cisanggarung.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-flex-toolbar">
              <div class="control has-icon">
                <input
                  class="input custom-text-filter"
                  placeholder="Search..."
                  v-model="cariJudul"
                  @keydown="cari"
                />
                <div class="form-icon">
                  <i class="fa fa-search"></i>
                </div>
              </div>

              <div class="tabs-inner">
                <router-link
                  to="/dashboard/inputpengumuman"
                  class="button h-button is-success is-outlined is-raised"
                >
                  <i class="fal fa-plus"></i>
                  <span class="tambah">Tambah Pengumuman</span>
                </router-link>
              </div>
            </div>

            <div class="page-content-inner">
              <div class="flex-list-wrapper flex-list-v2">
                <!--Active Tab-->
                <div
                  id="active-items-tab"
                  class="tab-content is-active"
                  v-if="!isLoading"
                >
                  <!-- Datatable -->
                  <div class="table-wrapper">
                    <b-table
                      class="table is-datatable is-hoverable table-is-bordered"
                      :data="isEmpty ? [] : data"
                      :bordered="isBordered"
                      :striped="isStriped"
                      :narrowed="isNarrowed"
                      :hoverable="isHoverable"
                      :loading="isLoading"
                      :focusable="isFocusable"
                      :mobile-cards="hasMobileCards"
                    >
                      <b-table-column
                        field="title"
                        label="Judul"
                        :td-attrs="columnTdAttrs"
                        v-slot="props"
                      >
                        {{ props.row.title }}
                      </b-table-column>

                      <b-table-column
                        field=""
                        label="Status"
                        :td-attrs="columnTdAttrs"
                        v-slot="props"
                      >
                        <!-- <span class="tag is-success" v-if="props.row.isActive">
                          {{ props.row.isActive ? "AKTIF" : "TIDAK AKTIF" }}
                        </span>
                        <span class="tag is-danger" v-else>
                          {{ props.row.isActive ? "AKTIF" : "TIDAK AKTIF" }}
                        </span> -->
                        <b-switch
                              type="is-success"
                              passive-type="is-danger"
                              size="is-medium"
                              v-model="props.row.isActive"
                              @input="buttonActive(props.row.id, props.row.isActive)"
                            >
                            </b-switch> 
                      </b-table-column>

                      <b-table-column field="id" label="Action" v-slot="props">
                        <router-link
                          class="button h-button is-info is-elevated"
                          :to="`/dashboard/updatepengumuman/${props.row.id}`"
                        >
                          <span class="icon">
                            <i aria-hidden="true" class="lnir lnir-scan"></i>
                          </span>
                          <span>pratinjau</span>
                        </router-link>
                      </b-table-column>

                      <template #empty>
                        <div class="has-text-centered">No records</div>
                      </template>
                    </b-table>
                  </div>

                  <!--Table Pagination-->
                  <div class="buttons is-right">
                    <b-button
                      @click="decrement"
                      type="is-info"
                      icon-pack="fas"
                      icon-left="chevron-left"
                      :disabled="hasDisablePreviousPage"
                    ></b-button>
                    <b-button
                      @click="increment"
                      type="is-info"
                      icon-pack="fas"
                      icon-left="chevron-right"
                      :disabled="hasDisableNextPage"
                    ></b-button>
                  </div>
                </div>

                <!--inactive Tab-->
                <div
                  id="active-items-tab"
                  class="tab-content is-active"
                  v-if="isLoading"
                >
                  <!--Empty placeholder-->
                  <div class="page-placeholder">
                    <div class="placeholder-content">
                      <img
                        class="light-image is-medium"
                        src="../../assets/image/gambar.jpg"
                        alt=""
                      />
                      <img
                        class="dark-image is-medium"
                        src="../../assets/image/gambar.jpg"
                        alt=""
                      />
                      <h3>Tidak Ada Data.</h3>
                      <p>Tidak ada data pengumuman yang ditampilkan.</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- END content iner -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";

export default {
  name: "PengumumanList",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
  },
  data() {
    return {
      inputCari: "",
      cariJudul: "",
      data: [],
      total: 0,
      loading: false,
      sortField: "created",
      sortOrder: "DESC",
      defaultSortOrder: "DESC",
      page: 0,
      perPage: 10,
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
    };
  },
  created() {
    this.fetchPengumuman().then(() => {
      this.isLoaded = true;
    });
  },

  computed: {
    ...mapState({
      results: (state) => state.pengumuman.items,
    }),
  },
  methods: {
    ...mapActions("pengumuman", ["fetchPengumuman", "searchPengumuman"]),

    loadAsyncData() {
        const params = [
        `title=${this.cariJudul}`,
        `page=${this.page}`,
        `sortType=${this.sortOrder}`,
        `limit=${this.perPage}`,
      ].join("&");
      this.searchPengumuman(params).then(() => {
        let response = this.results;
        this.data = [];
        let enablePreviousPage = response.hasPreviousPage;
        let enableNextPage = response.hasNextPage;
        if (enablePreviousPage) {
          this.hasDisablePreviousPage = false;
        } else {
          this.hasDisablePreviousPage = true;
        }

        if (enableNextPage) {
          this.hasDisableNextPage = false;
        } else {
          this.hasDisableNextPage = true;
        }

        response.annoucements.forEach((item) => {
          this.isLoading = false;
          this.data.push(item);
        });
        // .catch((error) => {
        //   this.data = [];
        //   this.total = 0;
        //   this.loading = false;
        //   throw error;
        // });
      });
    },

    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
    cari() {
      this.loadAsyncData();
    },
    dateThAttrs(column) {
      return column.label === "Date"
        ? {
            title: 'This title is sponsored by "th-attrs" prop',
            class: "has-text-success",
          }
        : null;
    },
    columnTdAttrs(row, column) {
      if (row.id === "Total") {
        if (column.label === "ID") {
          return {
            colspan: 4,
            class: "has-text-weight-bold",
            style: {
              "text-align": "left !important",
            },
          };
        } else if (column.label === "Gender") {
          return {
            class: "has-text-weight-semibold",
          };
        } else {
          return {
            style: { display: "none" },
          };
        }
      }
      return null;
    },
    remove(index) {
      console.log("index" + index);
    },

    buttonActive(id, isActive) {
      const buttonActive = { id: id, isActive: isActive };
      this.$store.dispatch("pengumuman/deactivePengumumanById", buttonActive);
      this.loadAsyncData();
      // console.log(buttonActive);

    },
  },
  mounted() {
    this.loadAsyncData();
  },
};
</script>

<style scoped>
.fal {
  margin-right: 10px;
  font-weight: 400;
}
</style>