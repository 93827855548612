<template>
  <div>
    <DinasSidebarNav />
    <MobileNavbarDash />
    <DinasMobileSidebarDash />
    
    <!-- Content Wrapper -->
    <div
      id="app-project"
      class="view-wrapper"
      data-naver-offset="214"
      data-menu-item="#layouts-sidebar-menu"
      data-mobile-item="#home-sidebar-menu-mobile"
    >
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
            <div
              class="huro-hamburger nav-trigger push-resize"
              data-sidebar="layouts-sidebar"
            >
              <span class="menu-toggle has-chevron">
                <span class="icon-box-toggle">
                  <span class="rotate">
                    <i class="icon-line-top"></i>
                    <i class="icon-line-center"></i>
                    <i class="icon-line-bottom"></i>
                  </span>
                </span>
              </span>
            </div>

            <div class="title-wrap">
              <h1 class="title is-4">Pos Dinas</h1>
            </div>
          </div>

          <div class="page-content-inner project-details">
            <div class="tabs-wrapper is-slider">
              <div class="tabs-inner">
                <div class="tabs">
                  <ul>
                    <!-- <li data-tab="project-tab" class="is-active"> -->
                    <li v-bind:class="{ 'is-active': isActive == 'Detail' }">
                      <a v-on:click="isActive = 'Detail'"
                        ><span>Detail</span></a
                      >
                    </li>
                    <!-- <li data-tab="team-tab"> -->
                    <li
                      v-bind:class="{ 'is-active': isActive == 'HidrologiPda' }"
                      v-if="jenisPos == 'PDA'"
                    >
                      <a v-on:click="isActive = 'HidrologiPda'"
                        ><span>PDA</span></a
                      >
                    </li>
                    <li
                      v-bind:class="{ 'is-active': isActive == 'HidrologiPch' }"
                      v-if="jenisPos == 'PCH'"
                    >
                      <a v-on:click="isActive = 'HidrologiPch'"
                        ><span>PCH</span></a
                      >
                    </li>
                    <li class="tab-naver"></li>
                  </ul>
                </div>
              </div>

              <!-- <div id="project-tab" class="tab-content is-active"> -->
              <div
                class="tab-content"
                v-bind:class="{ 'is-active': isActive == 'Detail' }"
              >
                <div class="columns project-details-inner">
                  <div class="column is-8">
                    <div class="project-details-card">
                      <div class="card-head">
                        <div class="title-wrap">
                          <h3>{{ posKedinasan.namaPos }}</h3>
                          <p>{{ posKedinasan.lokasiPos }}</p>
                        </div>
                      
                      </div>

                      <div class="column is-12">
                        <div class="illustration-header-2">
                          <img
                            src="../../assets/image/excel-file.png"
                            height="90"
                            width="100"
                            alt=""
                          />
                          <div class="header-meta">
                            <h3>Batch File Excel.</h3>
                            <p v-if="posKedinasan.jenisPos === 'PDA'">
                              Data diinput oleh Administrator Pos Dinas Terkait
                              untuk pengumpulan data hidrologi manual. Satuan
                              untuk penginputan Pos Duga Air dalam
                              Centimeter(Cm) .
                            </p>
                            <p v-else>
                              Data diinput oleh Administrator Pos Dinas Terkait
                              untuk pengumpulan data hidrologi manual. Satuan
                              untuk penginputan Pos Curah Hujan dalam
                              milimeter(mm) .
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="project-files">
                        <h4>Recent Files</h4>
                        <div class="columns is-multiline">
                          <TablebatchDinas />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="column is-4">
                    <div class="side-card">
                      <h4>Informasi Pos</h4>
                      <div class="active-team">
                        <table class="table">
                          <tbody>
                            <tr>
                              <td>Nama</td>
                              <td>:</td>
                              <td>{{ posKedinasan.namaPos }}</td>
                            </tr>
                            <tr>
                              <td>Pengelola</td>
                              <td>:</td>
                              <td>{{ posKedinasan.dinasPengelola }}</td>
                            </tr>
                            <tr>
                              <td>no Register</td>
                              <td>:</td>
                              <td>{{ posKedinasan.kodeRegister }}</td>
                            </tr>
                            <tr>
                              <td>Lokasi</td>
                              <td>:</td>
                              <td>{{ posKedinasan.lokasiPos }}</td>
                            </tr>

                            <tr>
                              <td>DAS</td>
                              <td>:</td>
                              <td>
                                {{ posKedinasan.namaDas }}
                              </td>
                            </tr>
                            <tr>
                              <td>Sungai</td>
                              <td>:</td>
                              <td>
                                {{ posKedinasan.namaSungai }}
                              </td>
                            </tr>
                            <tr>
                              <td>Latitude</td>
                              <td>:</td>
                              <td>
                                {{ posKedinasan.lat }}
                              </td>
                            </tr>
                            <tr>
                              <td>Longitude</td>
                              <td>:</td>
                              <td>
                                {{ posKedinasan.lng }}
                              </td>
                            </tr>
                            <tr>
                              <td>Jenis Pos</td>
                              <td>:</td>
                              <td>
                                {{
                                  posKedinasan.jenisPos === "PDA"
                                    ? "POS DUGA AIR"
                                    : "POS CURAH HUJAN"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="side-card">
                      <h4>Unduh Format Template File Excel</h4>

                      <div class="media-flex-center">
                        <b-field>
                          <div class="grup">
                            <div class="buttons">
                              <b-button
                                class="button1 is-success is-focused"
                                type="is-link"
                                outlined
                                v-if="posKedinasan.jenisPos === 'PDA'"
                                ><a
                                  href="/files/POS_TEMPLATE_PDA.xlsx"
                                  download
                                >
                                  <span class="title1">Unduh File PDA</span>
                                </a>
                              </b-button>
                              <b-button
                                class="button1 is-success is-focused"
                                type="is-link"
                                outlined
                                v-else
                                ><a
                                  href="/files/POS_TEMPLATE_PCH.xlsx"
                                  download
                                >
                                  <span class="title1">Unduh File PCH</span>
                                </a>
                              </b-button>
                            </div>
                          </div>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--Project Team-->
              <!-- <div id="team-tab" class="tab-content"> -->
              <div
                class="tab-content"
                v-bind:class="{ 'is-active': isActive == 'HidrologiPda' }"
              >
                <div class="page-content-inner all-projects">
                  <div class="lifestyle-dashboard lifestyle-dashboard-v4">
                    <!--Header-->
                    <div class="column is-12">
                      <div class="illustration-header-2">
                        <div class="header-image">
                          <img
                            src="../../assets/img/illustrations/placeholders/search-1-dark.svg"
                            alt=""
                            width="220"
                          />
                        </div>
                        <div class="header-meta">
                          <h3>Data Pos Hidrologi.</h3>
                          <p>
                            Data Pos hidrologi Duga Air diinput oleh pengamat
                            pos dilapangan pada waktu-waktu tertentu.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="task-grid">
                    <detail-pda />
                  </div>
                </div>
              </div>

              <!-- END PDA -->
              <div
                class="tab-content"
                v-bind:class="{ 'is-active': isActive == 'HidrologiPch' }"
              >
                <div class="page-content-inner all-projects">
                  <div class="lifestyle-dashboard lifestyle-dashboard-v4">
                    <!--Header-->
                    <div class="column is-12">
                      <div class="illustration-header-2">
                        <div class="header-image">
                          <img
                            src="../../assets/img/illustrations/placeholders/search-1-dark.svg"
                            alt=""
                            width="220"
                          />
                        </div>
                        <div class="header-meta">
                          <h3>Data Pos Hidrologi.</h3>
                          <p>
                            Data Pos hidrologi Duga Air diinput oleh pengamat
                            pos dilapangan pada waktu-waktu tertentu.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="task-grid">
                    <detail-pch/>
                  </div>
                </div>
              </div>
              <!-- END PCH -->
              <!-- END Template -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import DinasSidebarNav from "../dashboardnav/DinasSidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import DinasMobileSidebarDash from "../dashboardnav/DinasMobileSidebarDash.vue";
import TablebatchDinas from "./TablebatchDinas.vue";
import DetailPda from "./DetailPda.vue";
import DetailPch from "./DetailPch.vue"

export default {
  name: "PosDinasDetail",
  components: {
    DinasSidebarNav,
    MobileNavbarDash,
    DinasMobileSidebarDash,
    TablebatchDinas,
    DetailPda,
    DetailPch
  },
  data() {
    return {
      isActive: "Detail",
      jenisPos: null,
      registerId: null,
    };
  },
  created() {
    this.fetchPosDinas().then((resp) => {
       
      this.jenisPos = resp.jenisPos;
      this.registerId = resp.registerId;
    });
  },
  computed: {
    ...mapState({
      posKedinasan: (state) => state.posKedinasan.item, 
    }),
    getImgUrl() {
      var images = this.image;
      return images;
    },
  },
  methods: {
    ...mapActions("posKedinasan", ["fetchPosDinas"]), 

    onPageChange(page) {
      this.page = page;
    },

    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
  },
};
</script>
<style scoped>
.chart {
  height: 450px;
  width: 100%;
}

@media (max-width: 868px) {
  .chart {
    height: 200px;
    width: 360px;
  }
}
</style>