<template>
  <div class="business-dashboard company-dashboard ecommerce-dashboard-v1">
    <div class="columns is-multiline">
      <div class="column is-6">
        <div class="stats-wrapper">
          <div class="columns is-multiline is-flex-tablet-p">
            <div class="column is-6">
              <div class="dashboard-card">
                <div class="media-flex-center">
                  <div class="h-icon is-info is-rounded">
                    <i class="fad fa-bolt"></i>
                  </div>
                  <div class="flex-meta">
                    <span> {{ getVoltPda }} V</span>
                    <span>Volatase</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="dashboard-card">
                <div class="media-flex-center">
                  <div class="h-icon is-purple is-rounded">
                    <i class="fas fa-thermometer-quarter"></i>
                  </div>
                  <div class="flex-meta">
                    <span>{{ getSuhuPda }} °C</span>
                    <span>Suhu</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="datatable-toolbar">
      <div class="buttons">
        <SearchTelemetryPda :registerId="regId" />
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="dashboard-card is-base-chart">
          <div class="content-box">
            <div class="revenue-stats is-dark-bordered-12">
              Tinggi Muka air (cm)
            </div>
          </div>
          <div class="chart-container">
            <v-chart autoresize class="chart" :option="awlr" />
          </div>
        </div>
      </div>

      <div class="column is-12">
        <!-- Datatable -->

        <div class="table-wrapper">
          <b-table
            class="table is-datatable is-hoverable table-is-bordered"
            :data="channelList"
            :columns="columnsPda"
            focusable
            paginated
            :total="totalPda"
            :per-page="perPage"
            @page-change="onPagePdaChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @sort="onSort"
            icon-pack="fas"
            icon-left="chevron-right"
          >
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
let sseClient;
import { mapActions, mapState } from "vuex";
import SearchTelemetryPda from "./SearchTelemetryPda.vue";
import VChart from "vue-echarts";
export default {
  name: "TablePdaTelemetry",
  components: {
    SearchTelemetryPda,
    VChart
  },
  props: {
    regId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sn: null,
      pda: [],
      pagePda: 0,
      perPage: 10,
      totalPda: 0,
      columnsPda: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "serialNumber",
          label: "Serial Number",
        },
        {
          field: "ch1",
          label: "TMA (cm)",
        },
      ],
      sortField: "tanggal",
      sortOrder: "ASC",
      defaultSortOrder: "ASC",
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      waspada: 5,
      siaga: 50,
      awas: 60,
      tinggiTanggul: 150,
      awlr: {},
    };
  },
  created() {
    this.fetchChannel(this.regId).then(() => {
      this.channel.slice().forEach((item) => {
        this.sn = item.serialNumber;
      });
      
    });
     this.fetchLevelByRegisterId(this.regId).then((resp) => {        
        this.waspada = resp.waspada;         
        this.siaga = resp.siaga;
        this.awas = resp.awas;    
        this.tinggiTanggul = resp.tinggiTanggul;    
      });
    this.loadSubcribe();
  },
  computed: {
    ...mapState({
      channel: (state) => state.channel.items,
      level: (state) => state.level.item,
    }),
    channelList() {
      return this.channel.slice().reverse();
    },
    getVoltPda(){
      return this.channel.slice(0,1).map((r) => r.ch3).join(", ")
    },
    getSuhuPda(){
      return this.channel.slice(0,1).map((r) => r.ch2).join(", ")
    },
  },
  methods: {
    ...mapActions("channel", ["fetchChannel", "addPda"]),
    ...mapActions("level", ["fetchLevelByRegisterId"]),
    setSelected(tab) {
      this.selected = tab;
    },

    loadSubcribe() {
      sseClient = this.$sse.create({
        url: "/socket/channel/subscribe",
        format: "json",
        withCredentials: false,
        polyfill: true,
      });

      // Catch any errors (ie. lost connections, etc.)
      sseClient.on("error", (e) => {
        console.error("lost connection or failed to parse!", e);

        // If this error is due to an unexpected disconnection, EventSource will
        // automatically attempt to reconnect indefinitely. You will _not_ need to
        // re-add your handlers.
      });

      // Handle messages without a specific event
      sseClient.on("message", this.message);

      // Handle 'chat' messages
      sseClient.on("channel", (msg) => {
        // console.log(msg)
        // console.log(this.sn)
        if (msg.serialNumber === this.sn) {
          this.$store.dispatch("channel/addPda", msg);

          this.awlr = {
                title: {            
                  left: "1%",
                },
                tooltip: {
                  trigger: "axis",
                },
                grid: {
                  left: "5%",
                  right: "15%",
                  bottom: "10%",
                  top: "10%"
                },
                xAxis: {
                  data: this.channel.map((r) => r.tanggal),
                },
                yAxis: {
                  axisLabel: {
                      formatter: '{value} cm'
                  }
                },

                dataZoom: [
                  {
                    startValue: "1970-01-01",
                  },            
                  {
                    type: "inside",
                  },
                ],
                visualMap: {
                  top: 10,
                  right: 10,
                  pieces: [
                  {              
                      lte: this.waspada,
                      color: "#63b208",
                    },
                    {               
                      lte: this.siaga,
                      color: "#f7f711",
                    },
                    {              
                      lte: this.awas,
                      color: "#FD0100",
                    },
                    {              
                      gt: this.tinggiTanggul,
                      color: "#7f8c8d",
                    },
                  ],
                  outOfRange: {
                    color: "#e74c3c",
                  },
                },
                series: {
                  name: "TMA",
                  type: "line",
                  data: this.channel.map((r) => r.ch1),
                  markLine: {
                    silent: false,
                    lineStyle: {
                      color: "#333",
                    },
                    data:  [{ 
                    yAxis: this.waspada,
                    label: {  
                      show: true,
                      position: 'middle',
                      formatter: 'WASPADA',
                    },
                    lineStyle: { 
                        color: '#63b208',
                        width: 2                
                    },

                    },
                    { 
                      yAxis: this.siaga,
                      label: { 
                          show: true,
                          position: 'middle',
                          formatter: 'SIAGA',
                      },
                      lineStyle: {               
                          color: '#f7f711',
                          width: 2                
                      },

                    },
                    { 
                      yAxis: this.awas,
                      label: {
                          show: true,
                          position: 'middle',
                          formatter: 'AWAS',
                      },
                      lineStyle: {               
                          color: '#FD0100',
                          width: 2                
                      },

                    },
                    { 
                      yAxis: this.tinggiTanggul,
                      label: {
                          show: true,
                          position: 'middle',
                          formatter: 'TINGGI TANGGUL',
                      },
                      lineStyle: {
                          color: '#7f8c8d',
                          width: 2                
                      },

                    }]              
                  },
                },
            };

        }
      });

      sseClient
        .connect()
        .then((sse) => {
          console.log("Data Realtime connected!");

          // Unsubscribes from event-less messages after 7 seconds
          setTimeout(() => {
            sseClient.off("message", this.message);
            // console.log("Stopped listening to event-less messages!");
          }, 7000);

          // Unsubscribes from chat messages after 14 seconds
          setTimeout(() => {
            sse.off("channel", this.data);
            // console.log("Stopped listening to Pda Monitoring messages!");
          }, 14000);
        })
        .catch((err) => {
          // When this error is caught, it means the initial connection to the
          // events server failed.  No automatic attempts to reconnect will be made.
          console.error("Failed to connect to server", err);
        });
    },
    // channnel table
    loadAsyncData() {
      this.isLoaded = true;

      this.fetchChannel(this.regId).then(() => {
        let currentTotal = this.channel.length;
        if (currentTotal / this.perPage > 1000) {
          currentTotal = this.perPage * 1000;
        }
        this.totalPda = currentTotal;

        this.channel.slice().forEach((item) => {
          this.isLoaded = false;
          // item.tanggal =  moment(String(item.tanggal)).format('YYYY-MM-DD HH:mm:ss')
          this.pda.push(item);
        });
     
        this.awlr = {
          title: {            
            left: "1%",
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: "5%",
            right: "15%",
            bottom: "10%",
            top: "10%"
          },
          xAxis: {
            data: this.channel.map((r) => r.tanggal),
          },
          yAxis: {
            axisLabel: {
                      formatter: '{value} cm'
                  }
          },

          dataZoom: [
            {
              startValue: "1970-01-01",
            },            
            {
              type: "inside",
            },
          ],
          visualMap: {
            top: 10,
            right: 10,
            pieces: [
             {              
                lte: this.waspada,
                color: "#63b208",
              },
              {               
                lte: this.siaga,
                color: "#f7f711",
              },
              {              
                lte: this.awas,
                color: "#FD0100",
              },
              {              
                gt: this.tinggiTanggul,
                color: "#7f8c8d",
              },
            ],
            outOfRange: {
              color: "#e74c3c",
            },
          },
          series: {
            name: "TMA",
            type: "line",
            data: this.channel.map((r) => r.ch1),
            markLine: {
              silent: false,
              lineStyle: {
                color: "#333",
              },
              data:  [{ 
              yAxis: this.waspada,
              label: {  
                 show: true,
                 position: 'middle',
                 formatter: 'WASPADA',
              },
              lineStyle: { 
                  color: '#63b208',
                  width: 2                
              },

              },
              { 
                yAxis: this.siaga,
                label: { 
                    show: true,
                    position: 'middle',
                    formatter: 'SIAGA',
                },
                lineStyle: {               
                    color: '#f7f711',
                    width: 2                
                },

              },
              { 
                yAxis: this.awas,
                label: {
                    show: true,
                    position: 'middle',
                    formatter: 'AWAS',
                },
                lineStyle: {               
                    color: '#FD0100',
                    width: 2                
                },

              },
              { 
                yAxis: this.tinggiTanggul,
                label: {
                    show: true,
                    position: 'middle',
                    formatter: 'TINGGI TANGGUL',
                },
                lineStyle: {
                    color: '#7f8c8d',
                    width: 2                
                },

              }]              
            },
          },
      };

      });
      // END PDA
    },

    onPagePdaChange(page) {
      this.pagePda = page;
      this.loadAsyncData();
    },

    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    incrementPda() {
      this.pagePda += 1;
      this.loadAsyncData();
    },
    decrementPda() {
      this.pagePda -= 1;
      this.loadAsyncData();
    },
  },
  beforeDestroy() {
    // Make sure to close the connection with the events server
    // when the component is destroyed, or we'll have ghost connections!
    sseClient.disconnect();

    // Alternatively, we could have added the `sse: { cleanup: true }` option to our component,
    // and the SSEManager would have automatically disconnected during beforeDestroy.
  },
  mounted(){
      this.loadAsyncData()
  }
};
</script>

<style scoped>

.chart {
  height: 450px;
  width: 100%;
}


@media (max-width: 868px) 
{
  .chart
  {
    height: 200px;
    width: 360px;
  }
}

</style>