<template>
  <div class="modal h-modal is-big" :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background h-modal-close"></div>
    <div class="modal-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <h3>Informasi Pos Dinas</h3>
          <button class="close" aria-label="close" @click="closed">
            <i class="fas fa-window-close"></i>
          </button>
        </header>
        <div class="modal-card-body" v-if="isLoaded">
          <app-spinner />
        </div>
        <div class="modal-card-body" v-else>
          <!-- <div class="inner-content">
              <div class="section-placeholder">
                 <section class="modal-card-body">         -->
          <div class="tabs-wrapper">
            <!-- <div class="tabs-inner"> -->
            <div class="tabs">
              <ul>
                <li v-bind:class="{ 'is-active': isActive == 'Detail Pos' }">
                  <a v-on:click="isActive = 'Detail Pos'">Detail Pos</a>
                </li>
                <li
                  v-bind:class="{ 'is-active': isActive == 'pod' }"
                  
                >
                  <a v-on:click="isActive = 'pod'">Ketersediaan Data Pos</a>
                </li>
                
              </ul>
            </div>
          </div>
          <div
            class="tab-content"
            v-bind:class="{ 'is-active': isActive == 'Detail Pos' }"
          >
            <div class="detail">
              <div class="card">
                <section class="columns">
                  <div class="column is-12">
                    <div class="cards scroll-page">
                      <div class="columns">
                        <div class="column is-7 scroll">
                          <div class="header">
                            <h2 class="title is-4 is-narrow">Identitas Pos</h2>
                          </div>

                          <div class="card-content">
                            <div class="content">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td>Nama Pos</td>
                                    <td>:</td>
                                    <td>{{ data.namaPos }}</td>
                                  </tr>
                                  <tr>
                                    <td>Lokasi Pos</td>
                                    <td>:</td>
                                    <td>{{ data.lokasiPos }}</td>
                                  </tr>

                                  <tr>
                                    <td>DAS</td>
                                    <td>:</td>
                                    <td>
                                      {{ data.namaDas}}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Sungai</td>
                                    <td>:</td>
                                    <td>
                                      {{
                                        data.namaSungai 
                                      }}
                                    </td>
                                  </tr>
                                   <tr>
                                    <td>Dinas Pengelola</td>
                                    <td>:</td>
                                    <td>
                                      {{
                                        data.dinasPengelola 
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                         <div class="column gambar">
                          <div class="card is-4">
                            <div class="card-image">
                              <figure class="image is-3by2">
                                <img src="../../assets/img/sungai.jpg" alt="">
                              </figure>
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div
            class="tab-content"
            v-bind:class="{ 'is-active': isActive == 'pod' }"
            
          >
          <div class="lifestyle-dashboard lifestyle-dashboard-v3">
                             <!--Header-->
                               
                                    <div class="column is-12">
                                    <div class="illustration-header">
                                       <img src="../../assets/image/info.png" height="90" width="100" alt="">
                                        <div class="header-meta">
                                            <h3>Data Pos Hidrologi</h3>
                                            <p class="dashboard-cta-text">Data yang ditampilkan adalah informasi ketersediaan data
                                               yang dikumpulkan dari dinas terkait pengelolaan Hidrologi.
                                                Untuk memperoleh data yang dibutuhkan, Anda dapat menghubungi Kami Unit Hidrologi & Kualitas Air BBWS Cimanuk-Cisanggarung.</p> 
                                        </div>
                                    </div>
                                </div>
                                

                    </div>
             <div class="table-wrapper">
                  <b-table
                    class="table is-datatable is-hoverable table-is-bordered"
                    :data="dataTahunan"
                    
                    focusable
                    paginated
                    :total="total"
                    :per-page="perPage"
                    @page-change="onPageChange"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page"
                    :default-sort-direction="defaultSortOrder"
                    :default-sort="[sortField, sortOrder]"
                    @sort="onSort"
                    icon-pack="fas"
                    icon-left="chevron-right"
                  >
                    <b-table-column
                      field="tahun"
                      label="Tahun"
                      v-slot="props"
                    >
                      {{ props.row.tahun }}
                    </b-table-column>

                    <b-table-column field="januari" label="JAN" v-slot="props">                       
                      <img v-if="props.row.januari" :src="ada" alt="">
                      <img v-else :src="tidakAda" alt="">
                    </b-table-column>

                    <b-table-column field="februari" label="FEB" v-slot="props"> 
                       <img v-if="props.row.februari" :src="ada" alt="">
                      <img v-else :src="tidakAda" alt="">
                    </b-table-column>

                    <b-table-column field="maret" label="MAR" v-slot="props">
                      <img v-if="props.row.maret" :src="ada" alt="">
                      <img v-else :src="tidakAda" alt="">
                    </b-table-column>

                    <b-table-column field="april" label="APR" v-slot="props">
                      <img v-if="props.row.april" :src="ada" alt="">
                      <img v-else :src="tidakAda" alt="">
                    </b-table-column>

                    <b-table-column field="mei" label="MEI" v-slot="props">
                      <img v-if="props.row.mei" :src="ada" alt="">
                      <img v-else :src="tidakAda" alt="">
                    </b-table-column>

                    <b-table-column field="juni" label="JUN" v-slot="props">
                      <img v-if="props.row.juni" :src="ada" alt="">
                      <img v-else :src="tidakAda" alt="">
                    </b-table-column>

                    <b-table-column field="juli" label="JUL" v-slot="props">
                       <img v-if="props.row.juli" :src="ada" alt="">
                      <img v-else :src="tidakAda" alt="">
                    </b-table-column>

                    <b-table-column field="agustus" label="AGS" v-slot="props">
                       <img v-if="props.row.agustus" :src="ada" alt="">
                      <img v-else :src="tidakAda" alt="">
                    </b-table-column>

                    <b-table-column field="september" label="SEP" v-slot="props">
                       <img v-if="props.row.september" :src="ada" alt="">
                      <img v-else :src="tidakAda" alt="">
                    </b-table-column>

                    <b-table-column field="oktober" label="OKT" v-slot="props">
                       <img v-if="props.row.oktober" :src="ada" alt="">
                      <img v-else :src="tidakAda" alt="">
                    </b-table-column>

                    <b-table-column field="november" label="NOV" v-slot="props">
                       <img v-if="props.row.november" :src="ada" alt="">
                      <img v-else :src="tidakAda" alt="">
                    </b-table-column>

                    <b-table-column field="desember" label="DES" v-slot="props">
                       <img v-if="props.row.desember" :src="ada" alt="">
                      <img v-else :src="tidakAda" alt="">
                    </b-table-column>

                    <template #empty>
                      <div class="has-text-centered">Tidak Ada Data</div>
                    </template>
                  </b-table>
                </div>
          </div>
  
        </div>
        <div class="modal-card-foot is-right">
          <button @click="closed" class="button is-normal is-focused tutup">
            <span>Tutup</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script> 
import { mapActions, mapState } from "vuex"; 
import AppSpinner from "../shared/AppSpinner.vue"; 
export default {
  name: "ModalPosTelemetry",
  components: {
    AppSpinner, 
  },
  data() {
    return {
      selected: "Detail Pos",
      isActive: "Detail Pos",
      registerId: {},
      isOpen: false,
      isLoaded: true, 
      data: null,
      dataTahunan: [],
      total: 0,
      sortField: "created",
      sortOrder: "ASC",
      defaultSortOrder: "ASC",
      page: 0,
      perPage: 10, 
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      ada: require("../../assets/image/checkbox-1.png"),
      tidakAda: require("../../assets/image/checkbox-2.png"),
    };
  },
  computed: {
    ...mapState({
       landingPosDinas: (state) => state.landingPosDinas.items,
       kanalPosDinas: (state) => state.kanalPosDinas.items,
    }),
    
  },
  methods: { 
      ...mapActions("landingPosDinas", ["fetchLandingPosById"]),
      ...mapActions("kanalPosDinas", ["fetchLandingKanalPos"]),
    setSelected(tab) {
      this.selected = tab;
    },
    appendData(n) {
       this.fetchLandingPosById(n).then((resp) => {
         this.data = resp;
         this.isLoaded = false;
       });
       this.fetchLandingKanalPos(n).then((resp) => {
         this.dataTahunan = [];
         resp.dataTahunan.forEach((item) => {
              this.loading = false;
              this.dataTahunan.push(item);
              // console.log(item)
           });
         this.isLoaded = false;
       });
      
    },
 
    closed() {
      this.isOpen = false;
      this.isActive = "Detail Pos";
      this.isLoaded = true;
      
    },
    // channnel table

    
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
   
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order; 
    },
    increment() {
      this.page += 1; 
    },
   
    decrement() {
      this.page -= 1; 
    },
    
  
  },
  mounted() {
    this.$root.$on("pos-dinas", this.appendData);
    this.$root.$on("pos-dinas", () => {
      if (!this.isOpen) {
        this.isOpen = !this.isOpen;
      }
    });
  },
  beforeDestroy() {
    this.$root.$off("pos-dinas", this.registerId);
  },
};
</script>

<style scoped>
.modal-card-head,
.modal-card-body,
.modal-card-foot {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

.header {
  margin-left: 30px;
}

.gambar {
  margin: auto;
  margin-right: 10px;
}
button.close {
  width: 30px;
  font-size: 20px;
  color: #c0c5cb;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
}
button.tutup {
  background-color: #ed6755;
  border: none;
  border-radius: 5px;
  /* width: 50px; */
  padding: 14px;
  font-size: 16px;
  color: white;
  box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
}
.chart {
  height: 400px;
  width: 100%;
}
 
.fas
{
  margin-right: 10px;
  margin-left: -10px;
}

@media (max-width: 768px) {
  .chart {
    margin-left: 2px;
  }
  .gambar {
    margin-left: 10px;
  }
}
.banting-kanan {
  float: right;
}
.tombol
{
  padding-bottom: 10px;
}
</style>