
import axiosInstance from '../../conf/axiosAdmin';

export default {
    namespaced: true,
    state: {
        items: [],
    },
    getters: {

    },
    actions: {
        fetchPchHourly({ state, commit }, payload) {
            commit('setItems', { resource: 'searchKanalPch', items: [] }, { root: true })
            return axiosInstance.post(`/api/channel/search/hourly/pch/${payload.registerId}`, payload.search)
                .then(res => {
                    const pchchannels = res.data.channels
                    commit('setItems', { resource: 'searchKanalPch', items: pchchannels }, { root: true })
                    return state.items
                })
        },
        fetchPchDaily({ state, commit }, payload) {
            commit('setItems', { resource: 'searchKanalPch', items: [] }, { root: true })
            return axiosInstance.post(`/api/channel/search/daily/pch/${payload.registerId}`, payload.search)
                .then(res => {
                    const pchchannels = res.data.channels
                    commit('setItems', { resource: 'searchKanalPch', items: pchchannels }, { root: true })
                    return state.items
                })
        },
         
    }
}