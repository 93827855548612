<template>
   <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
      <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->

            <div class="title-wrap">
              <h1 class="title is-4">Data Sungai</h1>
            </div>
          </div>
 
          <div class="page-content-inner">
              <!--Form Layout 1-->
                <div class="form-layout">
                    <div class="form-outer">
                        <div class="form-header stuck-header">
                            <div class="form-header-inner">
                                <div class="left">
                                    <h3>Form Input</h3>
                                </div>
                                <div class="right">
                                    <div class="buttons">
                                        <button @click="$router.go(-1)"
                                            class="button h-button is-light is-dark-outlined">
                                            <span class="icon">
                                                <i class="lnir lnir-arrow-left rem-100"></i>
                                            </span>
                                            <span>Batal</span>
                                        </button>
                                        <button 
                                          class="button h-button is-primary is-raised"
                                          @click.prevent="editSungai"
                                          :disabled="isFormInvalid">Simpan</button>
                                        <button
                                        class="button h-button is-danger is-raised"
                                        @click.prevent="hapusSungai"
                                        type="is-success"
                                        outlined
                                      >     
                                        Hapus
                                      </button>  
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-body">
                            <!--Fieldset-->
                            <div class="form-fieldset"> 
                                <div class="columns is-multiline">
                                    <div class="column is-12">
                                       
                                      <div class="field">
                                        <div class="control">
                                          <label class="label">Nama DAS</label>
                                          <div class="select is-link">
                                            <b-autocomplete
                                              v-model="name"                          
                                              placeholder="e.g. Cimanuk"
                                              :keep-first="keepFirst"
                                              :open-on-focus="openOnFocus"
                                              :data="filteredDataObj"
                                              field="namaDas"
                                              @select="option => option !== null ? form.dasId = option.id : Number()"
                                              :clearable="clearable" 
                                              >
                                                    <template #empty >No results for {{name}}</template>
                                              </b-autocomplete>
                                              
                                          
                                          </div>
                                          
                                        </div>
                                      </div>
                                     
                                        <div class="field">
                                             <label>Nama Sungai</label>
                                            <div class="control has-icon">
                                                 <input
                                                  @blur="$v.form.namaSungai.$touch()"
                                                  v-model="form.namaSungai"
                                                  type="text"
                                                  class="input"
                                                  placeholder="Nama Sungai"
                                                />
                                                <div class="form-icon">
                                                    <i class="lnil lnil-air-flow"></i>
                                                </div>
                                                <div v-if="$v.form.namaSungai.$error">
                                                  <span v-if="!$v.form.namaSungai.required" class="help is-danger"
                                                    >Nama Sungai Harus Diisi</span
                                                  >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="column is-12">
                                        <div class="field">
                                            <label>Panjang Sungai</label>
                                            <div class="control has-icon">
                                                <input
                                                @blur="$v.form.panjangSungai.$touch()"
                                                v-model="form.panjangSungai"
                                                type="number"
                                                class="input"
                                                placeholder="Panjang Sungai"
                                              />
                                              <div class="form-icon">
                                                    <i class="lnil lnil-vector"></i>
                                                </div>
                                              <div v-if="$v.form.panjangSungai.$error">
                                                <span
                                                  v-if="!$v.form.panjangSungai.required"
                                                  class="help is-danger"
                                                  >Panjang Sungai Harus Diisi</span
                                                >
                                                <span v-if="!$v.form.panjangSungai.between" class="help is-danger"
                                                  >Panjang Sungai Tidak Boleh Minus</span
                                                >
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="column is-12">
                                        <div class="field">
                                             <label>Luas Sungai</label>
                                            <div class="control has-icon">
                                                <input
                                                  @blur="$v.form.luasSungai.$touch()"
                                                  v-model="form.luasSungai"
                                                  type="number"
                                                  class="input"
                                                  placeholder="Luas Sungai"
                                                />
                                                <div class="form-icon">
                                                    <i class="lnil lnil-vector"></i>
                                                </div>
                                                <div v-if="$v.form.luasSungai.$error">
                                                  <span v-if="!$v.form.luasSungai.required" class="help is-danger"
                                                    >Luas Sungai Harus Diisi</span
                                                  >
                                                  <span v-if="!$v.form.luasSungai.between" class="help is-danger"
                                                    >Luas Sungai Tidak Boleh Minus</span
                                                  >
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="column is-12">
                                        <div class="field">
                                             <label>Kelas Sungai</label>
                                            <div class="control">
                                                  <div class="select is-normal">
                                                  <select
                                                    v-model="form.kelasSungai"
                                                    @blur="$v.form.kelasSungai.$touch()"
                                                    aria-placeholder="pilih pos"
                                                  >
                                                    <option
                                                      v-for="ordo of ordos"
                                                      :key="ordo.status"
                                                      :value="ordo.status"
                                                    >
                                                      {{ ordo.keterangan }}
                                                    </option>
                                                  </select>
                                              </div>
                                                <div v-if="$v.form.kelasSungai.$error">
                                                  <span v-if="!$v.form.kelasSungai.required" class="help is-danger"
                                                    >Kelas Harus Diisi</span
                                                  >
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                     
                                </div>
                            </div>
                            <!-- end form -->
                        </div>
                    </div>
                </div>
                <!-- end -->

          </div>
        </div>
      </div>
    </div>
   </div>
</template>


<script>
import { required, between } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex"; 
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
export default {
  name: "FormUpdateSungai",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
  },
  data() {
    return {
      sungaiId: null,
      form: {
        id: null,
        dasId: null,
        namaSungai: null,
        panjangSungai: null,
        luasSungai: null,
        kelasSungai: null,
      },
      keepFirst: false,
      openOnFocus: false,
      name: '',
      selected: null,
      clearable: false, 
    };
  },
  validations: {
    form: {
      namaSungai: { required },
      panjangSungai: { 
        required,
        bettween: between(0, 1000000)
       },
      luasSungai: { 
        required,
        bettween: between(0, 1000000)
       },
      kelasSungai: { required },
      
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
    ...mapState({
       ordos: (state) => state.ordo.items,
       sungai: (state) => state.sungai.item,
       das: (state) => state.das.items
    }),
    filteredDataObj() {
       return this.das.filter(option => {
           return (
                    option.namaDas
                        .toString()
                        .indexOf(this.name) >= 0
                )
            })
    }
  },
  created() {
    this.sungaiId = this.$route.params.id;
    this.fetchOrdos();
    this.fetchDas();
    this.fetchSungaiById(this.sungaiId).then((res) => {
      this.form = res;
      this.name = this.sungai.das.namaDas;
    });
  },
  methods: {
    ...mapActions("ordo", ["fetchOrdos"]),
    ...mapActions("sungai", ["fetchSungaiById", "updateSungai"]),
    ...mapActions("das", ["fetchDas", "searchDas"]),
    editSungai() {
      this.updateSungai(this.form)
      .then(() => {
            this.$toasted.success('Pengkinian Data Sungai Berhasil', {duration: 3000})
            this.$router.push('/dashboard/sungai')
          })
          .catch(err => console.log(err))
      this.isOpen = false;
    },
    hapusSungai(){       
      this.$swal({
                  title: "Apakah anda yakin akan menghapus Data Sungai tersebut?!",
                  text: "Setelah dihapus anda tidak dapat mengembalikannya!",
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#c0392b",
                  confirmButtonText: "YA, HAPUS!"
                }).then((result) => { // <--
                  if (result.value) { // <-- if confirmed
                    this.$store.dispatch("sungai/deletSungai", this.sungaiId)
                    this.$router.push('/dashboard/sungai')
                    this.$toasted.success("Hapus Data Sungai Berhasil", { duration: 5000 }) 
                    
                  }
              });
 
    },
  },
};
</script>

 