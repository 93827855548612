<template>
  <!-- used `style="height: 100vh;"` because without it in the Firefox 89 and Chrome 91 (June 2021) the `vue-pdf-app` is not rendering on the page, just empty space without any errors (since `vue-pdf-app` does not have height and it is the top tag in the generated markup ) -->
  <!-- or you can just wrap `vue-pdf-app` in <div> tag and set height for it via CSS (like in `Script tag (unpkg)` example below) -->
  <vue-pdf-app style="height: 100vh;" :pdf=filePdf></vue-pdf-app>
  
</template>

<script>
import { mapActions, mapState } from "vuex";
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";

export default {
  components: {
    VuePdfApp
  },
  data() {
		return {
			filename: null,
      filePdf: null
		}
	},
   ...mapState({
       landingContent: (state) => state.landingContent.items,
    }),
  created() {
    this.filename = this.$route.params.filename;
    this.getLandingContentFile(this.filename).then((resp) => {        
         console.log(resp)
         this.filePdf = resp
    });
  },
  methods: {
     ...mapActions("landingContent", [
      "fetchContentInfoDataOlahan",
      "getLandingContentFile",
    ]),
  }
};
</script>