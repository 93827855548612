<template>
    <div class="mobile-main-sidebar" :class="{'is-active':isActive}">
    <div class="inner">
         

        <ul class="bottom-icon-side-menu">
            <li>
                <a @click.prevent="logout" class="button h-button is-success is-raised hint--info hint--top" data-hint="Logout">
                    <i class="lnil lnil-exit"></i>
                </a>
            </li>
             
        </ul>
    </div>
    
</div>
</template>


<script>
export default {
  name: 'MobileSidebarDash',
  components: {},
   data() {
       return {
           isActive: ''
       }
   },
   methods: {
     logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push(this.$route.query.redirect || "/login"));
    },
  },
   mounted() { 
      this.$root.$on("mobile-navbar", () => {
         this.isActive = this.isActive ? '' : 'is-active';
      });
    },
    beforeDestroy() {
      this.$root.$off("global-notification", this.registerId);
    },
}
</script>