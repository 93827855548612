<template>
  <div class="main-sidebar">
    <div class="sidebar-brand">
      <a href="/">
        <img class="light-image" src="../../assets/logoPu.png" alt="" />
        <img class="dark-image" src="../../assets/logoPu.png" alt="" />
      </a>
    </div>
    <div class="sidebar-inner">
      <!-- Plus 64 per menu -->
      <div class="naver" v-bind:style="{ 'margin-top': location }"></div>

      <ul class="icon-menu">
        <!-- Activity -->
        <!-- {{> sidebar-item-home}} -->
        <LinkNavigation/>
      </ul>

      
        <ul class="bottom-menu">
        
        <li v-if="!isAuthResolved">
          <div
            id="profile-menu"
            class="dropdown profile-dropdown dropdown-trigger is-spaced is-up"
          >
             <router-link class="button h-button is-primary is-raised hint--info hint--top" to="/login" data-hint="Login">
               <i class="lnil lnil-user-alt-1"></i>
             </router-link>
              
          </div>
        </li>
        <li  v-else>
          <div
            
            id="profile-menu"
            class="dropdown profile-dropdown dropdown-trigger is-spaced is-up"
            v-if="otoritas"
          > 
             <router-link class="button h-button is-primary is-raised hint--info hint--top"  to="/dashboard/profile"  data-hint="Profile">
               <i class="lnil lnil-user-alt-1"></i>
             </router-link>
              
          </div>
          <div
            
            id="profile-menu"
            class="dropdown profile-dropdown dropdown-trigger is-spaced is-up"
            v-else
          > 
             <router-link class="button h-button is-primary is-raised hint--info hint--top"  to="/dashboard/dinas"  data-hint="Profile">
               <i class="lnil lnil-user-alt-1"></i>
             </router-link>
              
          </div>
        </li>
        
      </ul> 
    </div>
  </div>
</template>


<script>
import LinkNavigation from './LinkNavigation.vue'
export default {
  name: "Sidebar",
  components: {LinkNavigation},
  data() {
    return {
      location: "",
      otoritas: null, 
    };
  },
  created() {
    const authority = localStorage.getItem('authority')
    if(authority === 'ADMIN'){
      this.otoritas = true
    }
    const loc = this.$route.name;
    if (loc === "LandingPos" || (loc === "Home") || (loc === "LandingPage")) {
      this.location = "150px";
    }  else if (loc === "KualitasAir") {
      this.location = "214px";
    } else if ((loc === "PosDinasLanding")){
      this.location = "278px";
    }else if ((loc === "KontenInfo") ||  (loc === "PageInfoUmum") || (loc === "PageInformasiAnalisa") || (loc === "PageDataOlahan")){
      this.location = "342px";
    }
    // console.log(this.location)
  },
   computed: {
    isAuthResolved () {
        let isLogin = this.$store.state.auth.isAuthResolved
      return isLogin;
    } 
  },
};
</script>